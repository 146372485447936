import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { TipoNumeroProcessoApenso } from "../tipos_de_dados/tipoNumeroProcessoApenso";
import { classeEntidadeDefault } from "./classeEntidadeDefault";

@Injectable({
  providedIn: 'root'
})
export class classeEntidadeApenso extends classeEntidadeDefault {

//construtor
  
  instanciar (): void {
    this.nome =  'apenso'
    this.nome_externo = 'Apenso'
    this.icone = 'attach_file'
    this.carregar_datatable()
    this.reconfigurar_botoes()
    this.configurar_dados_parent()
  }

  
  configurar_dados_parent(): void {
       this.campo_id_parent = 'id_processo'
       this.entidade_parent = 'processo'
  }
  
  configurar_layout(): void {

    this.reinicializa()
    this.incluir_campo('numero_processo', 'Apenso', new TipoNumeroProcessoApenso, true, true, true, false, 'processo_apenso', [Validators.required])
  }

  reconfigurar_botoes() {
    this.acoes_nivel_registro = []
    this.tipo_operacao.ALTERAR.executada_apenas_por_criador = false
    this.tipo_operacao.EXCLUIR.executada_apenas_por_criador = false
    this.acao_duplo_clique = this.tipo_operacao.ALTERAR
    this.acoes_nivel_registro.push(this.tipo_operacao.ALTERAR)
    this.acoes_nivel_registro.push(this.tipo_operacao.EXCLUIR)


  }

}