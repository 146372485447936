import { TipoLista } from "./tipoLista";

export class TipoAlertaStatus extends TipoLista {
  constructor(
    
  ) {
    super();
    this.prepara_lista()
  }

  prepara_lista(): void {
    this.adicionar_item('SUCESSO','SUCESSO')
    this.adicionar_item('ERRO','ERRO')
  }

}