import { TipoLista } from "./tipoLista";

export class TipoTarefaStatus extends TipoLista {
  constructor(
    
  ) {
    super();
    this.prepara_lista()
  }

  prepara_lista(): void {
    this.adicionar_item('A','Pendente')
    this.adicionar_item('C','Concluído')

  }

}