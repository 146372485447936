<mat-card class="calendario">
  <mat-card-title>
      <i class="material-icons">
          person_search
      </i>
      Consulta de Partes
  </mat-card-title>

  <mat-card-subtitle>
      Aqui você pode consultar todas as partes cadastradas. 
  </mat-card-subtitle>

</mat-card>

<div class="divDataTable">
  <app-datatable ENTIDADE="parte" ID_PARENT=""></app-datatable>
</div>