import { environment } from "src/environments/environment";

export interface domainRetornoApiJudicy {
      conteudo        ?: any
     ,status          ?: number
     ,mensagem        ?: string
     ,total_registros ?: number
} 

export const urlApiJudicyProducao = 'http://XXXXXXXXXXXX:5000';
export const urlApiJudicyDesenvolvimento = 'http://XXXXXXXXXXXX';
export const urlApiJudicyLocal = 'http://localhost:5000';

export const urlApiJudicy = environment.ApiJudicy;

export const autenticacaoApiJudicy = {
      requerente : 'aplicacaoWeb'
     ,chave: 'deltaAqnosApiStorm!01012021alfa'
}
