import { TipoLista } from "./tipoLista";

export class TipoTarefaTipo extends TipoLista {
  constructor(
    
  ) {
    super();
    this.prepara_lista()
  }

  prepara_lista(): void {
    this.adicionar_item('T','Tarefa')
    this.adicionar_item('P','Processo')

  }

}