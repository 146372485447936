<mat-card class="calendario">
    <mat-card-title>
        <i class="material-icons">
            date_range
        </i>
        Consulta de Prazos
    </mat-card-title>

    <mat-card-subtitle>
        Aqui você pode consultar todos os prazos cadastradas. 
    </mat-card-subtitle>

</mat-card>

<div class="divDataTable">
    <app-datatable ENTIDADE="prazo" ID_PARENT=""></app-datatable>
</div>