<mat-card class="calendario">
  <mat-card-title>
    <i class="material-icons">
      my_library_books
    </i>
    Consulta de Publicação
  </mat-card-title>

  <mat-card-subtitle>
    Aqui você pode consultar todas as publicações cadastradas.
  </mat-card-subtitle>

</mat-card>

<div class="divDataTable">
  <app-datatable ENTIDADE="publicacao" ID_PARENT=""></app-datatable>
</div>