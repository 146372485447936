import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SessaoService } from './servicos/sessao.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Aqnos';

  exibMenu: string = 'N';

  constructor(private sessaoServico : SessaoService
             ,private rotaURl                : Router
    ) {

  }

  get flagUsuarioLogado(): boolean {
    return this.sessaoServico.getFlagUsuarioLogado()
  }

  ngOnInit(){
    if  (this.sessaoServico.getFlagUsuarioLogado() == false) {
          this.rotaURl.navigate(['pgLogin'])
    }
  }
}
