import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { Condicao } from "src/app/domain/acao";
import { domainContato } from "src/app/domain/contato";
import { TipoContatoTipo } from "../tipos_de_dados/tipoContatoTipo";
import { TipoFlag } from "../tipos_de_dados/tipoFlag";
import { TipoTelefone } from "../tipos_de_dados/tipoTelefone";
import { TipoTexto } from "../tipos_de_dados/tipoTexto";
import { classeEntidadeDefault } from "./classeEntidadeDefault";

@Injectable({
  providedIn: 'root'
})
export class classeEntidadeContato extends classeEntidadeDefault {

//construtor
  

  instanciar(): void {
    this.nome = 'contato'
    this.nome_externo = 'Contato'
    this.icone = 'call'
    this.carregar_datatable()
    this.configurar_layout()
    this.configurar_dados_parent()
    this.campo_ordenacao = "tipo"
    this.direcao_ordenacao = 'asc'
  }
  
  configurar_dados_parent(): void {
       this.campo_id_parent = 'id_parte'
       this.entidade_parent = 'parte'
  }

  configurar_layout(): void {
    this.reinicializa()
    this.incluir_campo('id_contato'        , 'id_contato'        , new TipoTexto      , true, false, false, false, '', [Validators.required], false, false, )
    this.incluir_campo('tipo'              , 'Tipo'              , new TipoContatoTipo, true, true , true , false, '', [Validators.required], true , false, )
    this.incluir_campo('telefone'          , 'Telefone'          , new TipoTelefone   , true, true , true , false, '', [Validators.required], true , false, )
    
    var contato_recado : Condicao = {}
    contato_recado.campo = 'tipo'
    contato_recado.valor = '4'
    this.incluir_campo('nome_contato'      , 'Nome Contato'      , new TipoTexto      , true, true , true , false, '', undefined, true , false, [contato_recado])

    var contato_celular : Condicao = {}
    contato_celular.campo = 'tipo'
    contato_celular.valor = '2'
    this.incluir_campo('indicador_whatsapp', 'Indicador Whatsapp', new TipoFlag       , true, true , true , false, '', undefined, true , false, [contato_celular])

  }

  formatar_registro(registro: any): string {
    var contato: domainContato = registro
    var texto_whatsapp = ''
    var texto_recado = ''
    
    if (contato.indicador_whatsapp) {
      texto_whatsapp = ' (Whatsapp) '
    }

    if (contato.tipo == '4') {
      texto_recado = ' (' + contato.nome_contato + ') '
    }

    var tipo_contato = new TipoContatoTipo
    var tipo_telefone = new TipoTelefone

    var formatado: string
    formatado = tipo_contato.formatar_para_exibir_em_tela(contato.tipo!) + ': ' 
              + tipo_telefone.formatar_para_exibir_em_tela(contato.telefone!)
              + texto_whatsapp
              + texto_recado

    return formatado

  }

  formatar_detalhe_externo(registro: any): string {
    var contato: domainContato = registro
    var tipo_telefone = new TipoTelefone
    var formatado: string

    formatado = tipo_telefone.formatar_para_exibir_em_tela(contato.telefone!)

    return formatado

  }

}