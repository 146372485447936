import { TipoDados } from "./tipoDados";
import { itemLista } from "./tipoLista";

export class TipoTimestamp extends TipoDados {

  formatar_para_exibir_em_tela(timestamp: any): string {
    return timestamp
  }

  codigo_tipo = 'data'
  lista: Array<itemLista> = []
}