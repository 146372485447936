import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { merge, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { domainAcao, Operacao } from 'src/app/domain/acao';
import { domainRetornoApiJudicy } from 'src/app/domain/apiJudicy';
import { domainCampo } from 'src/app/domain/entidade';
import { domainParametro } from 'src/app/domain/parametro';
import { TipoOperadorJudicy } from 'src/app/domain/tipoOperadorJudicy';
import { domainUsuario } from 'src/app/domain/usuario';
import { SessaoService } from 'src/app/servicos/sessao.service';
import { JudicyService } from 'src/app/servicos/judicy.service';
import { UtilidadesService } from 'src/app/servicos/utilidades.service';
import { DialogComponent } from '../dialog/dialog.component';
import { classeEntidade } from '../entidade/classeEntidade';
import { TipoEntidade } from '../entidade/tipoEntidade';
import { classeFonteDados } from '../fonte-dados';
import { ExecutorOperacaoDialog } from '../OperacaoDialog';
import { ListasDeBancoDeDados } from '../tipos_de_dados/tipoListaBancoDeDados';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.css']
})
export class DatatableComponent implements OnInit {

  @Input()
  ENTIDADE!: string;

  @Input()
  ID_PARENT!: string;

  @Input()
  LISTA_DE_LISTAS!: ListasDeBancoDeDados;

  constructor(
    private dialog: MatDialog
    , private util: UtilidadesService
    , private judicy: JudicyService
    , private sessao: SessaoService
    , private rotaURL: Router

  ) { }


  selecionar(registro: any) {
    //console.log('registro = ' + registro)
    this.registro_selecionado = registro
  }
  // ----------- VARIAVEIS 
  fonteDados = new classeFonteDados();
  filtro: any = {};
  campo_ordenacao: string = '';
  direcao_ordenacao: string = '';
  pagina: number = 0;
  tamanho: number = 100;
  entidade!: classeEntidade
  cabecalho_da_tabela: Array<string> = []
  layout_tabela: Array<domainCampo> = []
  registro_selecionado: any = {}

  // ----------- CONTROLE DE PAGINACAO E ORDENACAO MATERIAL 
  @ViewChild(MatPaginator) paginacao!: MatPaginator;
  @ViewChild(MatSort) ordenacao!: MatSort;

  ngOnInit(): void {
    if (this.ENTIDADE == undefined) {

    } else {
      this.carregar_datatable()
    }
  }
  

  carregar_datatable(p_entidade_seletor?: classeEntidade) {
    this.entidade = p_entidade_seletor!

    if (this.entidade == undefined) {
      var lista_de_listas: ListasDeBancoDeDados;

      if (this.LISTA_DE_LISTAS == undefined) {
        lista_de_listas = new ListasDeBancoDeDados(this.judicy, this.util)
      } else {
        lista_de_listas = this.LISTA_DE_LISTAS
      }
      var tipo_entidade = new TipoEntidade(this.judicy, this.util, lista_de_listas)
      this.entidade = tipo_entidade.getEntidade(this.ENTIDADE)['instancia']
    }

    if (this.ID_PARENT == '') {
      this.entidade.configurar_exibicao_default()
    }
    else {
      this.entidade.configurar_exibicao_parent(this.ID_PARENT)
    }

    this.acoes_nivel_tabela = this.entidade.acoes_nivel_tabela
    this.acoes_nivel_registro = this.entidade.acoes_nivel_registro

    this.carregar_cabecalho_tabela()
    this.atualizar_conteudo_tabela()

  }

  get_estilo_link (linha: any, coluna: domainCampo) : string {
    if (coluna.nome_campo == 'numero_processo' && coluna.indicador_campo_parent
      && linha[coluna.nome_parent!][coluna.nome_campo] ) {
      return 'link'
    }
    
    return 'normal'
  }
  exibir_campo(linha: any, coluna: domainCampo): string {
    var retorno = ''
    switch (true) {
      case coluna.nome_campo == 'numero_processo' && coluna.indicador_campo_parent:
        retorno = coluna.tipo!.formatar_para_exibir_em_tela(linha[coluna.nome_parent!][coluna.nome_campo], linha['numero_unico_processo'])
        break

      case coluna.indicador_campo_parent:
        retorno = coluna.tipo!.formatar_para_exibir_em_tela(linha[coluna.nome_parent!][coluna.nome_campo])
        break

      case coluna.nome_campo == 'documento':
        retorno = coluna.tipo!.formatar_para_exibir_em_tela(linha['documento'], linha['tipo_documento'])
        break
  
  
      default:
        retorno = coluna.tipo!.formatar_para_exibir_em_tela(linha[coluna.nome_campo])
        break

      }
    //retorno = retorno.replace(new RegExp('\n', 'g'), "<br />")
    return retorno
  }
  // ----------- LOADING... DATA TABLE
  ngAfterViewInit() {
    this.ordenacao.sortChange.subscribe(() => this.paginacao.pageIndex = 0);

    merge(this.paginacao.page, this.ordenacao.sortChange)
      .pipe(
        tap(() => this.atualizar_conteudo_tabela())
      )
      .subscribe();
  }

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });


  limpar_filtro() {
    this.range.reset();
    this.listaFiltro = []
    this.listaFiltroData = []
    this.filtro = {}
    this.atualizar_conteudo_tabela()

  }

  aplicaFiltro(): void {
    this.paginacao.pageIndex = 0
    this.atualizar_conteudo_tabela()
  }

  aplicaFiltroData(campo: string, dataInicio: HTMLInputElement, dataFim: HTMLInputElement) {
    this.paginacao.pageIndex = 0
    this.removeFiltroData(campo)

    if (dataInicio.value != '') {
      this.listaFiltroData.push({ campo: campo, valor: this.formata_data(dataInicio.value), operador_logico: TipoOperadorJudicy.MAIOR_IGUAL })
    }

    if (dataFim.value != '') {
      this.listaFiltroData.push({ campo: campo, valor: this.formata_data(dataFim.value), operador_logico: TipoOperadorJudicy.MENOR_IGUAL })
    }

    this.atualizar_conteudo_tabela()
  }

  removeFiltroData(campo: string) {
    this.listaFiltroData.forEach((filtro, indice) => {
      if (filtro.campo == campo)
        this.listaFiltroData.splice(indice, 1);
    })
  }

  formata_data(data: string): string {
    return data.substring(6, 11) + '-' + data.substring(3, 5) + '-' + data.substring(0, 2)
  }

  filtro_judicy: string = ''
  listaFiltro: Array<any> = [];
  listaFiltroData: Array<any> = [];

  // ----------- CARREGA DATA TABLE DE ACORDO COM PARAMETROS
  atualizar_conteudo_tabela() {
    this.listaFiltro = []
    this.carregar_filtro_parent()
    this.carregar_filtros_aproximados()
    this.listaFiltro = this.listaFiltro.concat(this.listaFiltroData);

    this.converte_filtro_para_string()

    this.sincroniza_paginacao()
    this.sincroniza_ordenacao()
    this.listar_registros()
  }

  carregar_filtro_parent(): void {
    if (this.entidade.flag_exibicao_parent && this.entidade.campo_id_parent != '') {
      this.listaFiltro.push({ campo: this.entidade.campo_id_parent, valor: this.entidade.id_parent.toString(), operador_logico: TipoOperadorJudicy.IGUAL })
    }
  }

  carregar_filtros_aproximados(): void {
    for (var [v_campo, v_valor] of Object.entries(this.filtro)) {
      if (v_valor != '' && v_valor != '[object Object]') {
        if (v_campo == 'inatividade') {
          if (v_valor != 0) {
            this.listaFiltro.push({ campo: v_campo, valor: v_valor, operador_logico: TipoOperadorJudicy.MENOR_IGUAL })
          }

        } else {
          this.listaFiltro.push({ campo: v_campo, valor: v_valor + '*', operador_logico: TipoOperadorJudicy.INICIA_COM })
        }
      }
    }
  }

  converte_filtro_para_string(): void {
    if (this.listaFiltro.length != 0) {
      this.filtro_judicy = '{ "filtro":' + JSON.stringify(this.listaFiltro) + '}';
    } else {
      this.filtro_judicy = ''
    }
  }

  sincroniza_paginacao() {
    if (this.paginacao == undefined) {
      this.pagina = 0
      this.tamanho = 100
    } else {
      this.pagina = this.paginacao.pageIndex
      this.tamanho = this.paginacao.pageSize
    }
  }

  sincroniza_ordenacao() {
    if (this.ordenacao == undefined
      || this.ordenacao.direction == undefined || this.ordenacao.direction == ''
      || this.ordenacao.active == undefined || this.ordenacao.active == ''
    ) {
      this.direcao_ordenacao = '""'
      this.campo_ordenacao = '""'
    } else {
      this.direcao_ordenacao = this.ordenacao.direction
      this.campo_ordenacao = this.ordenacao.active

    }

  }


  listar_registros(): void {
    this.acionar_api_judicy_de_listagem().pipe(finalize(() => this.encerrar_loading_tabela())
    ).subscribe((retorno_api_judicy: any) => {
      this.trata_retorno_consulta(retorno_api_judicy)
    }
    ), (error: any) => {
      this.trata_erro_conexao_api(error)

    }
      ;
  }

  acionar_api_judicy_de_listagem(): Observable<domainRetornoApiJudicy> {
    return this.judicy.listar(
      this.entidade.nome!
      , this.pagina + 1
      , this.tamanho
      , this.campo_ordenacao
      , this.direcao_ordenacao
      , this.filtro_judicy
    )
  }

  encerrar_loading_tabela(): void {
    this.fonteDados.loadingSubject.next(false)
  }

  trata_erro_conexao_api(error: any): void {
    this.util.exibirMensagem(this.util.catalogo.msg_erro_listagem(), this.util.tp_msg.erro());
    console.log('Erro', error);
  }

  trata_retorno_consulta(retornoJudicy: domainRetornoApiJudicy): void {
    if (retornoJudicy.status == 0) {
      this.fonteDados.total_registros = retornoJudicy.total_registros!
      this.fonteDados.fonteSubject.next(retornoJudicy.conteudo)
    }

    else {
      this.util.exibirMensagem(retornoJudicy.mensagem!, this.util.tp_msg.erro());
    }
  }

  @ViewChild(DialogComponent, { static: false })
  caixa_dialogo!: DialogComponent;

  executar_operacao_duplo_clique(registro: any) {
    if (!this.verificaAcessoAcao(registro, this.entidade.acao_duplo_clique)) {
      this.executar_operacao(registro, this.entidade.acao_duplo_clique)
    }
  }

  get usuarioLogado(): domainUsuario {
    return this.sessao.getUsuarioLogado()
  }

  verificaAcessoAcao(registro: any, acao: domainAcao): boolean {
    var desabilitado = false
    var criador: string
    var responsavel: string
    criador = registro['usuario']
    responsavel = registro['responsavel']

    if (acao.executada_apenas_por_criador) {
      if (this.usuarioLogado.perfil != 'S') {
        if (this.usuarioLogado.login != criador && this.usuarioLogado.login != responsavel) {
          desabilitado = true
        }
      }
    }

    if (acao.condicao_desabilitar?.condicoes != undefined) {
      acao.condicao_desabilitar.condicoes!.forEach(condicao => {

        if (condicao.tipo) {
          if (registro[condicao.campo!] == condicao.valor
            && registro[condicao.campo!] != undefined
          ) {
            desabilitado = true
          }
        } else {
          if (registro[condicao.campo!] != condicao.valor
            && registro[condicao.campo!] != undefined
          ) {
            desabilitado = true
          }
        }

      })
    }

    return desabilitado
  }

  verificaDesabilitarAcao(registro: any, acao: domainAcao): boolean {
    return this.verificaAcessoAcao(registro, acao)

  }

  get_estilo_linha(registro: any): string {

    var retorno = 'padrao'

    this.entidade.condicao_destacar?.forEach((cond) => {
      if (registro[cond.campo!] == cond.valor) {
        retorno = cond.estilo!
      }
    })

    return retorno

  }
  verificaOcultarAcao(registro: any, acao: domainAcao): boolean {
    var oculto = false

    if (registro[acao.condicao?.campo!] != acao.condicao?.valor) {
      oculto = true
    }

    if (acao.nao_exibir_primeiro_registro) {
      if (registro['sequencial'] == 1) {
        oculto = true
      }
    }

    if (acao.nao_exibir_ultimo_registro) {
      if (registro['sequencial'] == this.fonteDados.total_registros) {
        oculto = true
      }
    }


    return oculto

  }

  acao!: domainAcao
  registro: any

  executar_operacao(registro: any, acao: domainAcao) {
    this.acao = acao
    this.registro = registro
    this.judicy.entidade = this.entidade
    if (acao.operacao == Operacao.ALTERAR_SEQUENCIA_UP || acao.operacao == Operacao.ALTERAR_SEQUENCIA_DOWN) {
      this.alterar_sequencia()
    } else {
      if (acao.operacao == Operacao.CONSULTAR_NOVA_PAGINA) {
        this.util.abrirEmNovaJanela(acao.pagina_destino + '/' + registro['id_' + this.entidade.nome])
      } else {
        if (acao.operacao == Operacao.CONSULTAR) {
          this.rotaURL.navigate([acao.pagina_destino + '/' + registro['id_' + this.entidade.nome]])
        } else {
          this.execucao_operacao_dialog()
        }
      }
    }
  }
  alterar_sequencia() {
    var v_parametro: domainParametro = {}
    v_parametro.campo = 'direcao'
    if (this.acao.operacao == Operacao.ALTERAR_SEQUENCIA_UP) {
      v_parametro.valor = 'acima'
    } else {
      v_parametro.valor = 'abaixo'

    }

    this.judicy.alterar(this.entidade.nome, this.registro, this.judicy.registro['id_' + this.entidade.nome]
      , 'altera_sequencia', [v_parametro]).subscribe(
        resposta => {
          if (resposta.status == 0) {
            this.util.exibirMensagem(this.util.catalogo.msg_sucesso_alteracao(), this.util.tp_msg.sucesso());
            this.atualizar_conteudo_tabela()
          }
          else {
            this.util.exibirMensagem(resposta.mensagem!, this.util.tp_msg.erro());
            //console.log('Erro', retornoJudicy.mensagem);                                                    
          }

        }
        , (error: any) => {
          this.util.exibirMensagem(this.util.catalogo.msg_erro_alteracao(), this.util.tp_msg.erro());
          //console.log('Erro', error);                                                              
        }
      );
  }
  execucao_operacao_dialog(): void {
    var executor = new ExecutorOperacaoDialog()
    this.judicy.acao = this.acao
    //console.log('this.judicy.acao = ' + JSON.stringify(this.judicy.acao))
    if (this.acao.operacao != undefined) {
      var dialog = executor.execucao_operacao_dialog(this.registro, this.acao.operacao,
        this.entidade.id_parent
        , this.judicy, this.entidade, this.dialog)

      dialog.afterAllClosed.subscribe(data => this.atualizar_conteudo_tabela())
    }
  }

  acoes_nivel_registro: Array<domainAcao> = []
  acoes_nivel_tabela: Array<domainAcao> = []

  flag_exibir_acao = true

  carregar_cabecalho_tabela(): void {
    for (var campo of this.entidade.layout) {
      if (campo.exibe_consulta) {
        if (this.entidade.id_parent == 0 || !campo.indicador_campo_parent) {
          //console.log(JSON.stringify(campo))
          this.cabecalho_da_tabela.push(campo.nome_campo)
          this.layout_tabela.push(campo)
        }
      }
    }


    if (this.acoes_nivel_registro.length > 0) {
      this.cabecalho_da_tabela.push('acao')
    }

    //console.log(JSON.stringify(this.cabecalho_da_tabela))


  }


}
