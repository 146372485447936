<mat-card class="mat-elevation-z3">
    <mat-card-title class="titulo">
        Parte
    </mat-card-title>

    <mat-card-subtitle class="subtitulo">
        Cadastro de Partes (Réus / Autores / Clientes)
    </mat-card-subtitle>
    <mat-card-content>
        <p>
            Olá!
        </p>

        <p>
            Nesta sessão você pode gerenciar todo o cadastro das partes envolvidas no Processo.
        </p>
        
        <p>
            Você poderá incluir, listar, alterar e excluir o cadastro de novos potênciais clientes, autores e réus que constarão nos seus processos.
        </p>

        <p>
            Faça bom proveito desta ferramenta poderosa! 
       </p>
    </mat-card-content>

</mat-card>

<br>

<mat-card class=" mat-elevation-z3">
    <mat-card-title class="titulo">
        Estatísticas
    </mat-card-title>

    <mat-card-subtitle class="subtitulo">
        Números Gerais
    </mat-card-subtitle>

    <mat-card-content>
        <p>
            Total de Partes Existentes: {{total.parte | number}}
        </p>

    </mat-card-content>
</mat-card>