import { TipoDados } from "./tipoDados";
import { itemLista } from "./tipoLista";

export class TipoData extends TipoDados {

  formatar_para_exibir_em_tela(data: Date): string {
    if (data != undefined) {
      var v_data = data.toString()
      return v_data.substring(8,10) + '/' + v_data.substring(5,7) + '/' + v_data.substring(0,4)
    } else {
      return '-'
    }
  }

  codigo_tipo = 'data'
  lista: Array<itemLista> = []
}