import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { domainUsuario } from 'src/app/domain/usuario';
import { SessaoService } from 'src/app/servicos/sessao.service';
import { UtilidadesService } from 'src/app/servicos/utilidades.service';
import { CabecalhoServicoService } from 'src/app/template/cabecalho/cabecalho-servico.service';
import { NavegacaoServicoService } from 'src/app/template/navegacao/navegacao-servico.service';

@Component({
  selector: 'app-usuario-pagina',
  templateUrl: './usuario-pagina.component.html',
  styleUrls: ['./usuario-pagina.component.css']
})
export class UsuarioPaginaComponent implements OnInit {

  constructor(
     public dialog: MatDialog

    , private vCabecalhoServico: CabecalhoServicoService
    , private vNavegacaoServico: NavegacaoServicoService
    , private sessao : SessaoService
    , private util: UtilidadesService
  ) {
    vCabecalhoServico.cabecalho = {
      titulo: 'Gerencial > Usuário',
      icone: 'settings',
      rotaURL: 'pgGerencial'
    }
    vNavegacaoServico.tipoMenu = 'gerencial';

  }
  ngOnInit(): void {
    if (this.validaPermissao() == false) {
      this.negarAcesso()
    }
  }
  
  get usuarioLogado(): domainUsuario {
    return this.sessao.getUsuarioLogado()
  }
  
  validaPermissao(): boolean {
    if (this.usuarioLogado.perfil == 'D' || this.usuarioLogado.perfil == 'S') {
      return true
    } else {
      return false
    }
  }

  negarAcesso(): void {
    this.util.exibirMensagem('Você não tem acesso a esta págia. Direcionando para home...', this.util.tp_msg.informacao());
    this.util.navegarPara('pgHome')
  }

}
