import { TipoLista } from "./tipoLista";

export class TipoParametroCampo extends TipoLista {
  constructor(
    
  ) {
    super();
    this.prepara_lista()
  }

  prepara_lista(): void {
    this.adicionar_item('ST','Status (Processo)')
    this.adicionar_item('CA','Categoria (Andamento)')
    this.adicionar_item('OR','Órgão (Processo)')
    this.adicionar_item('BA','Banco (Conta Bancária)')

  }

}