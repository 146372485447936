import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { JudicyService } from "../servicos/judicy.service";
import { TipoOperadorJudicy } from '../domain/tipoOperadorJudicy';
import { map } from 'rxjs/operators'; 

export function valida_processo_ja_cadastrado(
  judicy: JudicyService):
  AsyncValidatorFn {
  return (control: AbstractControl) => {
    const numero_processo = control.value;

    var filtro_processo = { campo: 'numero_processo', valor: numero_processo, operador_logico: TipoOperadorJudicy.IGUAL };

    var listafiltro = [];
    listafiltro.push(filtro_processo);

    var filtro_judicy = '{ "filtro":' + JSON.stringify(listafiltro) + '}';
    return busca_judicy('processo', filtro_judicy, judicy)
      .pipe(
        map((res: any) => { return res.conteudo.length == 0
                                 ? // * Quando não há erros:
                                 null
                                 : // * Quando houver erros: 
                                 { duplicado: true } }
        )
      );
  };
}


export function busca_judicy(entidade: string, filtro_judicy: string, judicy: JudicyService
) {
  return judicy.listar(
    entidade
    , 1
    , 1
    , ''
    , ''
    , filtro_judicy
  )
}