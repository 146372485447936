import { TipoLista } from "./tipoLista";

export class TipoAudienciaModelo extends TipoLista {
  constructor(
    
  ) {
    super();
    this.prepara_lista()
  }

  prepara_lista(): void {
    this.adicionar_item('Presencial','Presencial')
    this.adicionar_item('Vídeo Conferência','Vídeo Conferência')
  }

}
