import { TipoLista } from "./tipoLista";

export class TipoParteSexo extends TipoLista {
  constructor(
    
  ) {
    super();
    this.prepara_lista()
  }

  prepara_lista(): void {
    this.adicionar_item('F','Feminino')
    this.adicionar_item('M','Masculino')
  }

}