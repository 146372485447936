import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { domainForum } from "src/app/domain/forum";
import { TipoTexto } from "../tipos_de_dados/tipoTexto";
import { classeEntidadeDefault } from "./classeEntidadeDefault";
import { TipoCEP } from "../tipos_de_dados/tipoCEP";
import { TipoVara } from "../tipos_de_dados/tipoVara";

@Injectable({
  providedIn: 'root'
})
export class classeEntidadeForum extends classeEntidadeDefault {

//construtor

  instanciar(): void {
    this.nome = 'forum'
    this.nome_externo = 'Fórum'
    this.icone = 'apartment'
    this.carregar_datatable()
  }
  
  configurar_layout(): void {

    this.reinicializa()
    this.incluir_campo('forum'        , 'Fórum'        , new TipoTexto       , true, true , true, false, '', [Validators.required])
    // this.incluir_campo('endereco'     , 'Endereço'     , new TipoTexto       , true, true , true, false, '', [Validators.required])
    this.incluir_campo('cep'          , 'CEP'          , new TipoCEP         , true, true , true, false, '', [Validators.required], true, false)
    this.incluir_campo('logradouro'   , 'Logradouro'   , new TipoTexto       , true, true , true, false, '', [Validators.required], true, false)
    this.incluir_campo('numero'       , 'Número'       , new TipoTexto       , true, true , true, false, '', [Validators.required], true, false)
    this.incluir_campo('complemento'  , 'Complemento'  , new TipoTexto       , true, true , true, false, '', undefined            , true, false)
    this.incluir_campo('bairro'       , 'Bairro'       , new TipoTexto       , true, true , true, false, '', [Validators.required], true, false)
    this.incluir_campo('cidade'       , 'Cidade'       , new TipoTexto       , true, true , true, false, '', [Validators.required], true, false)
    this.incluir_campo('uf'           , 'UF'           , new TipoTexto       , true, true , true, false, '', [Validators.required], true, false)

  }

  formatar_detalhe_externo(registro: any): string {
    var forum: domainForum = registro

    var formatado: string

    formatado = forum.forum!

    return formatado

  }

}