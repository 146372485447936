import { TipoLista } from "./tipoLista";

export class TipoAlertaEvento extends TipoLista {
  constructor(
    
  ) {
    super();
    this.prepara_lista()
  }

  prepara_lista(): void {
    this.adicionar_item('ANIVERSARIO','ANIVERSARIO')
    this.adicionar_item('PRAZO','PRAZO')
    this.adicionar_item('AUDIENCIA','AUDIENCIA')
    this.adicionar_item('FIM_DE_ANO','FIM_DE_ANO')
  }

}