import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { TipoData } from "../tipos_de_dados/tipoData";
import { TipoHora } from "../tipos_de_dados/tipoHora";
import { TipoTexto } from "../tipos_de_dados/tipoTexto";
import { classeEntidadeDefault } from "./classeEntidadeDefault";
import { TipoNumeroProcessoLink } from "../tipos_de_dados/tipoNumeroProcessolink";
import { TipoAudienciaModelo } from "../tipos_de_dados/tipoAudienciaModelo";
import { TipoAudienciaTipo } from "../tipos_de_dados/tipoAudienciaTipo";
import { TipoAudienciaaStatus } from "../tipos_de_dados/tipoAudienciaStatus";
import { TipoTextoLongo } from "../tipos_de_dados/tipoTextoLongo";
import { domainAudiencia } from "src/app/domain/audiencia";
import { Condicao, CondicaoDestacar, Condicoes } from "src/app/domain/acao";
import { valida_audiencia_ja_agendada } from "src/app/validadores/validador_audiencia_existente";
import { TipoOculto } from "../tipos_de_dados/TipoOculto";
@Injectable({
  providedIn: 'root'
})
export class classeEntidadeAudiencia extends classeEntidadeDefault {

//construtor
  
  instanciar (): void {
    this.nome =  'audiencia'
    this.nome_externo = 'Audiência'
    this.icone = 'gavel'
    this.carregar_datatable()
    this.configurar_layout()
    this.reconfigurar_botoes()
    this.configurar_relatorios()
    this.configurar_dados_parent()
    this.configurar_destaque_linhas()
  }
  
  configurar_dados_parent(): void {
       this.campo_id_parent = 'id_processo'
       this.entidade_parent = 'processo'
  }
  
  configurar_layout(): void {
    this.layout = [
      {nome_campo: 'data'           ,nome_descritivo:'Data'           , tipo: new TipoData                          , cadastravel: true        , editavel:true , exibe_consulta: true , indicador_campo_parent: false, nome_parent:''        , validacao: [Validators.required], condicao_exibicao: [], condicao_habilitacao: [], validacao_assincrona: []  }
     ,{nome_campo: 'hora'           ,nome_descritivo:'Hora'           , tipo: new TipoHora                          , cadastravel: true        , editavel:true , exibe_consulta: true , indicador_campo_parent: false, nome_parent:''        , validacao: [Validators.required], condicao_exibicao: [], condicao_habilitacao: [], validacao_assincrona: []  }
     ,{nome_campo: 'tipo'           ,nome_descritivo:'Tipo'           , tipo: new TipoAudienciaTipo                 , cadastravel: true        , editavel:true , exibe_consulta: true , indicador_campo_parent: false, nome_parent:''        , validacao: [Validators.required], condicao_exibicao: [], condicao_habilitacao: [], validacao_assincrona: [valida_audiencia_ja_agendada('processoid_processo', this.judicy)]  }
     ,{nome_campo: 'modelo'         ,nome_descritivo:'Modelo'         , tipo: new TipoAudienciaModelo               , cadastravel: true        , editavel:true , exibe_consulta: true , indicador_campo_parent: false, nome_parent:''        , validacao: [Validators.required], condicao_exibicao: [], condicao_habilitacao: [], validacao_assincrona: []  }
     ,{nome_campo: 'responsavel'    ,nome_descritivo:'Responsável'    , tipo: this.lista.usuario                    , cadastravel: true        , editavel:true , exibe_consulta: true , indicador_campo_parent: false, nome_parent:''        , validacao: [Validators.required], condicao_exibicao: [], condicao_habilitacao: [], validacao_assincrona: []  }
     ,{nome_campo: 'descricao'      ,nome_descritivo:'Descrição'      , tipo: new TipoTextoLongo                    , cadastravel: true        , editavel:true , exibe_consulta: false, indicador_campo_parent: false, nome_parent:''        , validacao: []                   , condicao_exibicao: [], condicao_habilitacao: [], validacao_assincrona: []  }
     ,{nome_campo: 'status'         ,nome_descritivo:'Status'         , tipo: new TipoAudienciaaStatus              , cadastravel: false       , editavel:true , exibe_consulta: true , indicador_campo_parent: false, nome_parent:''        , validacao: [Validators.required], condicao_exibicao: [], condicao_habilitacao: [], validacao_assincrona: []  }
     ,{nome_campo: 'testemunhas'    ,nome_descritivo:'Testemunhas'    , tipo: new TipoTextoLongo                    , cadastravel: true        , editavel:true , exibe_consulta: true , indicador_campo_parent: false, nome_parent:''        , validacao: []                   , condicao_exibicao: [], condicao_habilitacao: [], validacao_assincrona: []  }
     ,{nome_campo: 'numero_pasta'   ,nome_descritivo:'Pasta'          , tipo: new TipoTexto                         , cadastravel: false       , editavel:false, exibe_consulta: true , indicador_campo_parent: true , nome_parent:'processo', validacao: []                   , condicao_exibicao: [], condicao_habilitacao: [], validacao_assincrona: []  }
     ,{nome_campo: 'autor'          ,nome_descritivo:'Autor'          , tipo: new TipoTexto                         , cadastravel: false       , editavel:false, exibe_consulta: true , indicador_campo_parent: true , nome_parent:'processo', validacao: []                   , condicao_exibicao: [], condicao_habilitacao: [], validacao_assincrona: []  }
     ,{nome_campo: 'reu'            ,nome_descritivo:'Réu'            , tipo: new TipoTexto                         , cadastravel: false       , editavel:false, exibe_consulta: true , indicador_campo_parent: true , nome_parent:'processo', validacao: []                   , condicao_exibicao: [], condicao_habilitacao: [], validacao_assincrona: []  }
     ,{nome_campo: 'numero_processo',nome_descritivo:'Número Processo', tipo: new TipoNumeroProcessoLink(this.util) , cadastravel: false       , editavel:false, exibe_consulta: true , indicador_campo_parent: true , nome_parent:'processo', validacao: []                   , condicao_exibicao: [], condicao_habilitacao: [], validacao_assincrona: []  }
     ,{nome_campo: 'id_processo'    ,nome_descritivo:'ID Processo'    , tipo: new TipoOculto                        , cadastravel: true        , editavel:true , exibe_consulta: false, indicador_campo_parent: true , nome_parent:'processo', validacao: []                   , condicao_exibicao: [], condicao_habilitacao: [], validacao_assincrona: []  }
   ]
   }

  reconfigurar_botoes() {
    this.acoes_nivel_registro = []
    this.tipo_operacao.ALTERAR.executada_apenas_por_criador = false
    this.tipo_operacao.EXCLUIR.executada_apenas_por_criador = false
    this.acao_duplo_clique = this.tipo_operacao.ALTERAR
    this.acoes_nivel_registro.push(this.tipo_operacao.ALTERAR)
    // this.acoes_nivel_registro.push(this.tipo_operacao.EXCLUIR)

    var cancelado : Condicao  = {}
    cancelado.campo = 'status'
    cancelado.valor = 'C'
    cancelado.tipo = true

    var realizado : Condicao  = {}
    realizado.campo = 'status'
    realizado.valor = 'R'
    realizado.tipo = true

    var cond : Condicoes = {}
    cond.condicoes = [cancelado, realizado]

    this.tipo_operacao.CANCELAR.condicao_desabilitar = cond
    this.tipo_operacao.CONCLUIR.condicao_desabilitar = cond
    
    this.acoes_nivel_registro.push(this.tipo_operacao.CANCELAR)

    // this.acoes_nivel_registro.push(this.tipo_operacao.CONCLUIR)



  }

  configurar_relatorios() {
    //relatorio.formatos = ['pdf', 'docx', 'html', 'xlsx']
    this.acoes_nivel_registro.push(this.tipo_operacao.RELATORIO)

    this.lista_relatorio = [
      { formatos : ['docx'], icone : 'mail', descritivo : 'Carta', nome : 'carta' }
     ,{ formatos : ['html'], icone : 'schedule_send', descritivo : 'Aviso de Recebimento', nome : 'aviso_recebimento' }
    ]

  }

  formatar_detalhe_externo(registro: any): string {
    var audiencia: domainAudiencia = registro

    var formatado: string

    formatado = audiencia.descricao!

    return formatado

  }

  configurar_destaque_linhas(): void {
    var agendado : CondicaoDestacar  = {}
    agendado.campo = 'status'
    agendado.valor = 'A'
    agendado.estilo = 'azul' 

    var redesignado : CondicaoDestacar  = {}
    redesignado.campo = 'status'
    redesignado.valor = 'D'
    redesignado.estilo = 'azul' 

    var cancelado : CondicaoDestacar  = {}
    cancelado.campo = 'status'
    cancelado.valor = 'C'
    cancelado.estilo = 'desfocada' 

    var condicoes = [agendado, redesignado, cancelado]
    this.condicao_destacar = condicoes

  }
}