<mat-card>
    <mat-card-title>
        <i class="material-icons">
            history
        </i>
        Histórico Operações
    </mat-card-title>

    <mat-card-subtitle>
        Aqui você visualiza cada operação realizada no Judicy.
    </mat-card-subtitle>
    
</mat-card>

<div class="divDataTable">
    <app-datatable ENTIDADE="log" ID_PARENT=""></app-datatable>
</div>