import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { classeCatalogo, classeTipoMensagem } from 'src/app/classe/mensagens';
import { TipoOperadorJudicy } from 'src/app/domain/tipoOperadorJudicy';

@Injectable({
  providedIn: 'root'
})
export class UtilidadesService {
  estilo: string = '';
  icone: string = '';

  constructor(private sb: MatSnackBar
    , private rotaURL: Router

  ) { }

  tpMsg: classeTipoMensagem = new (classeTipoMensagem)

  exibirMensagem(msg: string, tipo: string): void {

    if (tipo == this.tpMsg.erro()) {
      this.estilo = 'estilo-mensagem-erro'
      this.icone = 'error'
    };

    if (tipo == this.tpMsg.sucesso()) {
      this.estilo = 'estilo-mensagem-sucesso'
      this.icone = 'check_circle'
    };

    if (tipo == this.tpMsg.advertencia()) {
      this.estilo = 'estilo-mensagem-advertencia'
      this.icone = 'warning'

    };


    if (tipo == this.tpMsg.informacao()) {
      this.estilo = 'estilo-mensagem-informacao'
      this.icone = 'info'
    };

    this.sb.open(msg, 'x', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: this.estilo
    }
    )
  }

  downLoadArquivo(p_arquivo_base_64: any, p_tipo: string, p_nome_arquivo: string) {
    var arquivo_blob = this.base64_para_blob(p_arquivo_base_64, p_tipo);
    var arquivo_convertido = new File([arquivo_blob], p_nome_arquivo, { type: p_tipo });

    let blob = new Blob([arquivo_convertido], { type: p_tipo });
    let url = window.URL.createObjectURL(blob);
    var anchor = document.createElement("a");
    anchor.setAttribute('download', p_nome_arquivo);
    anchor.setAttribute('href', url);
    anchor.click();
  }

  base64_para_blob(base64: string, tipo: string) {
    const byteString = window.atob(base64);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: tipo });
    return blob;
  }

  // -------------------------------------- VARIAVEIS DE MENSAGENS PADRAO //
  catalogo: classeCatalogo = new (classeCatalogo);
  tp_msg: classeTipoMensagem = new (classeTipoMensagem);

  formataParametrosFiltro(p_filtro: any, p_id?: number, p_campo?: string): string {
    var listaFiltro = [];
    var retorno: string = '';

    if (p_id != 0 && p_id != undefined) {
      listaFiltro.push({ campo: p_campo, valor: p_id.toString(), operador_logico: TipoOperadorJudicy.IGUAL })
    }

    for (var [v_campo, v_valor] of Object.entries(p_filtro)) {
      if (v_valor != '' && v_valor != '[object Object]') {
        listaFiltro.push({ campo: v_campo, valor: v_valor + '*', operador_logico: TipoOperadorJudicy.INICIA_COM })
      }
    }

    if (listaFiltro.toString() != '') {
      retorno = '{ "filtro":' + JSON.stringify(listaFiltro) + '}';
    }

    return retorno;
  }

  navegarPara(pagina: string): void {
    this.rotaURL.navigate([pagina]);
  }

  abrirEmNovaJanela(pagina: string): void {
    // Converts the route into a string that can be used 
    // with the window.open() function
    const url = this.rotaURL.serializeUrl(
      this.rotaURL.createUrlTree([pagina])
    );

    window.open(url, '_blank');
  }

  adicionarDias(data: Date, quantidade_dias: number) : Date {
    var v_data = new Date(data)
     return new Date(v_data.getTime() + this.converteDiaParaMilisegundo(quantidade_dias) )
  }

  subtrairDias(data: Date, quantidade_dias: number) : Date {
    var v_data = new Date(data)
    return new Date(v_data.getTime() - this.converteDiaParaMilisegundo(quantidade_dias) )
 }

  converteDiaParaMilisegundo(quantidade_dias: number): number {
    var horas : number = 24 * quantidade_dias
    var minutos: number = 60 * horas
    var segundos : number = 60 * minutos
    var mili_segundos : number = 1000 * segundos

    return mili_segundos
  }
}
