<mat-card class="mat-elevation-z3">
    <mat-card-title class="titulo">
        CONTRATO 000.000.001
    </mat-card-title>
    <mat-card-content>
        <br>
        <p>
            <!-- {{this.processo.autor}} x {{this.processo.reu}} -->
            <strong>
                JOSE DA SILVA x RGB COMERCIO LTDA
            </strong>
        </p>
        <div class="flex-container">
            <div class="flex-item">
                <table class="div1">
                    <tr>
                        <td class='label'><strong>Endereço: </strong></td>
                        <td class='valor'>Hildebrandeo de Lima, 409</td>
                    </tr>
                </table>
            </div>
        </div>
    </mat-card-content>

</mat-card>

<br><br>

<mat-card class=" mat-elevation-z3">
    <p>
        <strong>LOCAIS DE TRABALHO</strong> | ADMISSÃO | HORÁRIO | REMUNERAÇÃO | DEMISSÃO | FGTS | FÉRIAS | OBS
    </p>
    <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- Position Column -->
        <ng-container matColumnDef="position">
            <mat-header-cell *matHeaderCellDef> CNPJ </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.position}} </mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Razão Social </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="weight">
            <mat-header-cell *matHeaderCellDef> Local </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.weight}} </mat-cell>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="symbol">
            <mat-header-cell *matHeaderCellDef> Endereço </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.symbol}} </mat-cell>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="data_inicio">
            <mat-header-cell *matHeaderCellDef> Data Início </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.data_inicio}} </mat-cell>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="data_fim">
            <mat-header-cell *matHeaderCellDef> Data Fim </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.data_fim}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <br>

    <mat-card-content>
        <mat-form-field class="example-full-width">
            <mat-label>OBS</mat-label>
            <textarea matInput placeholder="Ex. Andar 20º sala 21..."></textarea>
        </mat-form-field>
    </mat-card-content>
</mat-card>