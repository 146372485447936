import { AbstractControl } from '@angular/forms';

export function valida_cpf_cnpj(
  campo_tipo_documento: string) {
  return (controle_documento: AbstractControl) => {
    var tipo_documento = controle_documento.root.get(campo_tipo_documento)
    var documento = controle_documento
    var retorno = {}

    if (verifica_digito(documento?.value, tipo_documento?.value)) {
      retorno = { digito: true };
    }

    if (verifica_tamanho(documento?.value, tipo_documento?.value)) {
      retorno = { tamanho: true };
    }

    if (documento?.value == undefined || documento?.value == '') {
      retorno = { required: true };
    }

    return retorno     

  }
}

export function verifica_tamanho(documento: string, tipo: number) {
  var documento_invalido = false
  var tamanho_cpf = 11
  var tamanho_cnpj = 14
  var tamanho_validar = 0

  try {
    if (tipo == 1) {
      tamanho_validar = tamanho_cpf
    } else {
      tamanho_validar = tamanho_cnpj
    }

    if (documento.length != tamanho_validar) {
      documento_invalido = true
    }
  } catch {
    documento_invalido = true
  }

  return documento_invalido
}

export function verifica_digito(documento: string, tipo: number) {
  var documento_invalido = true
  var tamanho_cpf = 11
  var tamanho_cnpj = 14
  var tamanho_validar = 0

  if (tipo == 1) {
    tamanho_validar = tamanho_cpf
  } else {
    tamanho_validar = tamanho_cnpj
  }

  try {
    var documento_sem_ultimos_dois_digitos = documento.substring(0, tamanho_validar - 2)

    var dv_calculado = calcularDigitoVerificador(tipo, documento_sem_ultimos_dois_digitos);

    var ultimos_dois_digitos_documento = documento.substring(tamanho_validar - 2, tamanho_validar)

    if (dv_calculado == ultimos_dois_digitos_documento) {
      documento_invalido = false
    }
  } catch {
    documento_invalido = true
  }

  return documento_invalido
}

export function calcularDigitoVerificador(tipo: number, doc: string) {
  var primDig = 0
  var segDig = 0
  var peso_cpf = 11
  var peso_cnpj = 16
  var peso = 0

  if (tipo == 1) {
    peso = peso_cpf
  } else {
    peso = peso_cnpj
  }

  primDig = calculaDigito(tipo, peso - 1, doc);
  segDig = calculaDigito(tipo, peso, doc.concat(primDig.toString()));

  return primDig.toString() + segDig.toString();
}

export function calculaDigito(tipo: number, peso: number, num: string) {
  var sumarizado = 0;
  var result = 0;
  var resto = 0;
  var multi;

  for (let i = 0; i < num.length; i++) {

    if (tipo == 1) {
      multi = peso
    }
    else {
      if (peso > 11) {
        multi = peso - 10;
      } else {
        multi = peso - 2;
      }
    }

    sumarizado += Number(num.substring(i, i + 1)) * multi;
    peso = peso - 1;
  }

  resto = sumarizado % 11;
  result = 11 - resto;

  if (result > 9) {
    result = 0;
  }

  return result;
}