<div class='divDataTable mat-elevation-z3'>

    <mat-toolbar>
        <mat-toolbar-row>
            <button mat-raised-button color="primary" class="btn-incluir" (click)="limpar_filtro()"
                matTooltip='Limpar Filtro'>
                <mat-icon>
                    clear_all
                </mat-icon>
            </button>

            <button *ngFor="let acao of acoes_nivel_tabela" mat-raised-button (click)='executar_operacao({},acao)'
                class='{{acao.estilo}}' matTooltip='{{acao.descricao}} {{entidade.nome_externo}}'>
                <i class='material-icons'>
                    {{acao.icone}}
                </i>
            </button>

            <span class="espacamento"></span>

            <mat-paginator [pageSizeOptions]='[100, 5, 25, 50, 10]' [length]="fonteDados.total_registros"
                showFirstLastButtons class='paginacao-fixa'>
            </mat-paginator>
        </mat-toolbar-row>
    </mat-toolbar>

    <table mat-table [dataSource]='fonteDados' matSort aria-label='Elements' class='tabelaDataTable'>

        <ng-container *ngFor="let campo of layout_tabela" matColumnDef={{campo.nome_campo}}>
            <th mat-header-cell *matHeaderCellDef>
                <div class="divColunaDataTable">
                    <span mat-sort-header>{{campo.nome_descritivo}}</span>

                    <span *ngIf="campo.tipo!.codigo_tipo! === 'texto' || campo.tipo!.codigo_tipo! === 'mascara'">
                        <input class='inputFiltro' [(ngModel)]="filtro[campo.nome_campo]"
                            name='input_{{campo.nome_campo}}' (change)='this.aplicaFiltro()'>
                    </span>

                    <span *ngIf="campo.tipo!.codigo_tipo! === 'hora'">
                        <input class='inputFiltro' [(ngModel)]="filtro[campo.nome_campo]"
                            name='input_{{campo.nome_campo}}' (change)='this.aplicaFiltro()'>
                    </span>

                    <span *ngIf="campo.tipo!.codigo_tipo! === 'data'">
                        <div class="inputFiltro">
                            <mat-toolbar class="barraData">
                                <mat-date-range-input [rangePicker]="picker" class="inputRange" [formGroup]="range">

                                    <input class="inputDataInicio" matStartDate placeholder="" #dataInicio
                                        formControlName="start" readonly>

                                    <input class="inputDataFim" matEndDate placeholder="" #dataFim
                                        (dateChange)="this.aplicaFiltroData(campo.nome_campo,dataInicio,dataFim)"
                                        formControlName="end" readonly>

                                </mat-date-range-input>

                                <mat-datepicker-toggle matSuffix [for]="picker"
                                    class="seletorData"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>
                            </mat-toolbar>

                        </div>
                    </span>

                    <span *ngIf="campo.tipo!.codigo_tipo! === 'lista'">
                        <select class='inputFiltro' name="inputTipo" [(ngModel)]="filtro[campo.nome_campo]"
                            (change)='this.aplicaFiltro()'>
                            <option value="">Todos</option>
                            <option *ngFor="let item of campo.tipo!.lista" value="{{item.codigo}}">{{item.valor}}
                            </option>
                        </select>
                    </span>

                </div>
            </th>
            <td mat-cell *matCellDef='let registro' class="{{get_estilo_linha(registro)}}">
                <span *ngIf="campo.tipo!.codigo_tipo! === 'link'" (click)="campo.tipo!.acessar_link(registro)"
                class="{{get_estilo_link(registro, campo)}}">
                    {{exibir_campo(registro, campo)}}
                </span>

                <span *ngIf="campo.tipo!.codigo_tipo! === 'foto'">
                    <div class="estilo-foto-div">
                        <img [src]="exibir_campo(registro, campo)" class="estilo-foto-img">
                    </div>
                </span>

                <span *ngIf="campo.tipo!.codigo_tipo! != 'link' && campo.tipo!.codigo_tipo! != 'foto'"
                    class="estilo-registro">
                    <div style="max-width: 400px;word-wrap: break-word;">
                        <p class="exibir-com-quebra-de-linha">{{exibir_campo(registro, campo)}}</p>
                    </div>
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef='acao'>
            <th mat-header-cell *matHeaderCellDef>Ação</th>

            <td mat-cell *matCellDef='let registro' class="{{get_estilo_linha(registro)}} colunaAcao">
                <ng-container *ngFor="let acao of acoes_nivel_registro">
                    <button [disabled]='verificaDesabilitarAcao(registro,acao)' mat-raised-button
                        (click)='executar_operacao(registro,acao)' class='{{acao.estilo}}'
                        *ngIf="!verificaOcultarAcao(registro,acao)"
                        matTooltip='{{acao.descricao}} {{entidade.nome_externo}}'>
                        <i class='material-icons'>
                            {{acao.icone}}
                        </i>
                    </button>
                </ng-container>

            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef='cabecalho_da_tabela; sticky:true'></tr>

        <tr mat-row *matRowDef='let registro; columns: cabecalho_da_tabela;'
            (dblclick)='executar_operacao_duplo_clique(registro)' class='registroTabela' (click)="selecionar(registro)">
        </tr>

    </table>


    <div *ngIf="fonteDados.loading$ | async" class="spinner-container">
        <mat-progress-spinner color="primary" mode="indeterminate">
        </mat-progress-spinner>
    </div>

    <div *ngIf='fonteDados.total_registros === 0' class="texto">
        Nenhum registro encontrado.
    </div>

</div>
<mat-toolbar class="rodape mat-elevation-z3">
</mat-toolbar>