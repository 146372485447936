import { TipoLista } from "./tipoLista";

export class TipoUsuarioStatus extends TipoLista {
  constructor(
    
  ) {
    super();
    this.prepara_lista()
  }

  prepara_lista(): void {
    this.adicionar_item('I','Inativo')
    this.adicionar_item('B','Bloqueado')
    this.adicionar_item('A','Ativo')

  }

}