import { TipoLista } from "./tipoLista";

export class TipoAlertaTipo extends TipoLista {
  constructor(
    
  ) {
    super();
    this.prepara_lista()
  }

  prepara_lista(): void {
    this.adicionar_item('EMAIL','EMAIL')
    this.adicionar_item('WHATSAPP','WHATSAPP')
    this.adicionar_item('SMS','SMS')
    this.adicionar_item('MSGBOX','MSGBOX')

  }

}