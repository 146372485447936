<mat-card class="mat-elevation-z3">
    <mat-card-title class="titulo">
        Processo
    </mat-card-title>

    <mat-card-subtitle class="subtitulo">
        Gestão de Processos Trabalhistas
    </mat-card-subtitle>
    <mat-card-content>
        <p>
            Olá!
        </p>

        <p>
            Nesta sessão você pode gerenciar todos os detalhes do Processo Judicial.
        </p>
        
        <p>
            Você poderá incluir e visualizar todo o detalhe de um processo.
            Também tera opeções para visualizar suas audiências, publicações e Prazos de forma geral.
        </p>

        <p>
            Faça bom proveito desta ferramenta poderosa! 
       </p>
    </mat-card-content>

</mat-card>

<br>

<mat-card class=" mat-elevation-z3">
    <mat-card-title class="titulo">
        Estatísticas
    </mat-card-title>

    <mat-card-subtitle class="subtitulo">
        Números Gerais
    </mat-card-subtitle>

    <mat-card-content>
        <p>
            Total de Processos Existentes: {{total.processo | number}}
        </p>

        <p>
            Total de Publicações Existentes: {{total.publicacao | number}}
        </p>

        <p>
            Total de Audiências Existentes: {{total.audiencia | number}}
        </p>

    </mat-card-content>
</mat-card>