<mat-card class="card-principal" *ngIf="!flag_ficha_entrevista">
  <mat-card-title>
    <i class="material-icons">
      post_add
    </i>
    {{parte.nome}}
    <a (click)='alterarParte()' matTooltip='Alterar' matTooltipPosition='right' class="link">
      <i class='material-icons '>
        edit
      </i>
    </a>
  </mat-card-title>

  <mat-grid-list cols='4' rowHeight='100px'>

    <mat-grid-tile colspan="2" *ngIf='parte.tipo_documento === "1"'>
      <div class='div-resumo'>
        <tr>
          <td class='label'>{{tipo_documento_formata_para_exibir(parte.tipo_documento)}}:
          </td>
          <td class='valor'>{{documento_formata_para_exibir(parte.documento,parte.tipo_documento)}}</td>
        </tr>
        <tr>
          <td class='label'>RG: </td>
          <td class='valor'>{{parte.rg}} - {{parte.orgao_emissor}}</td>
        </tr>
        <tr>
          <td class='label'>Estado Civíl: </td>
          <td class='valor'>{{estado_civil_formata_para_exibir(parte.estado_civil)}}</td>
        </tr>
        <tr>
          <td class='label'>Profissão: </td>
          <td class='valor'>{{parte.profissao}}</td>
        </tr>
        <tr>
          <td class='label'>Nacinalidade: </td>
          <td class='valor'>{{parte.nacionalidade}}</td>
        </tr>
      </div>
    </mat-grid-tile>

    <mat-grid-tile *ngIf='parte.tipo_documento === "1"' colspan="2">
      <div class='div-resumo'>
        <tr>
          <td class='label'>Data Nascimento: </td>
          <td class='valor'>{{data_formata_para_exibir(parte.data_nascimento)}}</td>
        </tr>
        <tr>
          <td class='label'>Nome da Mãe: </td>
          <td class='valor'>{{parte.nome_mae}}</td>
        </tr>
        <tr>
          <td class='label'>Email: </td>
          <td class='valor'>{{parte.email}}</td>
        </tr>
        <tr>
          <td class='label'>CTPS: </td>
          <td class='valor'>Nº {{parte.ctps_numero}} / Série {{parte.ctps_serie}}</td>
        </tr>
        <tr>
          <td class='label'>PIS: </td>
          <td class='valor'>{{parte.pis}}</td>
        </tr>
      </div>
    </mat-grid-tile>

    <mat-grid-tile *ngIf='parte.tipo_documento === "2"'>
      <div class='div-resumo'>
        <tr>
          <td class='label'>{{tipo_documento_formata_para_exibir(parte.tipo_documento)}}:
          </td>
          <td class='valor'>{{documento_formata_para_exibir(parte.documento,parte.tipo_documento)}}</td>
        </tr>
        <tr>
          <td class='label'>RG: </td>
          <td class='valor'>{{parte.rg}} - {{parte.orgao_emissor}}</td>
        </tr>
        <tr>
          <td class='label'>Email: </td>
          <td class='valor'>{{parte.email}}</td>
        </tr>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

  <!-- <app-endereco-data-table></app-endereco-data-table>
    <app-contato-data-table></app-contato-data-table>
    <app-conta-bancaria-data-table></app-conta-bancaria-data-table> -->

  <app-datatable-simplista ENTIDADE="endereco" ID_PARENT="{{this.id_parte}}"></app-datatable-simplista>
  <app-datatable-simplista ENTIDADE="contato" ID_PARENT="{{this.id_parte}}"></app-datatable-simplista>
  <app-datatable-simplista ENTIDADE="conta_bancaria" ID_PARENT="{{this.id_parte}}"></app-datatable-simplista>

</mat-card>

<div *ngIf="flag_ficha_entrevista">
  <mat-card class="calendario">
    <mat-card-title>
      <i class="material-icons">
        description
      </i>


      {{parte.nome}}
    </mat-card-title>


    <mat-card-content>
      <div class="flex-container">
        <div class="flex-item">
          <table class="div1">
            <tr>
              <td class='label'>CPF: </td>
              <td class='valor'>{{documento_formata_para_exibir(parte.documento,parte.tipo_documento)}}</td>
            </tr>
            <tr>
              <td class='label'>Data Nascimento: </td>
              <td class='valor'>{{data_formata_para_exibir(parte.data_nascimento)}}</td>
            </tr>
            <tr>
              <td class='label'>Endereço: </td>
              <td class='valor'>endereco123</td>
            </tr>
          </table>
        </div>
        <div class="flex-item">
          <table class="div1">
            <tr>
              <td class='label'>Telefone: </td>
              <td class='valor'>(INSERIR TELEFONE)</td>
            </tr>
            <tr>
              <td class='label'>E-Mail: </td>
              <td class='valor'>{{parte.email}}</td>
            </tr>
            <!-- Adicione mais linhas conforme necessário -->
          </table>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <br>

  <mat-accordion>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="icon_person">
            <i class="material-icons">
              person
            </i>
            Dados pessoais
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <table>
        <tr>
          <td class='label'>CPF: </td>
          <td class='valor'>{{documento_formata_para_exibir(parte.documento,parte.tipo_documento)}}</td>
        </tr>
        <tr>
          <td class='label'>RG: </td>
          <td class='valor'>{{parte.rg}}</td>
        </tr>
        <tr>
          <td class='label'>Estado Civil: </td>
          <td class='valor'>{{estado_civil_formata_para_exibir(parte.estado_civil)}}</td>
        </tr>
        <tr>
          <td class='label'>Data Nascimento: </td>
          <td class='valor'>{{data_formata_para_exibir(parte.data_nascimento)}}</td>
        </tr>
        <tr>
          <td class='label'>Nome da Mãe: </td>
          <td class='valor'>{{parte.nome_mae}}</td>
        </tr>
        <tr>
          <td class='label'>Email: </td>
          <td class='valor'>{{parte.email}}</td>
        </tr>
        <tr>
          <td class='label'>CTPS: </td>
          <td class='valor'>Nº {{parte.ctps_numero}} / Série {{parte.ctps_serie}}</td>
        </tr>
        <tr>
          <td class='label'>PIS: </td>
          <td class='valor'>{{parte.pis}}</td>
        </tr>
        <tr>
          <td class='label'>Indicado Por: </td>
          <td class='valor'>(INSERIR INDICADO)</td>
        </tr>
      </table>
    </mat-expansion-panel>
    <br>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="icon_person">
            <i class="material-icons">
              home
            </i>
            Endereços [+]

          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-datatable-simplista ENTIDADE="endereco" ID_PARENT="{{this.id_parte}}"></app-datatable-simplista>
    </mat-expansion-panel>
    <br>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="icon_person">
            <i class="material-icons">
              call
            </i>
            Contatos [+]
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-datatable-simplista ENTIDADE="contato" ID_PARENT="{{this.id_parte}}"></app-datatable-simplista>
    </mat-expansion-panel>
    <br>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="icon_person">
            <i class="material-icons">
              account_balance
            </i>
            Conta bancária [+]
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-datatable-simplista ENTIDADE="conta_bancaria" ID_PARENT="{{this.id_parte}}"></app-datatable-simplista>
    </mat-expansion-panel>
    <br>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="icon_person">
            <i class="material-icons">
              description
            </i>
            Ficha de entrevista [+]
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-datatable ENTIDADE="prazo" ID_PARENT=""></app-datatable>
    </mat-expansion-panel>
    <br>
  </mat-accordion>
</div>