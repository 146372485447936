import { Injectable } from "@angular/core";
import { classeEntidadeReu } from "./classeEntidadeReu";

@Injectable({
  providedIn: 'root'
})
export class classeEntidadeAutor extends classeEntidadeReu {
  nome = 'autor'
  nome_externo = 'Autor'

}