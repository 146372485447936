import { TipoLista } from "./tipoLista";

export class TipoPublicacaoStatus extends TipoLista {
  constructor(
    
  ) {
    super();
    this.prepara_lista()
  }

  prepara_lista(): void {
    this.adicionar_item('R','Recebido')
    this.adicionar_item('T','Tratado')
    this.adicionar_item('D','Descartado')

  }

}