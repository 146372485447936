import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { JudicyService } from "src/app/servicos/judicy.service";
import { UtilidadesService } from "src/app/servicos/utilidades.service";
import { TipoData } from "../tipos_de_dados/tipoData";
import { ListasDeBancoDeDados } from "../tipos_de_dados/tipoListaBancoDeDados";import { TipoTarefaStatus } from "../tipos_de_dados/tipoTarefaStatus";
import { TipoTarefaTipo } from "../tipos_de_dados/tipoTarefaTipo";
import { TipoTexto } from "../tipos_de_dados/tipoTexto";
import { classeEntidadeDefault } from "./classeEntidadeDefault";
import { TipoListaUsuario } from "../tipos_de_dados/tipoListaUsuario";
import { TipoTimestamp } from "../tipos_de_dados/tipoTimestamp";

@Injectable({
  providedIn: 'root'
})
export class classeEntidadeLog extends classeEntidadeDefault {

//construtor
  
  instanciar (): void {
    this.nome =  'log'
    this.nome_externo = 'Histórico Operações'
    this.icone = 'history'
    this.acao_duplo_clique = { operacao: undefined}
    this.carregar_datatable()
    this.reconfigurar_botoes()
      }
  
  configurar_layout(): void {

    this.reinicializa()
    this.incluir_campo('timestamp', 'Data/Hora', new TipoTimestamp, true, true, true, false, '', [Validators.required])
    this.incluir_campo('usuario_login', 'Login', this.lista.usuario, true, true, true, false, '', [Validators.required])
    this.incluir_campo('operacao', 'Operação', new TipoTexto, true, true, true, false, '', [Validators.required])
    this.incluir_campo('entidade', 'Entidade', new TipoTexto, true, true, true, false, '', [Validators.required])
    this.incluir_campo('registro', 'Registro', new TipoTexto, true, true, true, false, '', [Validators.required])
    this.incluir_campo('requerente', 'Meio Acesso', new TipoTexto, true, true, true, false, '', [Validators.required])
    this.incluir_campo('endereco_ip', 'Região', new TipoTexto, true, true, true, false, '', [Validators.required])
 
  }

  reconfigurar_botoes() {    
    this.acoes_nivel_tabela = []
    this.acoes_nivel_registro = []
  }
    
}