<mat-sidenav-container class="{{getEstiloContainer()}}">

    <mat-sidenav class={{estiloNav}} 
                 mode="side" 
                 opened
                 fixedInViewport="true"
                 fixedTopGap="64"
                 *ngIf="flagUsuarioLogado"
                 (mouseover)="expande()"
                 (mouseleave)="reduz()"
                 >

                 <mat-nav-list>

                    <div *ngFor="let item of menu.itens">
                        <a  
                            *ngIf="item.tipo === tipoMenu"
                            mat-list-item [routerLink]="item.rotaURL"> 
                            <i class='material-icons'>
                                {{item.icone}}
                            </i>
                            {{item.texto}}
                        </a>
                    </div>

        </mat-nav-list>

    </mat-sidenav>

    <mat-sidenav-content class="conteudo">
        <router-outlet></router-outlet>
    </mat-sidenav-content>

</mat-sidenav-container>
