import { TipoLista } from "./tipoLista";

export class TipoAudienciaTipo extends TipoLista {
  constructor(
    
  ) {
    super();
    this.prepara_lista()
  }

  prepara_lista(): void {
    this.adicionar_item('I','Inicial')
    this.adicionar_item('U','Una')
    this.adicionar_item('T','Instrução')
    this.adicionar_item('N','Carta Precatória Inquiritória')
    this.adicionar_item('C','Conciliação')
    this.adicionar_item('P','Perícia')
    this.adicionar_item('O','Controle de Pauta - Ofícios')
    this.adicionar_item('J','Sessão Julgamento')
    this.adicionar_item('M','Sessão Julgamento Pela SUM 197')
    this.adicionar_item('H','Hasta Pública')

  }

}
