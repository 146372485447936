import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { TipoTexto } from "../tipos_de_dados/tipoTexto";
import { classeEntidadeDefault } from "./classeEntidadeDefault";
import { TipoParametroCampo } from "../tipos_de_dados/tipoParametroCampo";
import { domainLista } from "src/app/domain/lista";

@Injectable({
  providedIn: 'root'
})
export class classeEntidadeLista extends classeEntidadeDefault {

//construtor
  
  instanciar (): void {
    this.nome =  'lista'
    this.nome_externo = 'Parâmetro'
    this.icone = 'build'
    this.carregar_datatable()
  }
  
  configurar_layout(): void {

    this.reinicializa()
    this.incluir_campo('lista', 'Parâmetro', new TipoParametroCampo, true, true, true, false, '', [Validators.required])
    this.incluir_campo('valor', 'Valor', new TipoTexto, true, true, true, false, '', [Validators.required])
  }

  formatar_detalhe_externo(registro: any): string {
    var lista: domainLista = registro

    var formatado: string
    var formatador_parametro = new TipoParametroCampo()

    formatado = formatador_parametro.formatar_para_exibir_em_tela(lista.lista!) + ' - ' + lista.valor


    return formatado

  }
    
}