import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { domainRetornoApiJudicy } from 'src/app/domain/apiJudicy';
import { domainLog } from 'src/app/domain/log';
import { domainParte } from 'src/app/domain/parte';
import { domainUsuario } from 'src/app/domain/usuario';
import { SessaoService } from 'src/app/servicos/sessao.service';
import { UtilidadesService } from 'src/app/servicos/utilidades.service';
import { autenticacaoApiJudicy } from 'src/app/domain/apiJudicy';
import { LogControlService } from 'src/app/servicos/logControl.service';
import { enderecoIPService } from 'src/app/servicos/enderecoIP.service';
import { CabecalhoServicoService } from 'src/app/template/cabecalho/cabecalho-servico.service';
import { NavegacaoServicoService } from 'src/app/template/navegacao/navegacao-servico.service';
import { JudicyService } from 'src/app/servicos/judicy.service';
import { Operacao } from 'src/app/domain/acao';
import { ExecutorOperacaoDialog } from 'src/app/classe/OperacaoDialog';
import { TipoEntidade } from 'src/app/classe/entidade/tipoEntidade';
import { TipoDocumentoTipo } from 'src/app/classe/tipos_de_dados/tipoDocumentoTipo';
import { TipoCNPJ } from 'src/app/classe/tipos_de_dados/tipoCNPJ';
import { TipoCPF } from 'src/app/classe/tipos_de_dados/tipoCPF';
import { TipoData } from 'src/app/classe/tipos_de_dados/tipoData';
import { TipoParteEstadoCivil } from 'src/app/classe/tipos_de_dados/tipoParteEstadoCivil';
import { ListasDeBancoDeDados } from 'src/app/classe/tipos_de_dados/tipoListaBancoDeDados';

@Component({
  selector: 'app-parte-detalhe-pagina',
  templateUrl: './parte-detalhe-pagina.component.html',
  styleUrls: ['./parte-detalhe-pagina.component.css']
})
export class ParteDetalhePaginaComponent implements OnInit {
  flag_ficha_entrevista = false;
  panelOpenState = false;

  constructor(
    private judicy: JudicyService,
    private dialog: MatDialog,
    private rota: ActivatedRoute,
    private util: UtilidadesService,
    private sessao: SessaoService,
    private controladorLog: LogControlService
    , private servicoEnderecoIP: enderecoIPService
    , private vCabecalhoServico: CabecalhoServicoService
    , private vNavegacaoServico: NavegacaoServicoService
  ) {
    vCabecalhoServico.cabecalho = {
      titulo: 'Parte > Detalhe',
      icone: 'person_outline',
      rotaURL: 'pgParte'
    }

    vNavegacaoServico.tipoMenu = 'parte';
  }

  id_parte = this.rota.snapshot.paramMap.get("id_parte");
  filtro: domainParte = {}
  retorno_judicy: domainRetornoApiJudicy = {}
  log: domainLog = {}

  parte: domainParte = {}

  flag_carregou: string = 'S'
  
  tipo_documento_formata_para_exibir(tipo: string) : string {
    var tipo_documento = new TipoDocumentoTipo 
    return tipo_documento.formatar_para_exibir_em_tela(tipo)
  }

  data_formata_para_exibir(data?: Date): string {
    var tipo_data = new TipoData
    return tipo_data.formatar_para_exibir_em_tela(data!)
  }

  estado_civil_formata_para_exibir(ec? :string): string {
    var tipo_ec = new TipoParteEstadoCivil
    return tipo_ec.formatar_para_exibir_em_tela(ec!)
  }

  documento_formata_para_exibir(doc?: string, tipo? : string) : string {
    if(tipo = '1') {
      var documento = new TipoCPF 

    } else {
      var documento = new TipoCNPJ 

    }
    return documento.formatar_para_exibir_em_tela(doc!)
  }

  ngOnInit(): void {
    //console.log('parte = ' + JSON.stringify(this.parte))
    this.carrega_pagina()

  }

  carrega_pagina() {
    var id_parte_number: number;

    if (this.id_parte == null || this.id_parte == undefined) {
      id_parte_number = 0
    } else {
      id_parte_number = + this.id_parte
    }

    if (id_parte_number != undefined
      && this.parte.id_parte != undefined
      // && this.operacao == 'alterar_completo' 
    ) {
      id_parte_number = this.parte.id_parte
    }

    this.judicy.listar('parte', 1, 1, '', ''
      , this.util.formataParametrosFiltro(this.filtro, id_parte_number, 'id_parte')
    ).subscribe((resposta: any) => {
      var retornoJudicy: domainRetornoApiJudicy = resposta;

      if (retornoJudicy.status == 0) {
        this.parte = retornoJudicy.conteudo[0]

        if (this.flag_carregou == 'S') {
          this.flag_carregou = 'N'
          this.incluirLogConsulta()
        }
      }

      else {
        this.util.exibirMensagem(retornoJudicy.mensagem!, this.util.tp_msg.erro());
      }


    }
    ), (error: any) => {
      this.util.exibirMensagem(this.util.catalogo.msg_erro_listagem(), this.util.tp_msg.erro());
      console.log('Erro', error);
    }
  }

  get usuarioLogado(): domainUsuario {
    return this.sessao.getUsuarioLogado()
  }

  get enderecoIP(): string {
    return this.servicoEnderecoIP.getEnderecoIP()
  }

  incluirLogConsulta(): void {

    this.log.dominio = this.usuarioLogado.dominio
    this.log.entidade = 'PARTE'
    this.log.operacao = 'CONSULTA'
    this.log.registro = 'DOCUMENTO = ' + this.parte.documento + ' / NOME = ' + this.parte.nome
    this.log.id_registro = this.parte.id_parte
    this.log.usuario_login = this.usuarioLogado.login
    this.log.requerente = autenticacaoApiJudicy.requerente
    this.log.endereco_ip = this.enderecoIP

    this.controladorLog.incluirLog(this.log)

  }

  refresh() {
    this.carrega_pagina()
  }
  
  alterarParte() {
    this.judicy.registro = this.parte
    
    var lista_de_listas = new ListasDeBancoDeDados(this.judicy, this.util)
    var tipo_entidade = new TipoEntidade(this.judicy, this.util, lista_de_listas)
    var entidade = tipo_entidade.getEntidade('parte')['instancia']
    var executor = new ExecutorOperacaoDialog()

    var dialog = executor.execucao_operacao_dialog(this.judicy.registro, Operacao.ALTERAR,
      0
      , this.judicy, entidade, this.dialog)

    dialog.afterAllClosed.subscribe(data => this.refresh())
  }

}
