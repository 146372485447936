<!-- <mat-horizontal-stepper [linear]="vLinear" #stepper id="stepperIncluirParte">

  <mat-step [editable]="vEditavel">
    <ng-template matStepLabel>Cadastro Processo</ng-template> -->

    <mat-card>

      <mat-card-title>
        <i class="material-icons">
          post_add
        </i>
        Processo
      </mat-card-title>
      <app-formulario></app-formulario>

      <button mat-raised-button (click)="navegarPara('pgProcesso')" class="btn-cancelar">
        <i class="material-icons">
          close
        </i>
        Cancelar
      </button>

      <button mat-raised-button (click)="incluirProcesso()" class="btn-confirmar">
        <i class="material-icons">
          check
        </i>
        Cadastrar
      </button>

    </mat-card>

 <!-- </mat-step>

   <mat-step [editable]="vEditavel">
    <ng-template matStepLabel>Partes</ng-template>

    <mat-card>
      <mat-card-title>
        <i class="material-icons icone">
          contact_mail
        </i>
        Partes
      </mat-card-title>
      <mat-card-subtitle>
        Inclua os Autores e Réus do Processo {{processoServico.processo.numero_processo}}.
      </mat-card-subtitle>
      <app-autor-data-table></app-autor-data-table>  
      <app-reu-data-table></app-reu-data-table>  
      <div style="padding-top: 10px;">
      <button mat-raised-button (click)="passoSeguinte()" class="btn-confirmar" matTooltip="Próxima Etapa"
        matTooltipPosition="below">
        <i class="material-icons">
          navigate_next 
        </i> Próximo
      </button>
    </div>

    </mat-card>
  </mat-step>

  <mat-step [editable]="vEditavel">
    <ng-template matStepLabel>Conclusão</ng-template>
    <mat-card>

      <mat-card-title>
        <i class="material-icons icone">
          check
        </i>
        Conclusão
      </mat-card-title>

      <mat-card-subtitle class="subtitulo">
        Cadastro do Processo concluído! Abaixo resumo.
      </mat-card-subtitle>

      <span>
        <h1>Processo:</h1>
        <p>Número: {{covnerteNumeroProcesso(processoServico.processo.numero_processo)}}</p>
        <p>Pasta: {{processoServico.processo.numero_pasta}}</p>
        <p>Status: {{processoServico.processo.status}}</p>
        <p>Valor: {{processoServico.processo.valor}}</p>

        <h1 *ngIf='listaAutor.length > 1'>Autores:</h1>
        <h1 *ngIf='listaAutor.length <= 1'>Autor:</h1>
        <p *ngFor='let autor of listaAutor'>
          {{converteTipoDocumento(autor.parte.tipo_documento)}}
          {{converteDocumento(autor.parte.tipo_documento,autor.parte.documento)}}
          {{autor.parte.nome}}
        </p>
       
        <h1 *ngIf='listaReu.length > 1'>Réus:</h1>
        <h1 *ngIf='listaReu.length <= 1'>Réu:</h1>
        <p *ngFor='let reu of listaReu'>
          {{converteTipoDocumento(reu.parte.tipo_documento)}}
          {{converteDocumento(reu.parte.tipo_documento,reu.parte.documento)}}
          {{reu.parte.nome}}
        </p>
      </span>

      <button mat-raised-button (click)="navegarPara('pgProcessoDetalhe/' + this.processoServico.processo.id_processo)" class="btn-confirmar">
        <i class="material-icons">
          search
        </i>
        Ir para Detalhes do Processo
      </button>

      <button mat-raised-button (click)="navegarPara('pgProcesso')" class="btn-cancelar">
        <i class="material-icons">
          close
        </i>
        Sair
      </button>

    </mat-card>
  </mat-step>



</mat-horizontal-stepper> -->