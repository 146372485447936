import { TipoLista } from "./tipoLista";

export class TipoProcessoInatividade extends TipoLista {
  constructor(
    
  ) {
    super();
    this.prepara_lista()
  }

  prepara_lista(): void {
    this.adicionar_item(30,'Ultimos 30 dias')
    this.adicionar_item(90,'Ultimos 3 mêses')
    this.adicionar_item(180,'Ultimos 6 mêses')
    this.adicionar_item(270,'Ultimos 9 mêses')
    this.adicionar_item(365,'Ultimo ano')

  }

  formatar_para_exibir_em_tela(dias_inatividade:string) : string {
       return dias_inatividade
  }
}