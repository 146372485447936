import { TipoDados } from "./tipoDados";
import { itemLista } from "./tipoLista";

export class TipoHora extends TipoDados {

  formatar_para_exibir_em_tela(texto: string): string {
    return texto
  }

  codigo_tipo = 'hora'
  lista: Array<itemLista> = []
}