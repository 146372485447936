import { TipoDados } from "./tipoDados";
import { itemLista } from "./tipoLista";

export class TipoCNPJ extends TipoDados {

  formatar_para_exibir_em_tela(documento: string): string {
    var retorno = 'indefinido'

    retorno = documento!.toString().padStart(14, '0')
    retorno = retorno.replace(/(\d{2})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
    retorno = retorno.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
    retorno = retorno.replace(/(\d{3})(\d)/, "$1/$2"); //Coloca uma barra entre o terceiro e o quarto dígitos
    retorno = retorno.replace(/(\d{4})(\d)/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos
    // retorno = '00.000.000/0000-00'
    return retorno

  }

  codigo_tipo = 'mascara'
  mascara = '00.000.000/0000-00'

  lista: Array<itemLista> = []
}