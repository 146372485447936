import { TipoDados } from "./tipoDados";
import { itemLista } from "./tipoLista";

export class TipoPIS extends TipoDados {

  formatar_para_exibir_em_tela(pis: string): string {
    var retorno = ''
    
    if (pis.length == 11) {
      retorno = pis.substring(0,3) + '.' + pis.substring(3,8) + '.' + pis.substring(8,10) + '-' + pis.substring(10,11)
    }
    
    return retorno

  }

  codigo_tipo = 'mascara'
  mascara = '000.00000.00-0'

  lista: Array<itemLista> = []
}