import { TipoLista } from "./tipoLista";

export class TipoAudienciaaStatus extends TipoLista {
  constructor(
    
  ) {
    super();
    this.prepara_lista()
  }

  prepara_lista(): void {
    this.adicionar_item('R','Realizado')
    this.adicionar_item('D','Redesignado')
    this.adicionar_item('C','Cancelado')
    this.adicionar_item('A','Agendado')

  }

}