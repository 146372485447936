import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { Condicao } from "src/app/domain/acao";
import { domainContaBancaria } from "src/app/domain/contaBancaria";
import { TipoCNPJ } from "../tipos_de_dados/tipoCNPJ";
import { TipoCPF } from "../tipos_de_dados/tipoCPF";
import { TipoDocumentoTipo } from "../tipos_de_dados/tipoDocumentoTipo";
import { TipoFlag } from "../tipos_de_dados/tipoFlag";
import { TipoTexto } from "../tipos_de_dados/tipoTexto";
import { TipoTextoLongo } from "../tipos_de_dados/tipoTextoLongo";
import { classeEntidadeDefault } from "./classeEntidadeDefault";

@Injectable({
  providedIn: 'root'
})
export class classeEntidadeContaBancaria extends classeEntidadeDefault {

//construtor

  instanciar(): void {
    this.nome = 'conta_bancaria'
    this.nome_externo = 'Conta Bancária'
    this.icone = 'account_balance'
    this.carregar_datatable()
    this.configurar_layout()
    this.configurar_dados_parent()
  }

  configurar_dados_parent(): void {
    this.campo_id_parent = 'id_parte'
    this.entidade_parent = 'parte'
  }

  configurar_layout(): void {
    this.reinicializa()
    this.incluir_campo('indicador_mesma_titularidade', 'Mesma Titularidade'          , new TipoFlag         , true, true, true, false, '', [Validators.required], true, false)

    var outro_titular : Condicao = {}
    outro_titular.campo = 'indicador_mesma_titularidade'
    outro_titular.valor = false

    this.incluir_campo('tipo_documento_titular'      , 'Tipo Documento Titular'      , new TipoDocumentoTipo, true, true, true, false, '', undefined, true, false, [outro_titular])

    var exibe_apenas_pf : Condicao = {}
    exibe_apenas_pf.campo = 'tipo_documento_titular'
    exibe_apenas_pf.valor = '1'

    var exibe_apenas_pj : Condicao = {}
    exibe_apenas_pj.campo = 'tipo_documento_titular'
    exibe_apenas_pj.valor = '2'

    this.incluir_campo('documento_titular'           , 'CNPJ Titular'                , new TipoCNPJ         , true, true, true, false, '', undefined, true, false, [outro_titular,exibe_apenas_pj])
    this.incluir_campo('nome_titular'                , 'Razão Social Titular'        , new TipoTexto        , true, true, true, false, '', undefined, true, false, [outro_titular,exibe_apenas_pj])

    this.incluir_campo('documento_titular'           , 'CPF Titular'                 , new TipoCPF          , true, true, true, false, '', undefined, true, false, [outro_titular,exibe_apenas_pf])
    this.incluir_campo('nome_titular'                , 'Nome Titular'                , new TipoTexto        , true, true, true, false, '', undefined, true, false, [outro_titular,exibe_apenas_pf])

    this.incluir_campo('banco'                       , 'Banco'                       , this.lista.banco     , true, true, true, false, '', [Validators.required], true, false)
    this.incluir_campo('agencia'                     , 'Agência'                     , new TipoTexto        , true, true, true, false, '', [Validators.required], true, false)
    this.incluir_campo('conta'                       , 'Conta'                       , new TipoTexto        , true, true, true, false, '', [Validators.required], true, false)
    this.incluir_campo('obs'                         , 'Obs'                         , new TipoTextoLongo   , true, true, true, false, '', [Validators.required], true, false)
  }

  formatar_registro(registro: any): string {
    var conta: domainContaBancaria = registro

    var formatado: string
    formatado = conta.banco + ': '
      + 'Ag. ' + conta.agencia + ' - '
      + 'Conta ' + conta.conta + ' - '
      + '(' + conta.nome_titular + ')'


    return formatado

  }

  formatar_detalhe_externo(registro: any): string {
       return this.formatar_registro(registro)

  }

}