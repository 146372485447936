import { UtilidadesService } from "src/app/servicos/utilidades.service";
import { TipoNumeroProcesso } from "./tipoNumeroProcesso";
import { JudicyService } from "src/app/servicos/judicy.service";
import { domainRetornoApiJudicy } from "src/app/domain/apiJudicy";
import { domainParametro } from 'src/app/domain/parametro';

export class TipoNumeroProcessoLink extends TipoNumeroProcesso {
  codigo_tipo = 'link'

  constructor(
     private util: UtilidadesService
    ,private judicy?: JudicyService

  ) {
    super();
  }

  tentar_associar_a_processo(id_publicacao : number, numero_unico_processo: string) {
    var parametro: domainParametro = {}
    parametro.campo = 'numero_unico_processo'
    parametro.valor = numero_unico_processo

    this.judicy!.alterar('publicacao', {}, id_publicacao, 'associar_a_processo', [parametro])
      .subscribe(
        (resposta: domainRetornoApiJudicy) => { this.trata_retorno(resposta) }
        , (error: any) => {
          this.util.exibirMensagem(this.util.catalogo.msg_erro_alteracao(), this.util.tp_msg.erro());
        }
      );

    return undefined
  }

  trata_retorno(p_ret: domainRetornoApiJudicy) {
    switch (p_ret.status) {
         case 0:
            this.util.exibirMensagem('Publicação Relacionada a Processo com Sucesso!', this.util.tp_msg.sucesso());
            this.util.navegarPara('pgProcessoDetalhe/' + p_ret.conteudo['id_processo'])
            break
  
          case 810:
            this.util.exibirMensagem('Processo Não Encontrado. Abrindo tela para Cadastra-lo!', this.util.tp_msg.advertencia());
            this.criar_processo()
            break      

          case 820:
            this.util.exibirMensagem('Processo Duplicado! Falha ao tentar associar a publicação!', this.util.tp_msg.erro());
            break      
          
          default:
            this.util.exibirMensagem(p_ret.mensagem!, this.util.tp_msg.erro());
            break
    }

  }
  
  numero_unico_processo = ''

  criar_processo() {
    var numero = this.numero_unico_processo.replace(/[^0-9]/g, '');
    this.util.navegarPara('pgProcessoIncluir/' + numero)
  }

  acessar_link(registro: any): void {
    var id_processo            = registro['processo']['id_processo']
    var id_publicacao          = registro['id_publicacao']
    this.numero_unico_processo = registro['numero_unico_processo']

    if (id_processo == undefined) {
      id_processo = this.tentar_associar_a_processo(id_publicacao, this.numero_unico_processo)
    } else {
      this.util.abrirEmNovaJanela('pgProcessoDetalhe/' + id_processo)
    }
  }
}