import { JudicyService } from "src/app/servicos/judicy.service";
import { UtilidadesService } from "src/app/servicos/utilidades.service";
import { ListasDeBancoDeDados } from "../tipos_de_dados/tipoListaBancoDeDados";
import { classeEntidadeAndamento } from "./classeEntidadeAndamento";
import { classeEntidadeApenso } from "./classeEntidadeApenso";
import { classeEntidadeAudiencia } from "./classeEntidadeAudiencia";
import { classeEntidadeAutor } from "./classeEntidadeAutor";
import { classeEntidadeContaBancaria } from "./classeEntidadeContaBancaria";
import { classeEntidadeContato } from "./classeEntidadeContato";
import { classeEntidadeEndereco } from "./classeEntidadeEndereco";
import { classeEntidadeForum } from "./classeEntidadeForum";
import { classeEntidadeLista } from "./classeEntidadeLista";
import { classeEntidadeLocal } from "./classeEntidadeLocal";
import { classeEntidadeLog } from "./classeEntidadeLog";
import { classeEntidadeParte } from "./classeEntidadeParte";
import { classeEntidadePrazo } from "./classeEntidadePrazo";
import { classeEntidadeProcesso } from "./classeEntidadeProcesso";
import { classeEntidadePublicacao } from "./classeEntidadePublicacao";
import { classeEntidadeReu } from "./classeEntidadeReu";
import { classeEntidadeSaneamento } from "./classeEntidadeSaneamento";
import { classeEntidadeTarefa } from "./classeEntidadeTarefa";
import { classeEntidadeUsuario } from "./classeEntidadeUsuario";
import { classeEntidadeControleAlerta } from "./classeEntidadeControleAlerta";

export class TipoEntidade {
    constructor(
         private judicy: JudicyService
        ,private util: UtilidadesService
        ,private lista_de_listas: ListasDeBancoDeDados
    
      ) { }
      
    // lista_de_listas = new ListasDeBancoDeDados(this.judicy, this.util)

    tipo_entidade: any = {
        andamento       : {nome: 'andamento'     , instancia: new classeEntidadeAndamento(this.lista_de_listas,this.util,this.judicy)}
        ,apenso         : {nome: 'apenso'        , instancia: new classeEntidadeApenso(this.lista_de_listas,this.util,this.judicy)}
        ,audiencia      : {nome: 'audiencia'     , instancia: new classeEntidadeAudiencia(this.lista_de_listas,this.util,this.judicy)}
        ,autor          : {nome: 'autor'         , instancia: new classeEntidadeAutor(this.lista_de_listas,this.util,this.judicy)}
        ,conta_bancaria : {nome: 'conta_bancaria', instancia: new classeEntidadeContaBancaria(this.lista_de_listas,this.util,this.judicy)}
        ,contato        : {nome: 'contato'       , instancia: new classeEntidadeContato(this.lista_de_listas,this.util,this.judicy)}
        ,endereco       : {nome: 'endereco'      , instancia: new classeEntidadeEndereco(this.lista_de_listas,this.util,this.judicy)}
        ,forum          : {nome: 'forum'         , instancia: new classeEntidadeForum(this.lista_de_listas,this.util,this.judicy)}
        ,lista          : {nome: 'lista'         , instancia: new classeEntidadeLista(this.lista_de_listas,this.util,this.judicy)}
        ,local          : {nome: 'local'         , instancia: new classeEntidadeLocal(this.lista_de_listas,this.util,this.judicy)}
        ,log            : {nome: 'log'           , instancia: new classeEntidadeLog(this.lista_de_listas,this.util,this.judicy)}
        ,parte          : {nome: 'parte'         , instancia: new classeEntidadeParte(this.lista_de_listas,this.util,this.judicy)}
        ,prazo          : {nome: 'prazo'         , instancia: new classeEntidadePrazo(this.lista_de_listas,this.util,this.judicy)}  
        ,processo       : {nome: 'processo'      , instancia: new classeEntidadeProcesso(this.lista_de_listas,this.util,this.judicy)}
        ,publicacao     : {nome: 'publicacao'    , instancia: new classeEntidadePublicacao(this.lista_de_listas,this.util,this.judicy)}
        ,reu            : {nome: 'reu'           , instancia: new classeEntidadeReu(this.lista_de_listas,this.util,this.judicy)}
        ,saneamento     : {nome: 'saneamento'    , instancia: new classeEntidadeSaneamento(this.lista_de_listas,this.util,this.judicy)}
        ,tarefa         : {nome: 'tarefa'        , instancia:  new classeEntidadeTarefa(this.lista_de_listas,this.util,this.judicy)}     
        ,usuario        : {nome: 'usuario'       , instancia: new classeEntidadeUsuario(this.lista_de_listas,this.util,this.judicy)}
        ,controle_alerta: {nome: 'controle_alerta', instancia: new classeEntidadeControleAlerta(this.lista_de_listas,this.util,this.judicy)}
    }
  
    getEntidade(nome_entidade:string) {
      return this.tipo_entidade[nome_entidade];
    }
  }
