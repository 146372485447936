<div class="div-conteudo">
    <div style='padding-bottom: 3px;'>
        <mat-toolbar>
            <!-- <button mat-raised-button (click)='incluir()' class='btn-incluir' matTooltip='Incluir Endereço'
                matTooltipPosition='right'>
                +
            </button> -->

            <button *ngFor="let acao of acoes_nivel_tabela" mat-raised-button (click)='executar_operacao({},acao)'
                class='{{acao.estilo}}' matTooltip='{{acao.descricao}} {{entidade.nome}}' matTooltipPosition='right'>
                <i class='material-icons'>
                    {{acao.icone}}
                </i>
            </button>

            <i class='material-icons icone'>
                {{entidade.icone}}
            </i>
            {{entidade.nome_externo}}
        </mat-toolbar>

        <div class='div-toolbar'>

            <p *ngIf='registros.length === 0'>Sem cadastro de {{entidade.nome_externo}}. Para adicionar clque em + </p>
            <div *ngFor="let registro of registros">
                {{formatar_registro(registro)}}
                <!-- 
            <div *ngFor='let endereco of listaEndereco' style="padding-bottom: 10px;">

                <b>{{converteTipoEndereco(endereco.tipo_endereco)}}:</b>
                {{endereco.logradouro}},
                {{endereco.numero}}
                {{endereco.complemento}} -
                {{endereco.bairro}} - CEP: {{endereco.cep}} -
                {{endereco.cidade}} - {{endereco.uf}}

                <a (click)='excluir(endereco)' matTooltip='Excluír' matTooltipPosition='before' class="link">
                    <i class='material-icons '>
                        delete
                    </i>
                </a>

                <a (click)='alterar(endereco)' matTooltip='Alterar' matTooltipPosition='right' class="link">
                    <i class='material-icons '>
                        edit
                    </i>
                </a>


            </div> 
        
        [disabled]='verificaElegibilidadeAcao(registro,acao)'
        -->

                <ng-container *ngFor="let acao of acoes_nivel_registro" class="colunaAcao">
                    <a *ngIf="!verificaOcultarAcao(registro,acao)" 
                        matTooltipPosition='right' class="link" (click)='executar_operacao(registro,acao)'
                        matTooltip='{{acao.descricao}} {{entidade.nome}}'>
                        <i class='material-icons'>
                            {{acao.icone}}
                        </i>
                    </a>
                </ng-container>
            </div>

        </div>

    </div>

</div>