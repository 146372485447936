<h2 mat-dialog-title>
  Seletor de Foto
</h2>

<mat-dialog-content class='conteudo'>

  <div>
    <input type="file" (change)="fileChangeEvent($event)" />
  </div>

  <div class="img-cropper">
    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false" [aspectRatio]="4 / 3"
      format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"></image-cropper>
  </div>

  <div class="cropped-image">
    <img [src]="croppedImage" />
  </div>


</mat-dialog-content>


<mat-dialog-actions align='end'>
  <button mat-button (click)='cancelar()'>
    Cancelar
  </button>

  <button mat-button (click)='confirmar()'>
    Confirmar
  </button>

</mat-dialog-actions>