import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CabecalhoServicoService } from 'src/app/template/cabecalho/cabecalho-servico.service';
import { NavegacaoServicoService } from 'src/app/template/navegacao/navegacao-servico.service';

@Component({
  selector: 'app-tarefa-pagina',
  templateUrl: './tarefa-pagina.component.html',
  styleUrls: ['./tarefa-pagina.component.css']
})
export class TarefaPaginaComponent implements OnInit {

  constructor(
    public dialog: MatDialog
    , private vCabecalhoServico: CabecalhoServicoService
    , private vNavegacaoServico: NavegacaoServicoService) {
    vCabecalhoServico.cabecalho = {
      titulo: 'Home > Tarefas',
      icone: 'home',
      rotaURL: 'pgHome'
    }

    vNavegacaoServico.tipoMenu = 'home';

  }

  ngOnInit(): void {
    
  }


}