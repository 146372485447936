import { TipoLista } from "./tipoLista";

export class TipoParteEstadoCivil extends TipoLista {
  constructor(
    
  ) {
    super();
    this.prepara_lista()
  }

  prepara_lista(): void {
    this.adicionar_item('CA','Casado')
    this.adicionar_item('SO','Solteiro')
    this.adicionar_item('VI','Viúvo')
    this.adicionar_item('DV','Divorciado')
    this.adicionar_item('UE','União Estável')
  }

}