import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { domainRetornoApiJudicy } from 'src/app/domain/apiJudicy';
import { domainLog } from 'src/app/domain/log';
import { domainParte } from 'src/app/domain/parte';
import { domainProcesso } from 'src/app/domain/processo';
import { domainUsuario } from 'src/app/domain/usuario';
import { SessaoService } from 'src/app/servicos/sessao.service';
import { UtilidadesService } from 'src/app/servicos/utilidades.service';
import { autenticacaoApiJudicy } from 'src/app/domain/apiJudicy';
import { LogControlService } from 'src/app/servicos/logControl.service';
import { enderecoIPService } from 'src/app/servicos/enderecoIP.service';
import { CabecalhoServicoService } from 'src/app/template/cabecalho/cabecalho-servico.service';
import { NavegacaoServicoService } from 'src/app/template/navegacao/navegacao-servico.service';
import { JudicyService } from 'src/app/servicos/judicy.service';
import { Operacao } from 'src/app/domain/acao';
import { ExecutorOperacaoDialog } from 'src/app/classe/OperacaoDialog';
import { TipoEntidade } from 'src/app/classe/entidade/tipoEntidade';
import { TipoDocumentoTipo } from 'src/app/classe/tipos_de_dados/tipoDocumentoTipo';
import { TipoCNPJ } from 'src/app/classe/tipos_de_dados/tipoCNPJ';
import { TipoCPF } from 'src/app/classe/tipos_de_dados/tipoCPF';
import { TipoData } from 'src/app/classe/tipos_de_dados/tipoData';
import { TipoParteEstadoCivil } from 'src/app/classe/tipos_de_dados/tipoParteEstadoCivil';
import { domainContato } from 'src/app/domain/contato';


export interface PeriodicElement {
  name: string;
  position: number;
  weight: string;
  symbol: string;
  data_inicio: string;
  data_fim: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 19999999999, name: 'EMPREITEIRA SA', weight: 'Obra tiquatira', symbol: 'Rua: xxxx', data_inicio: '24/02/2024', data_fim: '01/09/2025'},
  {position: 18888888888, name: 'SOLIMOES SA', weight: 'Obra Osasco', symbol: 'Rua: xxxx', data_inicio: '01/01/2024', data_fim: '08/12/2025'},
  {position: 18888222211, name: 'LITHIUM SA', weight: 'Obra Penha', symbol: 'Rua: xxxx', data_inicio: '09/03/2024', data_fim: '10/02/2025'},
];


@Component({
  selector: 'app-locais-de-trabalho',
  templateUrl: './locais-de-trabalho.component.html',
  styleUrls: ['./locais-de-trabalho.component.css']
})
export class LocaisDeTrabalhoComponent implements OnInit {

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol', 'data_inicio', 'data_fim'];
  dataSource = ELEMENT_DATA;

  constructor() {}

  log: domainLog = {}

  parte: domainParte = {}
  filtro: domainProcesso = { forum: {} }
  processo: domainProcesso = {forum:{}}


  ngOnInit(): void {
    //console.log('parte = ' + JSON.stringify(this.parte))
    

  }

  }
