import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { domainEndereco } from "src/app/domain/endereco";
import { TipoCEP } from "../tipos_de_dados/tipoCEP";
import { TipoEnderecoTipo } from "../tipos_de_dados/tipoEnderecoTipo";
import { TipoTelefone } from "../tipos_de_dados/tipoTelefone";
import { TipoTexto } from "../tipos_de_dados/tipoTexto";
import { classeEntidadeDefault } from "./classeEntidadeDefault";

@Injectable({
  providedIn: 'root'
})
export class classeEntidadeEndereco extends classeEntidadeDefault {

//construtor

  instanciar(): void {
    this.nome = 'endereco'
    this.nome_externo = 'Endereço'
    this.icone = 'home'
    this.carregar_datatable()
    this.configurar_layout()
    this.configurar_dados_parent()
  }

  configurar_dados_parent(): void {
    this.campo_id_parent = 'id_parte'
    this.entidade_parent = 'parte'
  }

  configurar_layout(): void {
    this.reinicializa()
    this.incluir_campo('id_endereco'  , 'id_endereco'  , new TipoTexto       , true, false, true, false, '', [Validators.required], false, false)
    this.incluir_campo('tipo_endereco', 'Tipo Endereço', new TipoEnderecoTipo, true, true , true, false, '', [Validators.required], true, false)
    this.incluir_campo('cep'          , 'CEP'          , new TipoCEP         , true, true , true, false, '', [Validators.required], true, false)
    this.incluir_campo('logradouro'   , 'Logradouro'   , new TipoTexto       , true, true , true, false, '', [Validators.required], true, false)
    this.incluir_campo('numero'       , 'Número'       , new TipoTexto       , true, true , true, false, '', [Validators.required], true, false)
    this.incluir_campo('complemento'  , 'Complemento'  , new TipoTexto       , true, true , true, false, '', undefined, true, false)
    this.incluir_campo('bairro'       , 'Bairro'       , new TipoTexto       , true, true , true, false, '', [Validators.required], true, false)
    this.incluir_campo('cidade'       , 'Cidade'       , new TipoTexto       , true, true , true, false, '', [Validators.required], true, false)
    this.incluir_campo('uf'           , 'UF'           , new TipoTexto       , true, true , true, false, '', [Validators.required], true, false)
  }

  formatar_registro(registro: any): string {
    var endereco: domainEndereco = registro
    var tipo_endereco = new TipoEnderecoTipo
    var tipo_cep = new TipoCEP

    var formatado: string
    formatado = tipo_endereco.formatar_para_exibir_em_tela(endereco.tipo_endereco!) + ': ' 
              + endereco.logradouro! + ', ' 
              + endereco.numero + ' ' 
              + endereco.complemento + ' - '
              + endereco.bairro! + ' - ' 
              + 'CEP: ' + tipo_cep.formatar_para_exibir_em_tela(endereco.cep!) + ' - ' 
              + endereco.cidade! + ' - ' 
              + endereco.uf! + ' - ' 

    return formatado

  }

  formatar_detalhe_externo(registro: any): string {
    var endereco: domainEndereco = registro
    var tipo_endereco = new TipoEnderecoTipo
    var formatado: string

    formatado = tipo_endereco.formatar_para_exibir_em_tela(endereco.tipo_endereco!)

    return formatado

  }

}