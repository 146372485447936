<mat-card class="home mat-elevation-z3">
    <mat-card-title class="titulo">
        Bem Vindo!
    </mat-card-title>

    <mat-card-subtitle class="subtitulo">
        JUDICY - Sistema de gestão de processos
    </mat-card-subtitle>


</mat-card>