import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { CabecalhoServicoService } from 'src/app/template/cabecalho/cabecalho-servico.service';
import { NavegacaoServicoService } from 'src/app/template/navegacao/navegacao-servico.service';
import { domainDialog } from 'src/app/domain/dialog';
import { UtilidadesService } from 'src/app/servicos/utilidades.service';
import { classeCatalogo, classeTipoMensagem } from 'src/app/classe/mensagens';
import { domainRetornoApiJudicy } from 'src/app/domain/apiJudicy';
import { domainUsuario } from 'src/app/domain/usuario';
import { SessaoService } from 'src/app/servicos/sessao.service';
import { FormularioComponent } from 'src/app/classe/formulario/formulario.component';
import { classeEntidade } from 'src/app/classe/entidade/classeEntidade';
import { JudicyService } from 'src/app/servicos/judicy.service';
import { TipoEntidade } from 'src/app/classe/entidade/tipoEntidade';
import { domainForum } from 'src/app/domain/forum';
import { Operacao } from 'src/app/domain/acao';
import { ListasDeBancoDeDados } from 'src/app/classe/tipos_de_dados/tipoListaBancoDeDados';

@Component({
  selector: 'app-processo-incluir-pagina',
  templateUrl: './processo-incluir-pagina.component.html',
  styleUrls: ['./processo-incluir-pagina.component.css']
})
export class ProcessoIncluirPaginaComponent implements OnInit {

  @ViewChild('stepper') private passosProcessoIncluir!: MatStepper;

  vLinear = true;
  vEditavel = true;
  parametrosDialog: domainDialog = {};
  catalogo: classeCatalogo = new (classeCatalogo);
  tp_msg: classeTipoMensagem = new (classeTipoMensagem);

  constructor(private vCabecalhoServico: CabecalhoServicoService
    , private vNavegacaoServico: NavegacaoServicoService
    , private rotaURL: Router
    , public judicy: JudicyService
    , private util: UtilidadesService
    , private sessaoServico: SessaoService
    , private rota: ActivatedRoute,
  ) {
    vCabecalhoServico.cabecalho = {
      titulo: 'Processo > Incluir',
      icone: 'feed',
      rotaURL: 'pgProcessoIncluir'
    }

    vNavegacaoServico.tipoMenu = 'processo';

  }

  numero_processo = this.rota.snapshot.paramMap.get("numero_processo");

  ngOnInit(): void {
    var lista_de_listas = new ListasDeBancoDeDados(this.judicy, this.util)
    var tipo_entidade = new TipoEntidade(this.judicy, this.util, lista_de_listas)
    this.entidade = tipo_entidade.getEntidade('processo')['instancia']
    this.judicy.entidade = this.entidade
    this.judicy.registro = { 'forum': { 'id_forum': '' } }

    if (this.numero_processo == undefined) {
      this.judicy.registro = { 'forum': { 'id_forum': '' } }
    } else {
      this.judicy.registro = { 'numero_processo': this.numero_processo, 'forum': { 'id_forum': '' } }

    }
    this.judicy.acao.operacao = Operacao.INCLUIR

  }

  navegarPara(pagina: string): void {
    this.rotaURL.navigate([pagina]);
  }

  get usuarioLogado(): domainUsuario {
    return this.sessaoServico.getUsuarioLogado()
  }

  get registro(): any {
    return this.judicy.registro
  }

  entidade!: classeEntidade

  @ViewChild(FormularioComponent, { static: false })
  formulario!: FormularioComponent;

  incluirProcesso() {


    if (this.formulario.preechimento_formulario_ok()) {

      this.judicy.incluir(this.entidade.nome, this.registro)
        .subscribe(resposta => { this.trata_retorno(resposta); }
          , (error: any) => {
            this.util.exibirMensagem(this.util.catalogo.msg_erro_inclusao(), this.util.tp_msg.erro());
            //console.log('Erro', error);                                                              
          }
        )
    }
  }

  trata_retorno(p_ret: domainRetornoApiJudicy) {
    if (p_ret.status == 0) {
      this.util.exibirMensagem('Operação realizada com sucesso!', this.util.tp_msg.sucesso());
      this.judicy.registro = p_ret.conteudo
      var pagina = 'pgProcessoDetalhe/' + this.registro['id_processo']

      this.navegarPara(pagina)
    }
    else {
      this.util.exibirMensagem(p_ret.mensagem!, this.util.tp_msg.erro());
      //console.log('Erro', retornoJudicy.mensagem);                                                    
    }
  }

  passoAnterior() {
    this.passosProcessoIncluir.previous();
  }

  passoSeguinte() {
    this.passosProcessoIncluir.next();
  }


}
