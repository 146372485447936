import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { CabecalhoServicoService } from 'src/app/template/cabecalho/cabecalho-servico.service';
import { NavegacaoServicoService } from 'src/app/template/navegacao/navegacao-servico.service';
import { domainDialog } from 'src/app/domain/dialog';
import { classeCatalogo, classeTipoMensagem } from 'src/app/classe/mensagens';
import { UtilidadesService } from 'src/app/servicos/utilidades.service';
import { domainRetornoApiJudicy } from 'src/app/domain/apiJudicy';
import { SessaoService } from 'src/app/servicos/sessao.service';
import { domainUsuario } from 'src/app/domain/usuario';
import { TipoEntidade } from 'src/app/classe/entidade/tipoEntidade';
import { JudicyService } from 'src/app/servicos/judicy.service';
import { FormularioComponent } from 'src/app/classe/formulario/formulario.component';
import { classeEntidade } from 'src/app/classe/entidade/classeEntidade';
import { domainParte } from 'src/app/domain/parte';
import { Operacao } from 'src/app/domain/acao';
import { ListasDeBancoDeDados } from 'src/app/classe/tipos_de_dados/tipoListaBancoDeDados';

@Component({
  selector: 'app-parte-incluir-pagina',
  templateUrl: './parte-incluir-pagina.component.html',
  styleUrls: ['./parte-incluir-pagina.component.css']
})
export class ParteIncluirPaginaComponent implements OnInit {

  @ViewChild('stepper') private passosParteIncluir!: MatStepper;

  vLinear = true;
  vEditavel = true;
  parametrosDialog: domainDialog = {};
  catalogo: classeCatalogo = new (classeCatalogo);
  tp_msg: classeTipoMensagem = new (classeTipoMensagem);

  constructor(private vCabecalhoServico: CabecalhoServicoService
    , private vNavegacaoServico: NavegacaoServicoService
    , private rotaURL: Router
    , private judicy: JudicyService
    , private util: UtilidadesService
    , private sessaoServico: SessaoService
  ) {
    vCabecalhoServico.cabecalho = {
      titulo: 'Parte > Incluir',
      icone: 'person_add_alt',
      rotaURL: 'pgParteIncluir'
    }

    vNavegacaoServico.tipoMenu = 'parte';

  }
   
  ngOnInit(): void {
    var lista_de_listas = new ListasDeBancoDeDados(this.judicy, this.util)
    var tipo_entidade = new TipoEntidade(this.judicy, this.util, lista_de_listas)
    this.entidade = tipo_entidade.getEntidade('parte')['instancia']
    this.judicy.entidade = this.entidade
    this.judicy.registro = {}
    this.judicy.registro['tipo_documento'] = 1
    this.judicy.acao.operacao = Operacao.INCLUIR
  }

  navegarPara(pagina: string): void {
    this.rotaURL.navigate([pagina]);
  }

  get usuarioLogado(): domainUsuario {
    return this.sessaoServico.getUsuarioLogado()
  }

  get registro(): domainParte {
    return this.judicy.registro
  }

  entidade!: classeEntidade

  @ViewChild(FormularioComponent, { static: false })
  formulario!: FormularioComponent;

  incluirParte() {

    if (this.formulario.preechimento_formulario_ok()) {
      this.judicy.incluir(this.entidade.nome, this.registro)
        .subscribe(resposta => { this.trata_retorno(resposta); }
          , (error: any) => {
            this.util.exibirMensagem(this.util.catalogo.msg_erro_inclusao(), this.util.tp_msg.erro());
            //console.log('Erro', error);                                                              
          }
        )
    }
  }

  trata_retorno(p_ret: domainRetornoApiJudicy) {
    if (p_ret.status == 0) {
      this.util.exibirMensagem('Operação realizada com sucesso!', this.util.tp_msg.sucesso());
      this.judicy.registro = p_ret.conteudo
      var pagina = 'pgParteDetalhe/' + this.registro.id_parte!
      console.log('pagina = ' + pagina)
      this.navegarPara(pagina)
    }
    else {
      this.util.exibirMensagem(p_ret.mensagem!, this.util.tp_msg.erro());
      //console.log('Erro', retornoJudicy.mensagem);                                                    
    }
  }
  passoAnterior() {
    this.passosParteIncluir.previous();
  }

  passoSeguinte() {
    this.passosParteIncluir.next();
  }

}