<mat-card class="mat-elevation-z3">
    <mat-card-title class="titulo">
        Gerencial
    </mat-card-title>

    <mat-card-subtitle class="subtitulo">
        Gerenciamento Judicy
    </mat-card-subtitle>
    <mat-card-content>
        <p>
            Olá!
        </p>

        <p>
            Nesta sessão você pode gerenciar questões administrativas do Judicy.
        </p>

        <p>
            Você poderá os usuários que acessam o sistema e visualizar todas as operações realizadas por estes usuários.
        </p>

        <p>
            Faça bom proveito desta ferramenta poderosa!
        </p>
    </mat-card-content>

</mat-card>

<br>

<mat-card class=" mat-elevation-z3">
    <mat-card-title class="titulo">
        Estatísticas
    </mat-card-title>

    <mat-card-subtitle class="subtitulo">
        Números Gerais
    </mat-card-subtitle>

    <mat-card-content>
        <p>
            Total de Usuários Existentes: {{total.usuario | number}}
        </p>

        <p>
            Total de Fóruns cadastrados: {{total.forum | number}}
        </p> 
     

        <!-- <h1>
            Operações
        </h1> -->
        <p>
            Total de Operações realizadas: {{total.log | number}}
        </p>

        <!-- <p>
            Total de Operações realizadas hoje: 9999
        </p>

        <p>
            Total de Operações realizadas nos últimos 30 dias: 9999
        </p> -->

    </mat-card-content>


</mat-card>