<mat-card>
    <mat-card-title>
        <i class="material-icons">
            email
        </i>
        Controle Alertas
    </mat-card-title>

    <mat-card-subtitle>
        Aqui você visualiza cada alerta emitido pelo Judicy.
    </mat-card-subtitle>
    
</mat-card>

<div class="divDataTable">
    <app-datatable ENTIDADE="controle_alerta" ID_PARENT=""></app-datatable>
</div>