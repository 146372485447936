import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgendaPaginaComponent } from './pagina/agenda-pagina/agenda-pagina.component';
import { AudienciaPaginaComponent } from './pagina/audiencia-pagina/audiencia-pagina.component';
import { ForumPaginaComponent } from './pagina/forum-pagina/forum-pagina.component';
import { GerencialPaginaComponent } from './pagina/gerencial-pagina/gerencial-pagina.component';
import { HistoricoOperacoesPaginaComponent } from './pagina/historico-operacoes-pagina/historico-operacoes-pagina.component';
import { HomePaginaComponent } from './pagina/home-pagina/home-pagina.component';
import { LoginPaginaComponent } from './pagina/login-pagina/login-pagina.component';
import { ParametroPaginaComponent } from './pagina/parametro-pagina/parametro-pagina.component';
import { ParteConsultaPaginaComponent } from './pagina/parte-consulta-pagina/parte-consulta-pagina.component';
import { ParteDetalhePaginaComponent } from './pagina/parte-detalhe-pagina/parte-detalhe-pagina.component';
import { ParteIncluirPaginaComponent } from './pagina/parte-incluir-pagina/parte-incluir-pagina.component';
import { PartePaginaComponent } from './pagina/parte-pagina/parte-pagina.component';
import { PrazoPaginaComponent } from './pagina/prazo-pagina/prazo-pagina.component';
import { ProcessoConsultaPaginaComponent } from './pagina/processo-consulta-pagina/processo-consulta-pagina.component';
import { ProcessoDetalhePaginaComponent } from './pagina/processo-detalhe-pagina/processo-detalhe-pagina.component';
import { ProcessoIncluirPaginaComponent } from './pagina/processo-incluir-pagina/processo-incluir-pagina.component';
import { ProcessoPaginaComponent } from './pagina/processo-pagina/processo-pagina.component';
import { PublicacaoPaginaComponent } from './pagina/publicacao-pagina/publicacao-pagina.component';
import { TarefaPaginaComponent } from './pagina/tarefa-pagina/tarefa-pagina.component';
import { UsuarioPaginaComponent } from './pagina/usuario-pagina/usuario-pagina.component';
import { ControleAlertaPaginaComponent } from './pagina/controle-alerta-pagina/controle-alerta-pagina.component';
import { LocaisDeTrabalhoComponent } from './pagina/locais-de-trabalho-pagina/locais-de-trabalho.component';
import { AdmissaoPaginaComponent } from './pagina/admissao-pagina/admissao-pagina.component';

const routes: Routes = [
  {path: "pgHome"                                 , component: HomePaginaComponent }
 ,{path: "pgAgenda"                               , component: AgendaPaginaComponent }
 ,{path: "pgUsuario"                              , component: UsuarioPaginaComponent }
 ,{path: "pgParametro"                            , component: ParametroPaginaComponent }
 ,{path: "pgParte"                                , component: PartePaginaComponent }
 ,{path: "pgParteIncluir"                         , component: ParteIncluirPaginaComponent }
 ,{path: "pgParteDetalhe/:id_parte"               , component: ParteDetalhePaginaComponent }
 ,{path: "pgProcesso"                             , component: ProcessoPaginaComponent }
 ,{path: "pgGerencial"                            , component: GerencialPaginaComponent }
 ,{path: "pgLogin"                                , component: LoginPaginaComponent }
 ,{path: "pgHistorico"                            , component: HistoricoOperacoesPaginaComponent }
 ,{path: "pgForum"                                , component: ForumPaginaComponent }
 ,{path: "pgAudiencia"                            , component: AudienciaPaginaComponent }
 ,{path: "pgPrazo"                                , component: PrazoPaginaComponent }
 ,{path: "pgTarefa"                               , component: TarefaPaginaComponent }
 ,{path: "pgParteConsulta"                        , component: ParteConsultaPaginaComponent }
 ,{path: "pgProcessoConsulta"                     , component: ProcessoConsultaPaginaComponent }
 ,{path: "pgProcessoIncluir"                      , component: ProcessoIncluirPaginaComponent }
 ,{path: "pgProcessoIncluir/:numero_processo"     , component: ProcessoIncluirPaginaComponent }
 ,{path: "pgProcessoDetalhe/:id_processo"         , component: ProcessoDetalhePaginaComponent }
 ,{path: "pgPublicacao"                           , component: PublicacaoPaginaComponent }
 ,{path: "pgControleAlerta"                       , component: ControleAlertaPaginaComponent }
 ,{path: "pgLocaisDeTrabalho"                     , component: LocaisDeTrabalhoComponent }
 ,{path: "pgAdmissao"                             , component: AdmissaoPaginaComponent  }
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
