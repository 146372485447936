import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { domainAcao } from "src/app/domain/acao";
import { domainReu } from "src/app/domain/reu";
import { TipoDocumento } from "../tipos_de_dados/tipoDocumento";
import { TipoTexto } from "../tipos_de_dados/tipoTexto";
import { classeEntidadeDefault } from "./classeEntidadeDefault";
import { classeEntidadeParte } from "./classeEntidadeParte";

@Injectable({
  providedIn: 'root'
})
export class classeEntidadeReu extends classeEntidadeDefault {

//construtor

  instanciar(): void {
    this.nome = 'reu'
    this.nome_externo = 'Réu'
    this.icone = 'person_add_alt'
    this.configurar_datatable_seletor()
    this.carregar_datatable()
    this.configurar_layout()
    this.configurar_dados_parent()
    this.reconfigurar_botoes()
  }
  
  campo_ordenacao        : string = 'sequencial'
  direcao_ordenacao      : string = 'asc'

  configurar_datatable_seletor(): void {
    this.entidade_seletor = new classeEntidadeParte(this.lista, this.util, this.judicy)
    this.entidade_seletor.layout[10].exibe_consulta = false
    this.entidade_seletor.acoes_nivel_registro = []
    //this.entidade_seletor.acoes_nivel_tabela.push(this.tipo_operacao.INCLUIR)

  }

  configurar_dados_parent(): void {
       this.campo_id_parent = 'id_processo'
       this.entidade_parent = 'processo'
  }

  configurar_layout(): void {
    this.reinicializa()
    this.incluir_campo('sequencial ', 'Sequencial', new TipoTexto, true, true, true, false, '', [Validators.required])
    this.incluir_campo('nome', 'Autor', new TipoTexto, true, false, true, true, 'parte')
    this.incluir_campo('tipo_documento', 'Tipo Documento', new TipoTexto, true, false, true, true, 'parte')
    this.incluir_campo('documento', 'Documento', new TipoTexto, true, false, true, true, 'parte')
  }

  reconfigurar_botoes() {
    var botao_alterar_parte : domainAcao = this.tipo_operacao.ALTERAR_NOVA_PAGINA
    botao_alterar_parte.pagina_destino = 'pgParteDetalhe'
    botao_alterar_parte.nome_campo_chave = 'id_parte'
    botao_alterar_parte.pai = 'parte'
    
    this.acoes_nivel_registro = []
    this.acoes_nivel_registro.push(botao_alterar_parte)
    this.acoes_nivel_registro.push(this.tipo_operacao.EXCLUIR)
    this.acoes_nivel_registro.push(this.tipo_operacao.ALTERAR_SEQUENCIA_DOWN)
    this.acoes_nivel_registro.push(this.tipo_operacao.ALTERAR_SEQUENCIA_UP)

    this.acoes_nivel_tabela = []
    var botao_incluir_parte : domainAcao = this.tipo_operacao.INCLUIR_VIA_SELETOR
    botao_incluir_parte.entidade_seletor = 'parte'
    botao_incluir_parte.identificador_seletor = 'nome'
    this.acoes_nivel_tabela.push(this.tipo_operacao.INCLUIR_VIA_SELETOR)    

  }

  formatar_registro(registro: any): string {
    var reu: domainReu = registro

    var tipo_documento = new TipoDocumento
    
    var formatado: string
    formatado = reu.sequencial! + ': '
              + reu.parte.nome! + ' - '
              + reu.parte.tipo_documento + ' - '
              + tipo_documento.formatar_para_exibir_em_tela(reu.parte.documento!,reu.parte.tipo_documento!)

    return formatado

  }

}