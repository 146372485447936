export class classeCatalogo {

 public msg_indisponivel      (){ return 'Não foi possível executar a opreação. Serviço indisponível no momento.'}
 public msg_sucesso_inclusao  (){ return 'Inclusão realizada com sucesso!'                    }
 public msg_sucesso_exclusao  (){ return 'Exclusão realizada com sucesso!'                    }
 public msg_sucesso_alteracao (){ return 'Alteração realizada com sucesso!'                   }
 public msg_sucesso_download  (){ return 'Download realizado com sucesso!'                    }
 public msg_sucesso_login     (){ return 'Login realizado com sucesso!'                       }
 public msg_erro_inclusao     (){ return 'Erro na inclusão!'                                  }
 public msg_erro_exclusao     (){ return 'Erro na exclusão!'                                  }
 public msg_erro_alteracao    (){ return 'Erro na alteração!'                                 }
 public msg_erro_listagem     (){ return 'Erro na listagem!'                                  }
 public msg_erro_listagem_pai (){ return 'Erro na listagem por tabela primária!'              }
 public msg_erro_download     (){ return 'Erro no Download!'                                  }
 public msg_erro_login        (){ return 'Erro no login! Usuário, senha ou domínio inválidos' }
 public msg_erro_recarga      (){ return 'Erro na recarga de publicação!' }
}

export class classeTipoMensagem {
  public sucesso      () { return 'SUCESSO'     }
  public erro         () { return 'ERRO'        }
  public advertencia  () { return 'ADVERTENCIA' }
  public informacao   () { return 'INFORMACAO'  }
}

