import { NgModule, LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar'; 
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { FormsModule } from '@angular/forms'
import { ReactiveFormsModule } from '@angular/forms'
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule} from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule} from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { TextMaskModule } from 'angular2-text-mask';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatMenuModule} from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import {MatGridListModule} from '@angular/material/grid-list';

import { CabecalhoComponent } from './template/cabecalho/cabecalho.component';
import { RodapeComponent } from './template/rodape/rodape.component';
import { NavegacaoComponent } from './template/navegacao/navegacao.component';
import { UsuarioPaginaComponent } from './pagina/usuario-pagina/usuario-pagina.component';
import { HomePaginaComponent } from './pagina/home-pagina/home-pagina.component';
import { PartePaginaComponent } from './pagina/parte-pagina/parte-pagina.component';
import localePt from '@angular/common/locales/pt'
import { registerLocaleData } from '@angular/common';
import { ProcessoPaginaComponent } from './pagina/processo-pagina/processo-pagina.component';
import { GerencialPaginaComponent } from './pagina/gerencial-pagina/gerencial-pagina.component';
import { ParteIncluirPaginaComponent } from './pagina/parte-incluir-pagina/parte-incluir-pagina.component';
import { DialogComponent } from './classe/dialog/dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { LoginPaginaComponent } from './pagina/login-pagina/login-pagina.component';
import { getPortuguesePaginatorIntl } from './customizacao/mat-table-paginacao';
import { HistoricoOperacoesPaginaComponent } from './pagina/historico-operacoes-pagina/historico-operacoes-pagina.component';
import { ControleAlertaPaginaComponent } from './pagina/controle-alerta-pagina/controle-alerta-pagina.component';
import { ForumPaginaComponent } from './pagina/forum-pagina/forum-pagina.component';
import { AudienciaPaginaComponent } from './pagina/audiencia-pagina/audiencia-pagina.component';
import { DatePipe } from '@angular/common'; 
import { PrazoPaginaComponent } from './pagina/prazo-pagina/prazo-pagina.component';
import { TarefaPaginaComponent } from './pagina/tarefa-pagina/tarefa-pagina.component';
import { ProcessoIncluirPaginaComponent } from './pagina/processo-incluir-pagina/processo-incluir-pagina.component';
import { ProcessoDetalhePaginaComponent } from './pagina/processo-detalhe-pagina/processo-detalhe-pagina.component';
import { PublicacaoPaginaComponent } from './pagina/publicacao-pagina/publicacao-pagina.component';
import { ParteDetalhePaginaComponent } from './pagina/parte-detalhe-pagina/parte-detalhe-pagina.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
//import { SchedulerModule } from 'angular-calendar-scheduler';
import { ProcessoConsultaPaginaComponent } from './pagina/processo-consulta-pagina/processo-consulta-pagina.component';
import { ParteConsultaPaginaComponent } from './pagina/parte-consulta-pagina/parte-consulta-pagina.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import  {MatCurrencyFormatModule} from 'mat-currency-format';
import { ParametroPaginaComponent } from './pagina/parametro-pagina/parametro-pagina.component';
import { AgendaComponent } from './agenda/agenda.component';
import { FullCalendarModule } from '@fullcalendar/angular'; // npm install @fullcalendar/angular
import dayGridPlugin from '@fullcalendar/daygrid';          // npm install @fullcalendar/daygrid
import timeGridPlugin from '@fullcalendar/timegrid';        // npm install @fullcalendar/timegrid
import listPlugin from '@fullcalendar/list';                // npm install @fullcalendar/list
import interactionPlugin from '@fullcalendar/interaction';
import { AgendaPaginaComponent } from './pagina/agenda-pagina/agenda-pagina.component';
import { CarregandoInterceptor } from './template/carregando/carregando.interceptor';
import { CarregandoComponent } from './template/carregando/carregando.component';
import { DatatableComponent } from './classe/datatable/datatable.component';
import { FormularioComponent } from './classe/formulario/formulario.component';
import { DatatableSimplistaComponent } from './classe/datatable-simplista/datatable-simplista.component';

import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DatePickerFormatDirective } from './customizacao/date-picker-format.directive';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DialogImagemComponent } from './classe/dialog-imagem/dialog-imagem.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { LocaisDeTrabalhoComponent } from './pagina/locais-de-trabalho-pagina/locais-de-trabalho.component';
import { AdmissaoPaginaComponent } from './pagina/admissao-pagina/admissao-pagina.component';

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
])

registerLocaleData(localePt, 'pt');

const modules = [
  MatDatepickerModule,
];

const appDateFormat = {
  parse: {
    dateInput: 'D-M-YYYY',
  },
  display: {
    dateInput: 'D-M-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    DatePickerFormatDirective,
    AppComponent,
    CabecalhoComponent,
    RodapeComponent,
    NavegacaoComponent,
    UsuarioPaginaComponent,
    HomePaginaComponent,
    PartePaginaComponent,
    ProcessoPaginaComponent,
    GerencialPaginaComponent,
    ParteIncluirPaginaComponent,  
    DialogComponent,
    LoginPaginaComponent,
    HistoricoOperacoesPaginaComponent,
    ControleAlertaPaginaComponent,
    ForumPaginaComponent,
    AudienciaPaginaComponent,
    PrazoPaginaComponent,
    TarefaPaginaComponent,
    ProcessoIncluirPaginaComponent,
    ProcessoDetalhePaginaComponent,
    PublicacaoPaginaComponent,
    ParteDetalhePaginaComponent,
    ProcessoConsultaPaginaComponent,
    ParteConsultaPaginaComponent,
    ParametroPaginaComponent,
    AgendaComponent,
    AgendaPaginaComponent,
    CarregandoComponent,
    DatatableComponent,
    FormularioComponent,
    DatatableSimplistaComponent,
    DialogImagemComponent,
    LocaisDeTrabalhoComponent,
    AdmissaoPaginaComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
   ,MatMomentDateModule
   ,MatSlideToggleModule
   ,BrowserAnimationsModule
   ,MatToolbarModule
   ,MatSidenavModule
   ,MatListModule
   ,MatCardModule
   ,MatButtonModule
   ,MatSnackBarModule
   ,HttpClientModule
   ,FormsModule
   ,ReactiveFormsModule
   ,MatRadioModule
   ,MatFormFieldModule
   ,MatInputModule
   ,MatTableModule
   ,MatGridListModule
   ,MatPaginatorModule
   ,MatSortModule
   ,MatSelectModule
   ,NgxMaskModule.forRoot()
   ,MatDatepickerModule
   ,MatNativeDateModule
   ,TextMaskModule
   ,MatCheckboxModule
   ,MatStepperModule
   ,MatMenuModule
   ,MatDialogModule
   ,MatTooltipModule
   ,MatIconModule
   ,MatProgressSpinnerModule
   ,MatTabsModule, CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
   //,SchedulerModule.forRoot({ locale: 'en', headerDateFormat: 'daysRange' })
   ,MatAutocompleteModule
   ,MatCurrencyFormatModule
   ,FullCalendarModule
   ,BrowserModule
   ,ReactiveFormsModule
   ,ImageCropperModule
   ,MatExpansionModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CarregandoInterceptor, multi: true }
   ,{ provide: MatPaginatorIntl, useValue: getPortuguesePaginatorIntl()}
   ,{ provide: LOCALE_ID       , useValue: 'pt-BR'}
   ,{ provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }
   ,{ provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' }
   , DatePipe  
   , {
    provide: MAT_DATE_FORMATS,
    useValue: appDateFormat,
  },
  ],
  exports: [HttpClientModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
