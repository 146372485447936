<!-- <div>{{exibir()}}</div> -->

<form [formGroup]="formulario" class='conteudo'>
  <div class="conteudo-formulario">

    <div class='div-cabecalho'>
      <span *ngFor="let campo of campos_formulario_link">
        <mat-label>{{campo.nome_descritivo}}</mat-label>
        <span class="link" (click)="campo.tipo!.acessar_link(registro)">
          {{exibir_campo(registro, campo)}}
        </span>
      </span>

      <span *ngFor="let campo of campos_formulario">
        <!-- <div *ngIf="campo.tipo!.codigo_tipo! === 'foto'" class="div-foto">
          <img [src]="registro[campo.nome_campo]" height="100" class="foto-arredondada">
          <input type="file" class="file-upload"
            onchange="console.log(event.target.files)" (change)="selecaoFoto($event, campo)">
        </div> -->

        <div *ngIf="campo.tipo!.codigo_tipo! === 'foto'" class="div-foto">
          <img [src]="exibir_campo(registro, campo)" height="100px" class="foto-arredondada">
          <button mat-raised-button color="primary" class="bt-sel-foto" (click)="selecionar_foto()"
          matTooltip='Selecionar Foto'>
          Procurar...
          </button>
        </div>

      </span>
    </div>

    <div class='campos-gerais'>
      <div *ngFor="let campo of campos_formulario">

        <span *ngIf="verifica_condicoes_exibicao(registro, campo.condicao_exibicao!)">

          <mat-form-field *ngIf="campo.tipo!.codigo_tipo! === 'lista' && campo.indicador_campo_parent"
            (focusout)="blur_campo(campo)">

            <mat-label>{{campo.nome_descritivo}}</mat-label>

            <mat-select formControlName="{{campo.nome_parent + campo.nome_campo}}"
              [(value)]="registro[campo.nome_parent!][campo.nome_campo]" name="campo.nome_parent + campo.nome_campo">
              <mat-option value="">Selecione</mat-option>

              <mat-option *ngFor="let item of campo.tipo!.lista" value="{{item.codigo}}">
                {{item.valor}}
              </mat-option>

            </mat-select>

            <mat-error>{{campo.mensagem}}</mat-error>

          </mat-form-field>

          <mat-form-field *ngIf="campo.tipo!.codigo_tipo! === 'lista' && campo.indicador_campo_parent != true"
            (focusout)="blur_campo(campo)">

            <mat-label>{{campo.nome_descritivo}}</mat-label>

            <mat-select formControlName="{{campo.nome_campo}}" [(value)]="registro[campo.nome_campo]"
              name="campo.nome_campo">
              <mat-option value="">Selecione</mat-option>

              <mat-option *ngFor="let item of campo.tipo!.lista" value="{{item.codigo}}">
                {{item.valor}}
              </mat-option>

            </mat-select>

            <mat-error>{{campo.mensagem}}</mat-error>

          </mat-form-field>

          <mat-form-field *ngIf="campo.tipo!.codigo_tipo! === 'texto'" (focusout)="blur_campo(campo)">
            <mat-label>{{campo.nome_descritivo}}</mat-label>

            <input matInput placeholder="{{campo.nome_descritivo}}" formControlName="{{campo.nome_campo}}"
              [(ngModel)]="registro[campo.nome_campo]" name="campo.nome_campo">

            <mat-error>{{campo.mensagem}}</mat-error>

          </mat-form-field>

          <input *ngIf="campo.tipo!.codigo_tipo! === 'oculto'" type="hidden" placeholder="{{campo.nome_descritivo}}"
            formControlName="{{campo.nome_campo}}" [(ngModel)]="registro[campo.nome_campo]" name="campo.nome_campo">

          <mat-form-field *ngIf="campo.tipo!.codigo_tipo! === 'hora'" (focusout)="blur_campo(campo)">
            <mat-label>{{campo.nome_descritivo}}</mat-label>

            <input type="time" matInput placeholder="{{campo.nome_descritivo}}" formControlName="{{campo.nome_campo}}"
              [(ngModel)]="registro[campo.nome_campo]" name="campo.nome_campo">

            <mat-error>{{campo.mensagem}}</mat-error>

          </mat-form-field>

          <mat-form-field *ngIf="campo.tipo!.codigo_tipo! === 'senha'" (focusout)="blur_campo(campo)">
            <mat-label>{{campo.nome_descritivo}}</mat-label>

            <input matInput type="password" placeholder="{{campo.nome_descritivo}}"
              formControlName="{{campo.nome_campo}}" [(ngModel)]="registro[campo.nome_campo]" name="campo.nome_campo">

            <mat-error>{{campo.mensagem}}</mat-error>

          </mat-form-field>

          <mat-form-field *ngIf="campo.tipo!.codigo_tipo! === 'mascara'" (focusout)="blur_campo(campo)"
            (focusout)="blur_campo(campo)">
            <mat-label>{{campo.nome_descritivo}}</mat-label>

            <input matInput placeholder="{{campo.nome_descritivo}}" formControlName="{{campo.nome_campo}}"
              [(ngModel)]="registro[campo.nome_campo]" mask="{{campo.tipo!.mascara}}" name="campo.nome_campo">

            <mat-error>{{campo.mensagem}}</mat-error>

          </mat-form-field>

          <mat-checkbox *ngIf="campo.tipo!.codigo_tipo! === 'flag'" [(ngModel)]="registro[campo.nome_campo]"
            name="campo.nome_campo" [color]="estiloPrimary" formControlName="{{campo.nome_campo}}">
            {{campo.nome_descritivo}}
          </mat-checkbox>

          <mat-form-field *ngIf="campo.tipo!.codigo_tipo! === 'cep'" (focusout)="blur_campo(campo)">
            <mat-label>{{campo.nome_descritivo}}</mat-label>

            <input matInput placeholder="{{campo.nome_descritivo}}" formControlName="{{campo.nome_campo}}"
              (blur)='buscaCEP()' [(ngModel)]="registro[campo.nome_campo]" mask="{{campo.tipo!.mascara}}"
              name="campo.nome_campo">

            <mat-error>{{campo.mensagem}}</mat-error>

          </mat-form-field>

          <!-- 
            <input matInput [textMask]="mask"  
              name="{{campo.nome_campo}}" 
              [matDatepicker]="dateiro"  >
              
              
              
                          <input matInput formControlName="{{campo.nome_campo}}" [(ngModel)]="registro[campo.nome_campo]"
              name="campo.nome_campo" mask="00/00/0000">

            <input matInput formControlName="{{campo.nome_campo}}" [(ngModel)]="registro[campo.nome_campo]"
              name="campo.nome_campo" [matDatepicker]="dateiro" datePickerFormat="DD/MM/YYYY" hidden="true">
              
          -->


          <mat-form-field *ngIf="campo.tipo!.codigo_tipo! === 'data'" class="classData" (focusout)="blur_campo(campo)">
            <mat-label>{{campo.nome_descritivo}}</mat-label>
            <input matInput formControlName="{{campo.nome_campo}}" [(ngModel)]="registro[campo.nome_campo]"
              name="campo.nome_campo" [matDatepicker]="dateiro" datePickerFormat="DD/MM/YYYY">
            <mat-error>{{campo.mensagem}}</mat-error>
            <mat-datepicker-toggle matSuffix [for]="dateiro"></mat-datepicker-toggle>
            <mat-datepicker #dateiro></mat-datepicker>
          </mat-form-field>


          <mat-form-field *ngIf="campo.tipo!.codigo_tipo! === 'autocompleteprocesso'" class="classData"
            (focusout)="blur_campo(campo)" appearance="standard">
            <mat-label>{{campo.nome_descritivo}}</mat-label>
            <input matInput formControlName="{{campo.nome_campo}}" [(ngModel)]="prefixo_numero_processo"
              (ngModelChange)='this.carregarProcesso()' required [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="exibir_valores">
              <mat-option *ngFor='let processo of fonteDados.converteJson()' [value]='processo'
                (onSelectionChange)="selecionar_apenso(processo)">
                {{processo.numero_processo}}
              </mat-option>

            </mat-autocomplete>
            <mat-error>{{campo.mensagem}}</mat-error>
          </mat-form-field>
        </span>

      </div>
    </div>

    <div class="div-rodape">
      <span *ngFor="let campo of campos_formulario_longo">

        <span *ngIf="verifica_condicoes_exibicao(registro, campo.condicao_exibicao!)">

          <mat-form-field *ngIf="campo.tipo!.codigo_tipo! === 'textoLongo'" class="texto-longo"
            (focusout)="blur_campo(campo)">
            <mat-label>{{campo.nome_descritivo}}</mat-label>

            <textarea matInput placeholder="{{campo.nome_descritivo}}" formControlName="{{campo.nome_campo}}"
              [(ngModel)]="registro[campo.nome_campo]" name="campo.nome_campo">
           </textarea>

            <mat-error>{{campo.mensagem}}</mat-error>

          </mat-form-field>
        </span>
      </span>
    </div>
  </div>
</form>