<div>
<mat-toolbar class="rodape">
    <span>
        Aqnos Soluções @2022 - Versão {{versao}}
    </span>
    <!--
    <span>
        <a routerLink="pgHome">
            <img class='logo' src="assets/img/aqnosLogoHorizontal.png" alt="logo">
        </a>
    </span>
    -->
    
</mat-toolbar>
</div>