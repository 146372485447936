import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { TipoTexto } from "../tipos_de_dados/tipoTexto";
import { classeEntidadeDefault } from "./classeEntidadeDefault";
import { TipoData } from "../tipos_de_dados/tipoData";
import { TipoHora } from "../tipos_de_dados/tipoHora";
import { TipoAlertaEvento } from "../tipos_de_dados/tipoAlertaEvento";
import { TipoAlertaTipo } from "../tipos_de_dados/tipoAlertaTipo";
import { TipoAlertaStatus } from "../tipos_de_dados/tipoAlertaStatus";

@Injectable({
  providedIn: 'root'
})
export class classeEntidadeControleAlerta extends classeEntidadeDefault {

//construtor
  
  instanciar (): void {
    this.nome =  'controle_alerta'
    this.nome_externo = 'Alertas'
    this.icone = 'mail'
    this.acao_duplo_clique = { operacao: undefined}
    this.carregar_datatable()
    this.reconfigurar_botoes()
      }
  
  configurar_layout(): void {

    this.reinicializa()
    this.incluir_campo('tipo_alerta', 'Tipo Alerta', new TipoAlertaTipo, true, true, true, false, '', [Validators.required])            
    this.incluir_campo('tipo_evento', 'Evento', new TipoAlertaEvento, true, true, true, false, '', [Validators.required])            
    this.incluir_campo('id_evento', 'Id', new TipoTexto, true, false, false, false, '', [Validators.required])              
    this.incluir_campo('data_alerta', 'Data', new TipoData, true, true, true, false, '', [Validators.required])            
    this.incluir_campo('hora_alerta', 'Hora', new TipoHora, true, true, true, false, '', [Validators.required])            
    this.incluir_campo('destinatario_alerta', 'Destinatário', new TipoTexto, true, true, true, false, '', [Validators.required])    
    this.incluir_campo('contato_alerta', 'Contato', new TipoTexto, true, true, true, false, '', [Validators.required])         
    this.incluir_campo('mensagem_alerta', 'Mensagem', new TipoTexto, true, true, true, false, '', [Validators.required])        
    this.incluir_campo('status_alerta', 'Status', new TipoAlertaStatus, true, true, true, false, '', [Validators.required])          
    this.incluir_campo('mensagem_retorno', 'Obs', new TipoTexto, true, true, true, false, '', [Validators.required])       
 
  }

  reconfigurar_botoes() {    
    this.acoes_nivel_tabela = []
    this.acoes_nivel_registro = []
  }
    
}