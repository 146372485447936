<mat-card>
  <mat-card-title>
    <i class="material-icons">
      post_add
    </i>
    Processo {{processo_formata_para_exibir(this.processo.numero_processo)}}
    <a (click)='alterarProcesso()' matTooltip='Alterar' matTooltipPosition='right' class="link">
      <i class='material-icons '>
        edit
      </i>
    </a>
  </mat-card-title>
  <h2>
    {{this.processo.autor}}  x {{this.processo.reu}}
  </h2>
  <div>
    Processo da cidade de {{this.processo.cidade}} - {{this.processo.estado}},
    administrado pelo fórum {{this.processo.forum.forum}} - {{vara_formata_para_exibir(this.processo.vara)}} vara {{this.processo.orgao}}
    cuja o valor de causa é R$ {{this.processo.valor}}.
  </div>
  <div>
    Obs: {{this.processo.obs}}
  </div>

  <br />
  <div>
    Pasta: {{this.processo.numero_pasta}}
  </div>
  <div>
    Status: {{this.processo.status}}
  </div>

  <div>
    Data Ultimo Movimento: {{this.processo.dt_ultima_alteracao}}
  </div>

  <mat-tab-group mat-align-tabs="start" animationDuration="0ms">

    <mat-tab label="partes">
      <ng-template mat-tab-label>
        <label matTooltip="Partes" matTooltipPosition='right'>
          <i class='material-icons'>
            groups
          </i>
        </label>
      </ng-template>
      <app-datatable-simplista ENTIDADE="autor" ID_PARENT="{{this.id_processo}}" [LISTA_DE_LISTAS]=this.lista_de_listas ></app-datatable-simplista>
      <app-datatable-simplista ENTIDADE="reu" ID_PARENT="{{this.id_processo}}" [LISTA_DE_LISTAS]=this.lista_de_listas ></app-datatable-simplista>
    </mat-tab>

    <mat-tab *ngFor="let aba of this.abas" label="{{aba.label}}">
      <ng-template mat-tab-label>
        <label matTooltip="{{aba.label}}" matTooltipPosition='right'>
          <i class='material-icons'>
            {{aba.icone}}
          </i>
        </label>
      </ng-template>
      <app-datatable ENTIDADE="{{aba.entidade}}" ID_PARENT="{{this.id_processo}}" [LISTA_DE_LISTAS]=this.lista_de_listas></app-datatable>
    </mat-tab>

  </mat-tab-group>


</mat-card>