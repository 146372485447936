import { TipoDados } from "./tipoDados";
import { itemLista } from "./tipoLista";

export class TipoCPF extends TipoDados {

  formatar_para_exibir_em_tela(documento: string): string {
    var retorno = 'indefinido'

    retorno = documento!.toString().padStart(11, '0')
    retorno = retorno.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
    retorno = retorno.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
    retorno = retorno.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos

    return retorno

  }

  codigo_tipo = 'mascara'
  mascara = '000.000.000-00'

  lista: Array<itemLista> = []
}