import { Component, OnInit } from '@angular/core';
import { CabecalhoServicoService } from 'src/app/template/cabecalho/cabecalho-servico.service';
import { NavegacaoServicoService } from 'src/app/template/navegacao/navegacao-servico.service';

@Component({
  selector: 'app-home-pagina',
  templateUrl: './home-pagina.component.html',
  styleUrls: ['./home-pagina.component.css']
})
export class HomePaginaComponent implements OnInit {

  constructor(private vCabecalhoServico: CabecalhoServicoService
             ,private vNavegacaoServico: NavegacaoServicoService) {
    vCabecalhoServico.cabecalho = {
      titulo: 'Home',
      icone: 'home',
      rotaURL: 'pgHome'
    }

    vNavegacaoServico.tipoMenu = 'home';

   }

  ngOnInit(): void {
  }


}
