import { TipoDados } from "./tipoDados";
import { itemLista } from "./tipoLista";

export class TipoOculto extends TipoDados {

  formatar_para_exibir_em_tela(valor: string): string {
    return valor
  }

  codigo_tipo = 'oculto'
  lista: Array<itemLista> = []
}