import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { CarregandoService } from './carregando.service';
import { ngxLoadingAnimationTypes } from 'ngx-loading';

@Component({
  selector: 'app-carregando',
  templateUrl: './carregando.component.html',
  styleUrls: ['./carregando.component.css']
})
export class CarregandoComponent implements OnInit {

  constructor(private carregandoServico: CarregandoService) {

  }

  carregando!: boolean

  ngOnInit(): void {

  }

  ngAfterContentInit() {
    this.carregandoServico.estaCarregando.subscribe((v) => {
      this.carregando = v;
    });
  }

  PrimaryWhite: string = '#ffffff';
  SecondaryGrey: string = '#ccc';

  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public primaryColour = this.PrimaryWhite;
  public secondaryColour = this.SecondaryGrey;
}
