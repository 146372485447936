import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { domainRetornoApiJudicy, urlApiJudicy, autenticacaoApiJudicy } from 'src/app/domain/apiJudicy';
import { SessaoService } from './sessao.service';
import { domainUsuario } from 'src/app/domain/usuario';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { domainParametro } from 'src/app/domain/parametro';
import { enderecoIPService } from './enderecoIP.service';
import { classeEntidade } from 'src/app/classe/entidade/classeEntidade';
import { domainAcao } from 'src/app/domain/acao';

@Injectable({
  providedIn: 'root'
})
export class JudicyService {
  // -------------------------------------- CONSTRUCTOR //
  constructor(private conexaoHttp: HttpClient
    , private sessao: SessaoService
    , private datePipe: DatePipe
    , private servicoEnderecoIP: enderecoIPService
  ) { }

  // -------------------------------------- LINK COM DADOS DO USUARIO LOGADO NA SESSAO //
  get usuarioLogado(): domainUsuario {
    return this.sessao.getUsuarioLogado()
  }

  get enderecoIP(): string {
    return this.servicoEnderecoIP.getEnderecoIP()
  }

  registro: any = {};
  entidade!: classeEntidade
  acao: domainAcao = {}

  inicializa() {

  }

  campo_criador = ''

  ativar_log_usuario_criador(nome_campo: string): void {
    this.campo_criador = nome_campo
  }

  filtro: string = '';
  contar_total(
      p_entidade: string
    , p_numero_pagina: number
    , p_tamanho_pagina: number
    , p_campo_ordenacao: string
    , p_direcao_ordenacao: string
    , p_filtro: string
  ): Observable<domainRetornoApiJudicy> {
    this.filtro = p_filtro
    var url_get = urlApiJudicy;

    url_get = url_get + '/contar_total?entidade=' + p_entidade
    url_get = url_get + '&'
    url_get = url_get + 'id_dominio=' + this.usuarioLogado.dominio?.id_dominio
    url_get = url_get + '&'
    url_get = url_get + 'numero_pagina=' + p_numero_pagina
    url_get = url_get + '&'
    url_get = url_get + 'tamanho_pagina=' + p_tamanho_pagina
    url_get = url_get + '&'
    url_get = url_get + 'filtro=' + p_filtro
    url_get = url_get + '&'
    url_get = url_get + 'campo_ordenacao=' + p_campo_ordenacao
    url_get = url_get + '&'
    url_get = url_get + 'direcao_ordenacao=' + p_direcao_ordenacao
    url_get = url_get + '&'
    url_get = url_get + 'requerente=' + autenticacaoApiJudicy.requerente
    url_get = url_get + '&'
    url_get = url_get + 'chave=' + autenticacaoApiJudicy.chave
    url_get = url_get + '&'
    url_get = url_get + 'login=' + this.usuarioLogado.login
    url_get = url_get + '&'
    url_get = url_get + 'endereco_ip=' + this.enderecoIP;

    return this.conexaoHttp.get(url_get)
  }

  // --------------------------------------- LISTAR COM PAGINACAO //
  listar(
    p_entidade: string
    , p_numero_pagina: number
    , p_tamanho_pagina: number
    , p_campo_ordenacao: string
    , p_direcao_ordenacao: string
    , p_filtro: string
  ): Observable<domainRetornoApiJudicy> {
    this.filtro = p_filtro
    var url_get = urlApiJudicy;

    url_get = url_get + '/paginar?entidade=' + p_entidade
    url_get = url_get + '&'
    url_get = url_get + 'id_dominio=' + this.usuarioLogado.dominio?.id_dominio
    url_get = url_get + '&'
    url_get = url_get + 'numero_pagina=' + p_numero_pagina
    url_get = url_get + '&'
    url_get = url_get + 'tamanho_pagina=' + p_tamanho_pagina
    url_get = url_get + '&'
    url_get = url_get + 'filtro=' + p_filtro

    url_get = url_get + '&'
    url_get = url_get + 'campo_ordenacao=' + p_campo_ordenacao
    url_get = url_get + '&'
    url_get = url_get + 'direcao_ordenacao=' + p_direcao_ordenacao
    url_get = url_get + '&'
    url_get = url_get + 'requerente=' + autenticacaoApiJudicy.requerente
    url_get = url_get + '&'
    url_get = url_get + 'chave=' + autenticacaoApiJudicy.chave
    url_get = url_get + '&'
    url_get = url_get + 'login=' + this.usuarioLogado.login
    url_get = url_get + '&'
    url_get = url_get + 'endereco_ip=' + this.enderecoIP;

    return this.conexaoHttp.get(url_get)
  }

  // --------------------------------------- INCLUIR //
  incluir(p_entidade: string, p_objeto: any): Observable<domainRetornoApiJudicy> {
   
    // if (this.campo_criador != '') {
    //   this.registro[this.campo_criador] = this.sessao.getUsuarioLogado().login
    // }

    this.registro['usuario'] = this.sessao.getUsuarioLogado().login

    // console.log(JSON.stringify(this.registro))

    p_objeto.dominio = this.usuarioLogado.dominio

    return this.conexaoHttp.post<any>(urlApiJudicy + '/incluir?entidade=' + p_entidade
      + '&'
      + 'requerente=' + autenticacaoApiJudicy.requerente
      + '&'
      + 'chave=' + autenticacaoApiJudicy.chave
      + '&'
      + 'login=' + this.usuarioLogado.login
      + '&'
      + 'endereco_ip=' + this.enderecoIP
      , p_objeto)
      ;

  }

  // --------------------------------------- ALTERAR //
  alterar(p_entidade: string, p_objeto: any, p_id?: number, p_opcao?: string, p_parametros?: Array<domainParametro>): Observable<domainRetornoApiJudicy> {
    var url_put = urlApiJudicy + '/alterar/';

    

    if (p_opcao != undefined) {
      url_put = url_put + p_opcao + '/'
    }

    url_put = url_put + p_id + '?entidade=' + p_entidade
    

    var v_primeiro: boolean = true
    if (p_parametros != null) {
      p_parametros.forEach(parametro => {
        if (v_primeiro) {
          url_put = url_put + parametro.campo + '=' + parametro.valor
        }
        else {
          url_put = url_put + '&' + parametro.campo + '=' + parametro.valor
        }

      });
    }

    url_put = url_put + '&'
    url_put = url_put + 'id_dominio=' + this.usuarioLogado.dominio?.id_dominio
    url_put = url_put + '&'
    url_put = url_put + 'requerente=' + autenticacaoApiJudicy.requerente
    url_put = url_put + '&'
    url_put = url_put + 'chave=' + autenticacaoApiJudicy.chave
    url_put = url_put + '&'
    url_put = url_put + 'login=' + this.usuarioLogado.login
    url_put = url_put + '&'
    url_put = url_put + 'endereco_ip=' + this.enderecoIP

    return this.conexaoHttp.put<any>(url_put
      , p_objeto)
      ;

  }

  // --------------------------------------- EXCLUIR //
  excluir(p_entidade: string, p_id?: number): Observable<domainRetornoApiJudicy> {
    return this.conexaoHttp.delete<any>(urlApiJudicy + '/excluir/'
      + p_id
      + '?entidade=' +  p_entidade + '&'
      + 'requerente=' + autenticacaoApiJudicy.requerente
      + '&'
      + 'chave=' + autenticacaoApiJudicy.chave
      + '&'
      + 'login=' + this.usuarioLogado.login
      + '&'
      + 'endereco_ip=' + this.enderecoIP)
  }

  // --------------------------------------- RELATORIOS //
  downloadRelatorio(p_formato: string
    , p_nome_relatorio: string
    , p_id?: number
    , p_filtro?: string
  ): Observable<domainRetornoApiJudicy> {

    var v_url = urlApiJudicy + '/gerar_relatorio'
    if (p_id != null) {
      v_url = v_url + '/' + p_id
    }
    v_url = v_url + '?relatorio=' + p_nome_relatorio + '&'
    v_url = v_url + 'requerente=' + autenticacaoApiJudicy.requerente
    v_url = v_url + '&'
    v_url = v_url + 'chave=' + autenticacaoApiJudicy.chave
    v_url = v_url + '&'
    v_url = v_url + 'login=' + this.usuarioLogado.login
    v_url = v_url + '&'
    v_url = v_url + 'endereco_ip=' + this.enderecoIP
    v_url = v_url + '&'
    v_url = v_url + 'formato=' + p_formato
    v_url = v_url + '&'
    v_url = v_url + 'filtro=' + p_filtro
      ;

    return this.conexaoHttp.get(v_url)

  }

  // --------------------------------------- INCLUIR //
  recarregar(p_data: string, p_servico: any): Observable<domainRetornoApiJudicy> {
    var payload = 'x'
    
    return this.conexaoHttp.post<any>(urlApiJudicy + '/' + p_servico  + '/' + this.usuarioLogado.dominio?.id_dominio
    + '?'
    + 'requerente=' + autenticacaoApiJudicy.requerente
    + '&'
    + 'chave=' + autenticacaoApiJudicy.chave
    + '&'
    + 'login=' + this.usuarioLogado.login
    + '&'
    + 'endereco_ip=' + this.enderecoIP
    + '&'
    + 'data=' +  p_data
    , payload)
      ;

  }

}
