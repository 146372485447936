import { TipoLista } from "./tipoLista";

export class TipoContatoTipo extends TipoLista {
  constructor(
    
  ) {
    super();
    this.prepara_lista()
  }

  prepara_lista(): void {
    this.adicionar_item('1','Residêncial')
    this.adicionar_item('2','Celular')
    this.adicionar_item('3','Comercial')
    this.adicionar_item('4','Recado')
  }

}
