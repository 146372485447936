import { JudicyService } from "src/app/servicos/judicy.service";
import { UtilidadesService } from "src/app/servicos/utilidades.service";
import { TipoListaParametros } from "./tipoListaParametros";

export class TipoProcessoOrgao extends TipoListaParametros {
  constructor(
    public judicy: JudicyService,
    public util: UtilidadesService
  ) {
    super(judicy, util);
    this.prepara_lista()
  }

  prepara_lista(): void {
    this.nome_lista = 'OR'
    this.carregarLista()

  }
  
}