<mat-card class="calendario">
  <mat-card-title>
      <i class="material-icons">
        chrome_reader_mode
      </i>
      Agenda
  </mat-card-title>

  <mat-card-subtitle>
      Aqui você pode verificar todos os prazos, audiências e tarefas que estão assignados pra você!
  </mat-card-subtitle>
</mat-card>

<div class="divDataTable">
  <app-agenda></app-agenda>
</div>