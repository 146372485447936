<mat-card>
    <mat-card-title>
        <i class="material-icons">
            person
        </i>
        Cadastro de Usuários
    </mat-card-title>

    <mat-card-subtitle>
        Aqui você pode gerenciar todos os usuários cadastrados no Strom. 
    </mat-card-subtitle>

</mat-card>

<div class="divDataTable">
    <app-datatable ENTIDADE="usuario" ID_PARENT=""></app-datatable>
</div>