import { TipoLista } from "./tipoLista";

export class TipoListaUF extends TipoLista {
  constructor(
    
  ) {
    super();
    this.prepara_lista()
  }

  prepara_lista(): void {
    this.adicionar_item('AC','AC')
    this.adicionar_item('AL','AL') 
    this.adicionar_item('AP','AP') 
    this.adicionar_item('AM','AM') 
    this.adicionar_item('BA','BA') 
    this.adicionar_item('CE','CE') 
    this.adicionar_item('ES','ES') 
    this.adicionar_item('GO','GO') 
    this.adicionar_item('MA','MA') 
    this.adicionar_item('MT','MT') 
    this.adicionar_item('MS','MS') 
    this.adicionar_item('MG','MG') 
    this.adicionar_item('PA','PA') 
    this.adicionar_item('PB','PB') 
    this.adicionar_item('PR','PR') 
    this.adicionar_item('PE','PE') 
    this.adicionar_item('PI','PI') 
    this.adicionar_item('RJ','RJ') 
    this.adicionar_item('RN','RN') 
    this.adicionar_item('RS','RS') 
    this.adicionar_item('RO','RO') 
    this.adicionar_item('RR','RR') 
    this.adicionar_item('SC','SC') 
    this.adicionar_item('SP','SP') 
    this.adicionar_item('SE','SE') 
    this.adicionar_item('TO','TO')     

  }

}
