import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { Condicao } from "src/app/domain/acao";
import { TipoCNPJ } from "../tipos_de_dados/tipoCNPJ";
import { TipoCPF } from "../tipos_de_dados/tipoCPF";
import { TipoData } from "../tipos_de_dados/tipoData";
import { TipoDocumento } from "../tipos_de_dados/tipoDocumento";
import { TipoDocumentoTipo } from "../tipos_de_dados/tipoDocumentoTipo";
import { TipoParteEstadoCivil } from "../tipos_de_dados/tipoParteEstadoCivil";
import { TipoParteSexo } from "../tipos_de_dados/tipoParteSexo";
import { TipoPIS } from "../tipos_de_dados/tipoPIS";
import { TipoTexto } from "../tipos_de_dados/tipoTexto";
import { classeEntidadeDefault } from "./classeEntidadeDefault";
import { valida_cpf_cnpj } from "src/app/validadores/validador_cpf_cnpj";
import { valida_parte_ja_cadastrada } from "src/app/validadores/validador_parte_existente";

@Injectable({
  providedIn: 'root'
})
export class classeEntidadeParte extends classeEntidadeDefault {

//construtor
  
  instanciar (): void {
    this.nome =  'parte'
    this.nome_externo = 'Parte'
    this.icone = 'person_add_alt'
    this.carregar_datatable()
    this.configurar_layout()
    this.reconfigurar_botoes()
    this.configurar_relatorios()
  }

  configurar_layout(): void {
    this.reinicializa()
    this.incluir_campo('tipo_documento' , 'Tipo Documento' , new TipoDocumentoTipo   , true , true, true , false, '', [Validators.required], true, false)
    
    this.incluir_campo('documento' , 'Documento' , new TipoDocumento       , true , false, true , false, '', [valida_cpf_cnpj('tipo_documento')], false, false)
    this.incluir_campo('nome'      , 'Nome'      , new TipoTexto       , true , false, true , false, '', [Validators.required], false, false)

    var exibe_apenas_pj : Condicao = {}
    exibe_apenas_pj.campo = 'tipo_documento'
    exibe_apenas_pj.valor = '2'

    this.incluir_campo('documento'      , 'CNPJ'            , new TipoCNPJ            , true , true, false , false, '', [], true, false, [exibe_apenas_pj])
    this.incluir_campo('nome'           , 'Razão Social'    , new TipoTexto           , true , true, false , false, '', [Validators.required], true, false, [exibe_apenas_pj])

    var exibe_apenas_pf : Condicao = {}
    exibe_apenas_pf.campo = 'tipo_documento'
    exibe_apenas_pf.valor = '1'

    this.incluir_campo('documento'      , 'CPF'            , new TipoCPF
           , true , true, false , false, '', [valida_cpf_cnpj('tipo_documento')], true, false , [exibe_apenas_pf], [], [valida_parte_ja_cadastrada('tipo_documento',this.judicy)])
    this.incluir_campo('nome'           , 'Nome'           , new TipoTexto           , true , true, false , false, '', [Validators.required], true, false , [exibe_apenas_pf])

    this.incluir_campo('email'          , 'E-Mail'         , new TipoTexto           , false, true, true , false, '', []            , true, false )

    this.incluir_campo('rg'             , 'RG'             , new TipoTexto           , false, true, false, false, '', undefined            , true, true  , [exibe_apenas_pf])
    this.incluir_campo('orgao_emissor'  , 'Orgão Emissor'  , new TipoTexto           , false, true, false, false, '', undefined            , true, true  , [exibe_apenas_pf])
    this.incluir_campo('data_nascimento', 'Data Nascimento', new TipoData            , false, true, false, false, '', undefined            , true, true  , [exibe_apenas_pf])
    this.incluir_campo('estado_civil'   , 'Estado Civil'   , new TipoParteEstadoCivil, false, true, false, false, '', undefined            , true, true  , [exibe_apenas_pf])
    this.incluir_campo('sexo'           , 'Sexo'           , new TipoParteSexo       , false, true, false, false, '', undefined            , true, true  , [exibe_apenas_pf])
    this.incluir_campo('profissao'      , 'Profissão'      , new TipoTexto           , false, true, false, false, '', undefined            , true, true  , [exibe_apenas_pf])
    this.incluir_campo('nacionalidade'  , 'Nacionalidade'  , new TipoTexto           , false, true, false, false, '', undefined            , true, true  , [exibe_apenas_pf])
    this.incluir_campo('nome_mae'       , 'Nome Mãe'       , new TipoTexto           , false, true, false, false, '', undefined            , true, true  , [exibe_apenas_pf])
    this.incluir_campo('ctps_numero'    , 'CTPS Número'    , new TipoTexto           , false, true, false, false, '', undefined            , true, true  , [exibe_apenas_pf])
    this.incluir_campo('ctps_serie'     , 'CTPS Série'     , new TipoTexto           , false, true, false, false, '', undefined            , true, true  , [exibe_apenas_pf])
    this.incluir_campo('pis'            , 'PIS'            , new TipoPIS             , false, true, false, false, '', undefined            , true, true  , [exibe_apenas_pf])
  }

  reconfigurar_botoes() {
    this.tipo_operacao.ALTERAR.executada_apenas_por_criador = true
    var botao = this.tipo_operacao.CONSULTAR
    botao.pagina_destino = 'pgParteDetalhe'
    
    this.acao_duplo_clique = this.tipo_operacao.CONSULTAR
    
    this.acoes_nivel_tabela = []
    this.acoes_nivel_registro = []
    this.acoes_nivel_registro.push(botao)
    this.acoes_nivel_registro.push(this.tipo_operacao.RELATORIO)
    this.acoes_nivel_registro.push(this.tipo_operacao.EXCLUIR)
  }

  configurar_relatorios() {
    //relatorio.formatos = ['pdf', 'docx', 'html', 'xlsx']

    this.lista_relatorio = [
      { formatos : ['docx'], icone : 'payments', descritivo : 'Honorários', nome : 'honorario' }
     ,{ formatos : ['docx'], icone : 'article', descritivo : 'Declaração de Pobreza', nome : 'declaracao_pobreza' }
     ,{ formatos : ['docx'], icone : 'description', descritivo : 'Procuração', nome : 'procuracao' }
     ,{ formatos : ['docx'], icone : 'question_answer', descritivo : 'Ficha de Entrevista', nome : 'ficha_entrevista' }
    ]
  }

    
}