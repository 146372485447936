import { MatDialog } from "@angular/material/dialog";
import { domainAcao, Operacao } from "../domain/acao";
import { JudicyService } from "../servicos/judicy.service";
import { DialogComponent } from "./dialog/dialog.component";
import { classeEntidade } from "./entidade/classeEntidade";


export class ExecutorOperacaoDialog {

  operacao!: Operacao
  registro: any

  execucao_operacao_dialog(registro: any
    , operacao: Operacao
    , id_parent: number
    , judicy: JudicyService
    , entidade: classeEntidade
    , dialog: MatDialog
    , desconsiderar_limpeza?: boolean
  ): MatDialog {
    this.operacao = operacao
    this.registro = registro

    var caixaDialogo: any;
    if (desconsiderar_limpeza == undefined) {
      desconsiderar_limpeza = false
    }

    if (this.operacao == Operacao.INCLUIR || this.operacao == Operacao.INCLUIR_VIA_SELETOR) {

      if (id_parent != 0) {
        var entidadade_pai: any = {}
        entidadade_pai[entidade.campo_id_parent] = id_parent
        judicy.registro = {}
        judicy.registro[entidade.entidade_parent] = entidadade_pai

      } else {
        if (desconsiderar_limpeza) {

        } else {

          judicy.registro = {}
        }
      }
    }

    else {
      judicy.registro = this.registro

    }

    //Object.assign(this.judicy.registro, registro);
    judicy.acao.operacao = this.operacao!
    judicy.entidade = entidade

    caixaDialogo = dialog.open(DialogComponent);
    caixaDialogo.disableClose = true;
    return dialog
  }

}