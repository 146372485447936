  <!--
<mat-horizontal-stepper [linear]="vLinear" #stepper id="stepperIncluirParte">

 <mat-step [editable]="vEditavel">
    <ng-template matStepLabel>Dados Pessoais</ng-template> -->
    <mat-card>

      <mat-card-title>
        <i class="material-icons">
          contact_mail
        </i>
        Cadastro Parte
      </mat-card-title>

      <app-formulario></app-formulario>

      <button mat-raised-button (click)="navegarPara('pgParte')" class="btn-cancelar">
        <i class="material-icons">
          close
        </i>
        Cancelar
      </button>

      <button mat-raised-button (click)="incluirParte()" class="btn-confirmar">
        <i class="material-icons">
          check
        </i>
        Cadastrar
      </button>

    </mat-card>
<!-- 

  </mat-step>
  <mat-step [editable]="vEditavel">
    <ng-template matStepLabel>Informações Adicionais</ng-template>

    <mat-card>
      <mat-card-title>
        <i class="material-icons icone">
          contact_mail
        </i>
        Informações Adicionais
      </mat-card-title>
      <mat-card-subtitle>
        Inclua as informações adicionais de {{registro.nome}}.
      </mat-card-subtitle>


      <app-datatable-simplista ENTIDADE="endereco" ID_PARENT="{{this.registro.id_parte}}"></app-datatable-simplista>
      <app-datatable-simplista ENTIDADE="contato" ID_PARENT="{{this.registro.id_parte}}"></app-datatable-simplista>
      <app-datatable-simplista ENTIDADE="conta_bancaria" ID_PARENT="{{this.registro.id_parte}}"></app-datatable-simplista>

      <div style="padding-top: 10px;">
      <button mat-raised-button (click)="passoSeguinte()" class="btn-confirmar" matTooltip="Próxima Etapa"
        matTooltipPosition="below">
        <i class="material-icons">
          navigate_next 
        </i> Próximo
      </button>
    </div>

    </mat-card>
  </mat-step>

  <mat-step [editable]="vEditavel">
    <ng-template matStepLabel>Conclusão</ng-template>
    <mat-card>

      <mat-card-title>
        <i class="material-icons icone">
          check
        </i>
        Conclusão
      </mat-card-title>

      <mat-card-subtitle class="subtitulo">
        Cadastro da Parte concluído! Abaixo resumo.
      </mat-card-subtitle>

      <span>
        <h1>Parte:</h1>
        <p>Concluido o cadastro de {{registro.nome}} portador do 
        {{registro.tipo_documento}} número
        {{registro.documento}}. 
        </p>



      </span>

      <button mat-raised-button (click)="navegarPara('pgParte')" class="btn-cancelar">
        <i class="material-icons">
          close
        </i>
        Sair
      </button>


    </mat-card>
  </mat-step>



</mat-horizontal-stepper> -->