import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { SessaoService } from 'src/app/servicos/sessao.service';
import { JudicyService } from 'src/app/servicos/judicy.service';

@Component({
  selector: 'app-dialog-imagem',
  templateUrl: './dialog-imagem.component.html',
  styleUrls: ['./dialog-imagem.component.css']
})
export class DialogImagemComponent implements OnInit {

  constructor(
      private judicy: JudicyService,
      private dialogRef: MatDialogRef<DialogImagemComponent>,
      private sessaoServico: SessaoService

  ) { }

  ngOnInit(): void {
  }

  confirmar(): void {
    this.judicy.registro['foto'] = this.croppedImage
    this.dialogRef.close()
  }

  cancelar(): void {
    this.dialogRef.close()
  }

  title = 'image-cropper-angular';
  imageChangedEvent: any = '';
  croppedImage: any = '';

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
}
