
export interface domainAcao {
    operacao?: Operacao
    icone?: string
    estilo?: string
    executada_apenas_por_criador ?: boolean
    descricao ?: string
    pagina_destino ?: string
    nome_campo_chave ?: string
    pai ?: string
    entidade_seletor ?: string
    identificador_seletor ?:string
    condicao ?: Condicao
    nao_exibir_primeiro_registro ?: boolean
    nao_exibir_ultimo_registro ?: boolean
    condicao_desabilitar ?: Condicoes
}

export interface Condicoes {
    condicoes ?: Array<Condicao>
}

export interface Condicao {
    campo ?: string
    valor ?: any
    tipo ?: boolean
}

export interface CondicaoDestacar {
    campo ?: string
    valor ?: any
    estilo ?: string
}

export enum Operacao {
     ALTERAR                = 'alterar'
    ,INCLUIR                = 'incluir'
    ,INCLUIR_VIA_SELETOR    = 'incluir_via_seletor'
    ,EXCLUIR                = 'excluir'
    ,CONSULTAR              = 'consultar'
    ,CONSULTAR_NOVA_PAGINA  = 'consultar_nova_pagina'
    ,CONSULTAR_DIALOG       = 'consultar_dialog'
    ,ALTERAR_NOVA_PAGINA    = 'alterar_nova_pagina'
    ,RELATORIO              = 'relatorio'
    ,ALTERAR_SEQUENCIA_UP   = 'alterar_sequencia_up'
    ,ALTERAR_SEQUENCIA_DOWN = 'alterar_sequencia_down'
    ,CONCLUIR               = 'concluir'
    ,BLOQUEAR               = 'bloquear'
    ,CANCELAR               = 'cancelar'
    ,DESBLOQUEAR            = 'desbloquear'
    ,RESETAR                = 'resetar'
    ,RECARREGAR             = 'recarregar'

}
export class TipoOperacao {
      array_name: Array<Condicao> = []

      EXCLUIR                 = {operacao: Operacao.EXCLUIR               , icone: 'delete'        , estilo: 'btn-excluir'             ,executada_apenas_por_criador: false, condicao_desabilitar: {}, descricao: 'Excluir'                                }
      INCLUIR                 = {operacao: Operacao.INCLUIR               , icone: 'add'           , estilo: 'btn-incluir'             ,executada_apenas_por_criador: false, condicao_desabilitar: {}, descricao: 'Incluir'                                }
      INCLUIR_VIA_SELETOR     = {operacao: Operacao.INCLUIR_VIA_SELETOR   , icone: 'add'           , estilo: 'btn-incluir'             ,executada_apenas_por_criador: false, condicao_desabilitar: {}, descricao: 'Incluir'          , entidade_seletor: ''}
      ALTERAR                 = {operacao: Operacao.ALTERAR               , icone: 'edit'          , estilo: 'btn-alterar'             ,executada_apenas_por_criador: false, condicao_desabilitar: {}, descricao: 'Alterar'                                }
      ALTERAR_SEQUENCIA_UP    = {operacao: Operacao.ALTERAR_SEQUENCIA_UP  , icone: 'arrow_upward'  , estilo: 'btn-alterar'             ,executada_apenas_por_criador: false, condicao_desabilitar: {}, descricao: 'Alterar Sequencia', nao_exibir_primeiro_registro : true }
      ALTERAR_SEQUENCIA_DOWN  = {operacao: Operacao.ALTERAR_SEQUENCIA_DOWN, icone: 'arrow_downward', estilo: 'btn-alterar'             ,executada_apenas_por_criador: false, condicao_desabilitar: {}, descricao: 'Alterar Sequencia', nao_exibir_ultimo_registro : true }
      CONSULTAR               = {operacao: Operacao.CONSULTAR             , icone: 'search'        , estilo: 'btn-consultar'           ,executada_apenas_por_criador: false, condicao_desabilitar: {}, descricao: 'Consultar'        , pagina_destino: ''  }
      CONSULTAR_DIALOG        = {operacao: Operacao.CONSULTAR_DIALOG      , icone: 'search'        , estilo: 'btn-consultar'           ,executada_apenas_por_criador: false, condicao_desabilitar: {}, descricao: 'Consultar'        , pagina_destino: ''  }
      CONSULTAR_NOVA_PAGINA   = {operacao: Operacao.CONSULTAR_NOVA_PAGINA , icone: 'search'        , estilo: 'btn-consultar'           ,executada_apenas_por_criador: false, condicao_desabilitar: {}, descricao: 'Consultar'        , pagina_destino: ''  }
      ALTERAR_NOVA_PAGINA     = {operacao: Operacao.ALTERAR_NOVA_PAGINA   , icone: 'edit'          , estilo: 'btn-alterar'             ,executada_apenas_por_criador: false, condicao_desabilitar: {}, descricao: 'Alterar'          , pagina_destino: ''  }
      RELATORIO               = {operacao: Operacao.RELATORIO             , icone: 'list_alt'      , estilo: 'btn-relatorios'          ,executada_apenas_por_criador: false, condicao_desabilitar: {}, descricao: 'Relatórios'                             }
      CONCLUIR                = {operacao: Operacao.CONCLUIR              , icone: 'task_alt'      , estilo: 'btn-concluir'            ,executada_apenas_por_criador: false, condicao_desabilitar: {}, descricao: 'Concluir'                               }
      BLOQUEAR                = {operacao: Operacao.BLOQUEAR              , icone: 'block'         , estilo: 'btn-excluir'             ,executada_apenas_por_criador: false, condicao_desabilitar: {}, descricao: 'Bloquear'                               }
      CANCELAR                = {operacao: Operacao.CANCELAR              , icone: 'block'         , estilo: 'btn-excluir'             ,executada_apenas_por_criador: false, condicao_desabilitar: {}, descricao: 'Cancelar'                               }
      DESBLOQUEAR             = {operacao: Operacao.DESBLOQUEAR           , icone: 'lock_open'     , estilo: 'btn-incluir'             ,executada_apenas_por_criador: false, condicao_desabilitar: {}, descricao: 'Desbloquear'                            }
      RESETAR                 = {operacao: Operacao.RESETAR               , icone: 'lock_reset'    , estilo: 'btn-incluir'             ,executada_apenas_por_criador: false, condicao_desabilitar: {}, descricao: 'Resetar'                                }
      RECARREGAR              = {operacao: Operacao.RECARREGAR            , icone: 'repeat'        , estilo: 'btn-incluir'             ,executada_apenas_por_criador: false, condicao_desabilitar: {}, descricao: 'Recarregar'                             }
}