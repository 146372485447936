import { Component, OnInit } from '@angular/core';
import { CabecalhoServicoService } from 'src/app/template/cabecalho/cabecalho-servico.service';
import { NavegacaoServicoService } from 'src/app/template/navegacao/navegacao-servico.service';

@Component({
  selector: 'app-parte-consulta-pagina',
  templateUrl: './parte-consulta-pagina.component.html',
  styleUrls: ['./parte-consulta-pagina.component.css']
})
export class ParteConsultaPaginaComponent implements OnInit {

  constructor(private vCabecalhoServico: CabecalhoServicoService
    , private vNavegacaoServico: NavegacaoServicoService
  ) {
    vCabecalhoServico.cabecalho = {
      titulo: 'Parte > Consulta',
      icone: 'person_outline',
      rotaURL: 'pgParte'
    }

    vNavegacaoServico.tipoMenu = 'parte';
  }

  ngOnInit(): void {
  }

}
