import { TipoDados } from "./tipoDados";
import { itemLista } from "./tipoLista";

export class TipoNumeroProcessoApenso extends TipoDados {

  formatar_para_exibir_em_tela(processo: string): string {
    var retorno: string
    if (processo == undefined) {
      retorno = 'Indefinido:' + processo
    } else {
      if (processo?.length == 20) {
        retorno = processo!.toString()
        retorno = retorno.substr(0, 7) + '-'
          + retorno.substr(7, 2) + '.'
          + retorno.substr(9, 4) + '.'
          + retorno.substr(13, 1) + '.'
          + retorno.substr(14, 2) + '.'
          + retorno.substr(16,)
      } else {
        retorno = processo
      }
    }
    return retorno
  }
  
  codigo_tipo = 'autocompleteprocesso'
  lista: Array<itemLista> = []
  mascara = '0000000-00.0000.0.00.0000'

}