import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CabecalhoServicoService } from 'src/app/template/cabecalho/cabecalho-servico.service';
import { NavegacaoServicoService } from 'src/app/template/navegacao/navegacao-servico.service';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-publicacao-pagina',
  templateUrl: './publicacao-pagina.component.html',
  styleUrls: ['./publicacao-pagina.component.css']
})
export class PublicacaoPaginaComponent implements OnInit {

  constructor(private vCabecalhoServico: CabecalhoServicoService
    , private vNavegacaoServico: NavegacaoServicoService
    , public dialog: MatDialog
    ,public datepipe: DatePipe
  ) {
    vCabecalhoServico.cabecalho = {
      titulo: 'Processo > Publicação',
      icone: 'feed',
      rotaURL: 'pgProcesso'
    }

    vNavegacaoServico.tipoMenu = 'processo';
  }

  ngOnInit(): void {

  }

}