import { Component, OnInit } from '@angular/core';
import { domainRetornoApiJudicy } from 'src/app/domain/apiJudicy';
import { JudicyService } from 'src/app/servicos/judicy.service';
import { UtilidadesService } from 'src/app/servicos/utilidades.service';
import { CabecalhoServicoService } from 'src/app/template/cabecalho/cabecalho-servico.service';
import { NavegacaoServicoService } from 'src/app/template/navegacao/navegacao-servico.service';

@Component({
  selector: 'app-gerencial-pagina',
  templateUrl: './gerencial-pagina.component.html',
  styleUrls: ['./gerencial-pagina.component.css']
})
export class GerencialPaginaComponent implements OnInit {

  constructor(private vCabecalhoServico: CabecalhoServicoService
    , private vNavegacaoServico: NavegacaoServicoService
    , private judicy: JudicyService
    , private util: UtilidadesService
  ) {
    vCabecalhoServico.cabecalho = {
      titulo: 'Gerencial',
      icone: 'settings',
      rotaURL: 'pgGerencial'
    }
    vNavegacaoServico.tipoMenu = 'gerencial';

  }
  
  lista_entidades = ['usuario','forum','log']

  ngOnInit(): void {
    this.contabilizarRecursivo()
  }

 
  total : any = {forum : 0, usuario:0, log:0}
  
  ix = 0
  contabilizarRecursivo(): void {
    if (this.ix < this.lista_entidades.length) {
      this.contabilizarTotal(this.lista_entidades[this.ix])
    }  

  }
  contabilizarTotal(entidade: string): void {
    this.judicy.contar_total(
      entidade
      , 1
      , 1
      , ''
      , ''
      , ''
    ).subscribe((resposta: any) => {
      var retornoJudicy: domainRetornoApiJudicy = resposta;

      if (retornoJudicy.status == 0) {
        this.total[this.lista_entidades[this.ix]] = resposta.total_registros
        this.ix = this.ix + 1
        this.contabilizarRecursivo()
      }

      else {
        this.util.exibirMensagem(retornoJudicy.mensagem!, this.util.tp_msg.erro());
      }

    }
    ), (error: any) => {
      this.util.exibirMensagem(this.util.catalogo.msg_erro_listagem(), this.util.tp_msg.erro());
      console.log('Erro', error);
    }
      ;

  }
}
