import { TipoDados } from "./tipoDados";
import { itemLista } from "./tipoLista";

export class TipoTelefone extends TipoDados {

  formatar_para_exibir_em_tela(telefone: string): string {
    var retorno : string

    if (telefone == undefined) {
      retorno = 'Indefinido'
    }
    else {
      retorno = telefone
    }

    if (telefone?.length == 10) {
      retorno = '(' + telefone.substr(0, 2) + ') '
        + telefone.substr(2, 4) + '-'
        + telefone.substr(6, 4)
    }

    if (telefone?.length == 11) {
      retorno = '(' + telefone.substr(0, 2) + ') '
        + telefone.substr(2, 5) + '-'
        + telefone.substr(7, 4)
    }

    return retorno
  }

  mascara = "(00)0000-00009"
  codigo_tipo = 'mascara'
  lista: Array<itemLista> = []
}