<mat-card>
    <mat-card-title>
        <i class="material-icons">
            apartment
        </i>
        Cadastro de Fóruns
    </mat-card-title>

    <mat-card-subtitle>
        Aqui você pode gerenciar todos os fóruns cadastrados no Strom. 
    </mat-card-subtitle>

</mat-card>

<div class="divDataTable">
    <app-datatable ENTIDADE="forum" ID_PARENT=""></app-datatable>
</div>