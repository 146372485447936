import { domainRetornoApiJudicy } from "src/app/domain/apiJudicy";
import { domainForum } from "src/app/domain/forum";
import { JudicyService } from "src/app/servicos/judicy.service";
import { UtilidadesService } from "src/app/servicos/utilidades.service";
import { TipoLista } from "./tipoLista";

export class TipoListaForum extends TipoLista {
  constructor(
    private judicy: JudicyService,
    private util: UtilidadesService
  ) {
    super();
    this.prepara_lista()
  }

  prepara_lista(): void {
    this.carregarForum()
  }

  listaForum: Array<domainForum> = []

  carregarForum(): void {
    this.judicy.listar(
      'forum'
      , 1
      , 999999999
      , 'id_forum'
      , 'asc'
      , ''
    )
      .subscribe((resposta: any) => {
        var retornoJudicy: domainRetornoApiJudicy = resposta;

        if (retornoJudicy.status == 0) {
          this.listaForum = resposta.conteudo
          for (var forum of this.listaForum) {
            var vforum : domainForum
            vforum = forum
            this.adicionar_item(vforum.id_forum, forum.forum!)
          }
        }

        else {
          this.util.exibirMensagem(retornoJudicy.mensagem!, this.util.tp_msg.erro());
        }

      }
      ), (error: any) => {
        this.util.exibirMensagem(this.util.catalogo.msg_erro_listagem(), this.util.tp_msg.erro());
        console.log('Erro', error);
      }
      ;
  }

}