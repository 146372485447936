import { FormGroup } from "@angular/forms";

export function valida_data_inicio_e_fim(
    campo_data_inicio: string,
  ) {
    return (data_fim: FormGroup) => {
      const data_inicio = data_fim.root.get(campo_data_inicio);
      var retorno = null

      if (data_inicio!.value > data_fim.value) {
        retorno = { data_fim_menor_que_inicio: true };
      }

      return retorno

    }
};