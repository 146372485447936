import { itemLista } from "./tipoLista"

export class TipoDados  {
  constructor(
  ) { }

  formatar_para_exibir_em_tela(dado: any, tipo?: any): string {
    return 'x'
  }

  mascara = ''
  codigo_tipo = 'x'
  lista: Array<itemLista> = []

  acessar_link(registro: any) : void {

  }

  set_mascara( tipo : any) {
    
  }

}