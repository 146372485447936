<mat-card class="calendario">
    <mat-card-title>
        <i class="material-icons">
          gavel
        </i>
        Consulta de Audiências
    </mat-card-title>

    <mat-card-subtitle>
        Aqui você pode consultar todas as audiências cadastradas, que o Judicy está cuidando pra você. 
    </mat-card-subtitle>
    
</mat-card>

<div class="divDataTable">
    <app-datatable ENTIDADE="audiencia" ID_PARENT=""></app-datatable>
</div>