import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { domainAcao, Operacao } from 'src/app/domain/acao';
import { domainRetornoApiJudicy } from 'src/app/domain/apiJudicy';
import { domainCampo } from 'src/app/domain/entidade';
import { domainParametro } from 'src/app/domain/parametro';
import { TipoOperadorJudicy } from 'src/app/domain/tipoOperadorJudicy';
import { domainUsuario } from 'src/app/domain/usuario';
import { SessaoService } from 'src/app/servicos/sessao.service';
import { JudicyService } from 'src/app/servicos/judicy.service';
import { UtilidadesService } from 'src/app/servicos/utilidades.service';
import { DialogComponent } from '../dialog/dialog.component';
import { classeEntidade } from '../entidade/classeEntidade';
import { TipoEntidade } from '../entidade/tipoEntidade';
import { ExecutorOperacaoDialog } from '../OperacaoDialog';
import { ListasDeBancoDeDados } from '../tipos_de_dados/tipoListaBancoDeDados';

@Component({
  selector: 'app-datatable-simplista',
  templateUrl: './datatable-simplista.component.html',
  styleUrls: ['./datatable-simplista.component.css']
})
export class DatatableSimplistaComponent implements OnInit {

  @Input()
  ENTIDADE!: string;

  @Input()
  ID_PARENT!: string;

  
  @Input()
  LISTA_DE_LISTAS!: ListasDeBancoDeDados;

  constructor(
    private dialog: MatDialog
    , private util: UtilidadesService
    , private judicy: JudicyService
    , private sessao: SessaoService
    , private rotaURL: Router

  ) { }

  permitir_incluir = true

  entidade!: classeEntidade

  // ----------- CONTROLE DE PAGINACAO E ORDENACAO MATERIAL 
  @ViewChild(MatPaginator) paginacao!: MatPaginator;
  @ViewChild(MatSort) ordenacao!: MatSort;

  instanciar() {

  }

  carregar_datatable() {

    var lista_de_listas: ListasDeBancoDeDados;

    if (this.LISTA_DE_LISTAS == undefined) {
      lista_de_listas = new ListasDeBancoDeDados(this.judicy, this.util)
    } else {
      lista_de_listas = this.LISTA_DE_LISTAS
    }

    var tipo_entidade = new TipoEntidade(this.judicy, this.util, lista_de_listas)
    this.entidade = tipo_entidade.getEntidade(this.ENTIDADE)['instancia']

    if (this.ID_PARENT == '') {
      this.entidade.flag_exibicao_parent = false
      this.entidade.id_parent = 0
    }
    else {
      this.entidade.flag_exibicao_parent = true
      this.entidade.id_parent = + this.ID_PARENT

    }

    this.acoes_nivel_registro = []
    this.acoes_nivel_tabela = []

    this.acoes_nivel_tabela = this.entidade.acoes_nivel_tabela
    this.acoes_nivel_registro = this.entidade.acoes_nivel_registro

    this.atualizar_conteudo_tabela()

  }

  ngOnInit(): void {
    this.carregar_datatable()
  }

  exibir_campo(linha: any, coluna: domainCampo): string {
    if (coluna.indicador_campo_parent && coluna.nome_parent) {
      return coluna.tipo!.formatar_para_exibir_em_tela(linha[coluna.nome_parent][coluna.nome_campo])

    } else {
      return coluna.tipo!.formatar_para_exibir_em_tela(linha[coluna.nome_campo])
    }
  }

  filtro_judicy: string = ''
  listaFiltro: Array<any> = [];

  // ----------- CARREGA DATA TABLE DE ACORDO COM PARAMETROS
  atualizar_conteudo_tabela() {
    this.listaFiltro = []
    this.carregar_filtro_parent()
    this.converte_filtro_para_string()
    this.listar_registros()
  }

  carregar_filtro_parent(): void {
    if (this.entidade.campo_id_parent != '') {
      this.listaFiltro.push({ campo: this.entidade.campo_id_parent, valor: this.entidade.id_parent.toString(), operador_logico: TipoOperadorJudicy.IGUAL })
    }
  }

  converte_filtro_para_string(): void {
    if (this.listaFiltro.length != 0) {
      this.filtro_judicy = '{ "filtro":' + JSON.stringify(this.listaFiltro) + '}';
    } else {
      this.filtro_judicy = ''
    }
  }

  listar_registros(): void {
    this.acionar_api_judicy_de_listagem().pipe(
    ).subscribe((retorno_api_judicy: any) => {
      this.trata_retorno_consulta(retorno_api_judicy)
    }
    ), (error: any) => {
      this.trata_erro_conexao_api(error)

    }
      ;
  }

  acionar_api_judicy_de_listagem(): Observable<domainRetornoApiJudicy> {
    return this.judicy.listar(
      this.entidade.nome!
      , 1
      , 999
      , this.entidade.campo_ordenacao!
      , this.entidade.direcao_ordenacao!
      , this.filtro_judicy
    )
  }

  trata_erro_conexao_api(error: any): void {
    this.util.exibirMensagem(this.util.catalogo.msg_erro_listagem(), this.util.tp_msg.erro());
    console.log('Erro', error);
  }

  registros: Array<any> = []

  trata_retorno_consulta(retornoJudicy: domainRetornoApiJudicy): void {
    if (retornoJudicy.status == 0) {
      this.registros = retornoJudicy.conteudo
    }

    else {
      this.util.exibirMensagem(retornoJudicy.mensagem!, this.util.tp_msg.erro());
    }
  }

  get usuarioLogado(): domainUsuario {
    return this.sessao.getUsuarioLogado()
  }

  verificaDesabilitarAcao(registro: any, acao: domainAcao): boolean {
    var desabilitado = false
    var criador: domainUsuario
    criador = registro['usuario']

    if (acao.executada_apenas_por_criador) {
      if (this.usuarioLogado.perfil != 'S' && this.usuarioLogado.login != criador) {
        desabilitado = true
      }
    }

    return desabilitado

  }

  verificaOcultarAcao(registro: any, acao: domainAcao): boolean {
    var oculto = false

    if (registro[acao.condicao?.campo!] != acao.condicao?.valor) {
      oculto = true
    }

    if (acao.nao_exibir_primeiro_registro) {
      if (registro['sequencial'] == 1) {
        oculto = true
      }
    }

    if (acao.nao_exibir_ultimo_registro) {
      if (registro['sequencial'] == this.registros.length) {
        oculto = true
      }
    }


    return oculto

  }
  acao!: domainAcao
  registro: any

  executar_operacao(registro: any, acao: domainAcao) {
    this.acao = acao
    this.registro = registro
    this.judicy.entidade = this.entidade
    if (acao.operacao == Operacao.ALTERAR_SEQUENCIA_UP || acao.operacao == Operacao.ALTERAR_SEQUENCIA_DOWN) {
      this.alterar_sequencia()
    } else {
      if (acao.operacao == Operacao.CONSULTAR_NOVA_PAGINA || acao.operacao == Operacao.ALTERAR_NOVA_PAGINA) {
        if (this.entidade.nome == 'reu' || this.entidade.nome == 'autor') {
          this.util.abrirEmNovaJanela(acao.pagina_destino + '/' + registro['parte']['id_parte'])

        } else {
          this.util.abrirEmNovaJanela(acao.pagina_destino + '/' + registro['id_' + this.entidade.nome])
        }
      } else {
        if (acao.operacao == Operacao.CONSULTAR) {
          this.rotaURL.navigate([acao.pagina_destino + '/' + registro['id_' + this.entidade.nome]])
        } else {
          this.execucao_operacao_dialog()
        }
      }
    }
  }

  alterar_sequencia() {
    var v_parametro: domainParametro = {}
    v_parametro.campo = 'direcao'
    if (this.acao.operacao == Operacao.ALTERAR_SEQUENCIA_UP) {
      v_parametro.valor = 'acima'
    } else {
      v_parametro.valor = 'abaixo'

    }
    console.log('reg = ' + JSON.stringify(this.registro))
    this.judicy.alterar(this.entidade.nome, this.registro, this.registro['id_' + this.entidade.nome]
      , 'altera_sequencia', [v_parametro]).subscribe(
        resposta => {
          if (resposta.status == 0) {
            this.util.exibirMensagem(this.util.catalogo.msg_sucesso_alteracao(), this.util.tp_msg.sucesso());
            this.atualizar_conteudo_tabela()
          }
          else {
            this.util.exibirMensagem(resposta.mensagem!, this.util.tp_msg.erro());
            //console.log('Erro', retornoJudicy.mensagem);                                                    
          }

        }
        , (error: any) => {
          this.util.exibirMensagem(this.util.catalogo.msg_erro_alteracao(), this.util.tp_msg.erro());
          //console.log('Erro', error);                                                              
        }
      );
  }
  
  execucao_operacao_dialog(): void {
    var executor = new ExecutorOperacaoDialog()

    this.judicy.acao = this.acao

    if (this.acao.operacao != undefined) {
      var dialog = executor.execucao_operacao_dialog(this.registro, this.acao.operacao,
        this.entidade.id_parent
        , this.judicy, this.entidade, this.dialog)

      dialog.afterAllClosed.subscribe(data => this.atualizar_conteudo_tabela())
    }
  }

  acoes_nivel_registro: Array<domainAcao> = []
  acoes_nivel_tabela: Array<domainAcao> = []

  formatar_registro(registro: any): string {
    return this.entidade.formatar_registro(registro)
  }


}
