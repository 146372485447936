import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { domainRetornoApiJudicy } from 'src/app/domain/apiJudicy';
import { domainLog } from 'src/app/domain/log';
import { domainProcesso } from 'src/app/domain/processo';
import { domainUsuario } from 'src/app/domain/usuario';
import { SessaoService } from 'src/app/servicos/sessao.service';
import { UtilidadesService } from 'src/app/servicos/utilidades.service';
import { LogControlService } from 'src/app/servicos/logControl.service';
import { autenticacaoApiJudicy } from 'src/app/domain/apiJudicy';
import { enderecoIPService } from 'src/app/servicos/enderecoIP.service';
import { MatDialog } from '@angular/material/dialog';
import { CabecalhoServicoService } from 'src/app/template/cabecalho/cabecalho-servico.service';
import { NavegacaoServicoService } from 'src/app/template/navegacao/navegacao-servico.service';
import { ExecutorOperacaoDialog } from 'src/app/classe/OperacaoDialog';
import { JudicyService } from 'src/app/servicos/judicy.service';
import { TipoEntidade } from 'src/app/classe/entidade/tipoEntidade';
import { Operacao } from 'src/app/domain/acao';
import { TipoNumeroProcesso } from 'src/app/classe/tipos_de_dados/tipoNumeroProcesso';
import { TipoVara } from 'src/app/classe/tipos_de_dados/tipoVara';
import { ListasDeBancoDeDados } from 'src/app/classe/tipos_de_dados/tipoListaBancoDeDados';

@Component({
  selector: 'app-processo-detalhe-pagina',
  templateUrl: './processo-detalhe-pagina.component.html',
  styleUrls: ['./processo-detalhe-pagina.component.css']
})
export class ProcessoDetalhePaginaComponent implements OnInit {

  constructor(
    private rota: ActivatedRoute,
    private util: UtilidadesService,
    private sessao: SessaoService,
    private judicy: JudicyService,
    private controladorLog: LogControlService,
    private servicoEnderecoIP: enderecoIPService,
    private dialog: MatDialog,
    private vCabecalhoServico: CabecalhoServicoService
    , private vNavegacaoServico: NavegacaoServicoService
  ) {
    vCabecalhoServico.cabecalho = {
      titulo: 'Processo > Detalhe',
      icone: 'feed',
      rotaURL: 'pgProcesso'
    }

    vNavegacaoServico.tipoMenu = 'processo';
  }


  id_processo = this.rota.snapshot.paramMap.get("id_processo");
  filtro: domainProcesso = { forum: {} }
  log: domainLog = {}

  flag_carregou: string = 'S'

  ngOnInit(): void {
    //this.processo = this.judicy.registro
    //console.log('---------------------init')
    //console.log(JSON.stringify(this.processo))
    this.carrega_pagina()

  }

  vara_formata_para_exibir(p_vara?: number) : string {
    var vara = new TipoVara 
    return vara.formatar_para_exibir_em_tela(p_vara)
  }

  lista_de_listas : any = undefined
  
  carrega_pagina() {
    console.log('lista aqui 1')
    this.lista_de_listas = new ListasDeBancoDeDados(this.judicy, this.util)

    var id_processo_number: number;

    if (this.id_processo == null || this.id_processo == undefined) {
      id_processo_number = 0
    } else {
      id_processo_number = + this.id_processo
    }

    this.judicy.listar('processo',1, 1, '', ''
      , this.util.formataParametrosFiltro(this.filtro, id_processo_number, 'id_processo')
    ).subscribe((resposta: any) => {
      var retornoJudicy: domainRetornoApiJudicy = resposta;

      if (retornoJudicy.status == 0) {
        this.processo = retornoJudicy.conteudo[0]
        if (this.flag_carregou == 'S') {
          this.flag_carregou = 'N'
          this.incluirLogConsulta()
        }
      }

      else {
        this.util.exibirMensagem(retornoJudicy.mensagem!, this.util.tp_msg.erro());
      }


    }
    ), (error: any) => {
      this.util.exibirMensagem(this.util.catalogo.msg_erro_listagem(), this.util.tp_msg.erro());
      console.log('Erro', error);
    }
  }

  get usuarioLogado(): domainUsuario {
    return this.sessao.getUsuarioLogado()
  }

  get enderecoIP(): string {
    return this.servicoEnderecoIP.getEnderecoIP()
  }

  incluirLogConsulta(): void {

    this.log.dominio         = this.usuarioLogado.dominio
    this.log.entidade        = 'PROCESSO'
    this.log.operacao        = 'CONSULTA'
    this.log.registro        = 'PROCESSO = ' + this.processo.numero_processo
    this.log.id_registro     = this.processo.id_processo
    this.log.usuario_login   = this.usuarioLogado.login
    this.log.requerente      = autenticacaoApiJudicy.requerente
    this.log.endereco_ip     = this.enderecoIP

    this.controladorLog.incluirLog(this.log)

  }

  refresh() {
    this.carrega_pagina()
  }

  processo: domainProcesso = {forum:{}}

  alterarProcesso() {
    this.judicy.registro = this.processo
    var lista_de_listas = new ListasDeBancoDeDados(this.judicy, this.util)
    var tipo_entidade = new TipoEntidade(this.judicy, this.util, lista_de_listas)
    var entidade = tipo_entidade.getEntidade('processo')['instancia']
    var executor = new ExecutorOperacaoDialog()

    var dialog = executor.execucao_operacao_dialog(this.judicy.registro, Operacao.ALTERAR,
      0
      , this.judicy, entidade, this.dialog)

    dialog.afterAllClosed.subscribe(data => this.refresh())
  }

  abas = [
    {entidade: 'andamento'  , label: 'Andamento'  , icone: 'history'           },
    {entidade: 'saneamento' , label: 'Saneamento' , icone: 'recycling'         },
    {entidade: 'publicacao' , label: 'Publicação' , icone: 'my_library_books'  },
    {entidade: 'prazo'      , label: 'Prazo'      , icone: 'date_range'        },
    {entidade: 'audiencia'  , label: 'Audiência'  , icone: 'gavel'             },
    {entidade: 'apenso'     , label: 'Apenso'     , icone: 'attach_file'       },
    {entidade: 'local'      , label: 'Local'      , icone: 'place'             },
    ]

    processo_formata_para_exibir(processo?: string) : string {
      var v_processo = new TipoNumeroProcesso 
      return v_processo.formatar_para_exibir_em_tela(processo!)
    }
  
}

