<section>
  <mat-toolbar class="menu mat-elevation-z3">
    <span>
      <a routerLink="pgHome">
        <img class='logo' src="assets/img/JudicyLogo.png" alt="logo">

      </a>

    </span>

    <span class="centro">
      <button mat-button><a mat-list-item routerLink="pgHome">Home</a></button>
      <button mat-button><a mat-list-item routerLink="pgParte">Parte</a></button>
      <button mat-button><a mat-list-item routerLink="pgProcesso">Processo</a></button>
      <!-- <button mat-button><a mat-list-item routerLink="pgProcesso">Financeiro</a></button>-->
      <button *ngIf='usuarioLogado.perfil === "D" || usuarioLogado.perfil === "S"' mat-button>
        <a mat-list-item routerLink="pgGerencial">Gerencial</a>
      </button>
    </span>

    <span class="espacamento"></span>
    <!-- 
        <input class="pesquisa" type="search" 
               name="inputPesquisa" placeholder="Pesquisar"
            >
        <button mat-button class="btnPesquisa">
                <i class='material-icons'>
                    search
                </i>
        </button>
      -->
    <div class="estilo-foto-div">
      <img [src]="formatar_foto(usuarioLogado.foto)" class="estilo-foto-img">
    </div>
    <span class="iconeUsuario" class="iconeUsuario">

      <button mat-button class="menuUsuario">
        <span>{{usuarioLogado.nome}}</span>
      </button>

      <button mat-button [matMenuTriggerFor]="menu" class="btnAcoes">
        <i class='material-icons'>
          more_vert
        </i>
      </button>
    </span>

    <mat-menu #menu="matMenu">

      <button mat-menu-item (click)="alterarSenha()">
        <span>
          <i class='material-icons'>
            lock
          </i>
          Alterar Senha</span>
      </button>

      <button mat-menu-item (click)="alterarCadastro()">
        <span>
          <i class='material-icons'>
            change_circle
          </i>
          Alterar Cadastro</span>
      </button>

      <button mat-menu-item (click)="realizarLogout()">
        <i class='material-icons'>
          logout
        </i>
        Sair
      </button>

    </mat-menu>

  </mat-toolbar>

</section>

<mat-toolbar class="cabecalho ">
  <!--

-->

  <span class='titulo'>
    <a routerLink="this.vCabecalhoServico.cabecalho.rotaURL">

      <i class="material-icons">
        {{ icone }}
      </i>


      {{ titulo }}

    </a>
  </span>
</mat-toolbar>