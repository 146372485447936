import { HttpClient, HttpXhrBackend } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { domainConsultaEnderecoIP } from "src/app/domain/consultaEnderecoIP";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class enderecoIPService {
  enderecoIP = '';

  constructor() { }

  conexaoHttp = new HttpClient(new HttpXhrBackend({
    build: () => new XMLHttpRequest()
  }));

  getEnderecoIP(): string {
    // this.executaConsultaPorEnderecoIP().subscribe((resposta: any) => {
    //   var retornoIP: domainConsultaEnderecoIP = resposta;

    //   if (retornoIP.IPv4 != undefined) {
    //       this.enderecoIP = retornoIP.country_code 
    //                       + ' - ' + retornoIP.state 
    //                       + ' - ' + retornoIP.city
    //                       + ' (' + retornoIP.IPv4 + ')'
    //   }
    // })
    this.enderecoIP = ''
    return this.enderecoIP

  }

  executaConsultaPorEnderecoIP(
  ): Observable<domainConsultaEnderecoIP> {
    var url_consulta_ips = "http://geolocation-db.com/json/"
    return this.conexaoHttp.get(url_consulta_ips)
  }

}

