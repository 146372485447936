import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CalendarOptions, DateSelectArg, EventClickArg, EventInput, EventApi, EventDropArg, FullCalendarComponent, Calendar } from '@fullcalendar/angular';
import { UtilidadesService } from '../servicos/utilidades.service';
import { domainRetornoApiJudicy } from '../domain/apiJudicy';
import { domainUsuario } from '../domain/usuario';
import { domainTarefa } from '../domain/tarefa';
import { domainPrazo } from '../domain/prazo';
import { EventResizeDoneArg } from '@fullcalendar/interaction';
import { SessaoService } from '../servicos/sessao.service';
import { JudicyService } from '../servicos/judicy.service';
import { ExecutorOperacaoDialog } from '../classe/OperacaoDialog';
import { domainAcao, TipoOperacao } from '../domain/acao';
import { TipoEntidade } from '../classe/entidade/tipoEntidade';
import { TipoAudienciaTipo } from '../classe/tipos_de_dados/tipoAudienciaTipo';
import { TipoOperadorJudicy } from '../domain/tipoOperadorJudicy';
import { DatePipe } from '@angular/common';
import { ListasDeBancoDeDados } from '../classe/tipos_de_dados/tipoListaBancoDeDados';


@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css']
})
export class AgendaComponent implements OnInit {

  constructor(private dialog: MatDialog
    , private util: UtilidadesService
    , private sessaoServico: SessaoService
    , private judicy: JudicyService
     ,public datepipe: DatePipe
  ) { }

  listaEventos: EventInput[] = []
  registros_do_usuario: Array<any> = []
  tarefas_usuario: Array<domainTarefa> = []
  prazos_usuario: Array<domainPrazo> = []
  indicador_ver_registros_escritorio: boolean = false

  get usuarioLogado(): domainUsuario {
    return this.sessaoServico.getUsuarioLogado()
  }

  get_cor(p_entidade: string): string {
    var v_retorno = ''
    if (p_entidade == 'audiencia') {
      v_retorno = '#31859c'
    }

    if (p_entidade == 'prazo') {
      v_retorno = '#604a7b'
    }

    if (p_entidade == 'tarefa') {
      v_retorno = '#17375e'
    }

    return v_retorno

  }

  get_titulo(p_entidade: string, registro: any): string {
    var v_retorno = ''
    if (p_entidade == 'audiencia') {
      var tipo = new TipoAudienciaTipo()
      v_retorno = tipo.formatar_para_exibir_em_tela(registro['tipo']) + ' - ' + registro['hora']
    }

    if (p_entidade == 'prazo') {
      v_retorno = registro['descricao']
    }

    if (p_entidade == 'tarefa') {
      v_retorno = registro['descricao']
    }

    return v_retorno

  }

  get_data_fim(p_entidade: string, registro: any): Date {
    var v_retorno = new Date()

    if (p_entidade == 'audiencia') {
      v_retorno = registro['data']
    }

    if (p_entidade == 'prazo') {
      v_retorno = this.util.adicionarDias(registro['data_fim'], 1)
    }

    if (p_entidade == 'tarefa') {
      v_retorno = this.util.adicionarDias(registro['data_fim'], 1)
    }

    return v_retorno

  }

  get_data_inicio(p_entidade: string, registro: any): Date {
    var v_retorno = new Date()

    if (p_entidade == 'audiencia') {
      v_retorno = registro['data']
    }

    if (p_entidade == 'prazo') {
      v_retorno = registro['data_inicio']
    }

    if (p_entidade == 'tarefa') {
      v_retorno = registro['data_inicio']
    }

    return v_retorno

  }

  get_nome_entidade(p_id_entidade: string): any {

    var nome_entidade = ''

    if (p_id_entidade.substring(0, 1) == 'a') {
      nome_entidade = 'audiencia'

    }

    if (p_id_entidade.substring(0, 1) == 'p') {
      nome_entidade = 'prazo'

    }

    if (p_id_entidade.substring(0, 1) == 't') {
      nome_entidade = 'tarefa'

    }
    return nome_entidade

  }

  ngOnInit(): void {

  }


  calendario!: Calendar;

  @ViewChild("calendario_html", { static: false })
  calendarComponent!: FullCalendarComponent


  flag_primeira_vez = true

  ngAfterViewChecked() {
    this.calendario = this.calendarComponent.getApi();
    
    if (this.flag_primeira_vez) {
      this.recarregarEventos()
      this.flag_primeira_vez = false
    }

  }

  lista_entidades = ['audiencia', 'prazo', 'tarefa']
  ix = 0

  recarregarEventos(): void {
    // console.log('indicador: ' )
    // console.log(this.indicador_ver_registros_escritorio)
    this.ix = 0
    this.calendario?.removeAllEvents()
    this.carregarEventos()

  }

  listaFiltroData: Array<any> = [];
  filtro_judicy: string = ''

  formata_data(data: string): string {
    return data.substring(6, 11) + '-' + data.substring(3, 5) + '-' + data.substring(0, 2)
  }
  
  aplicar_filtro(entidade:string): void {
    var mes_anterior = this.calendario.getDate()
    mes_anterior.setMonth(mes_anterior.getMonth() - 3);
    
    var mes_seguinte = this.calendario.getDate()
    mes_seguinte.setMonth(mes_seguinte.getMonth() + 3);

    var data_inicio = this.datepipe.transform(mes_anterior, 'dd-MM-yyyy');
    var data_fim = this.datepipe.transform(mes_seguinte, 'dd-MM-yyyy');
    
    var campo = ''
    if (entidade == 'audiencia') {
       campo = 'data'
    } else {
      campo = 'data_inicio'
    }
    
    this.aplicaFiltroData(campo, data_inicio!, data_fim!)
    
    this.converte_filtro_para_string()

  }

  aplicaFiltroData(campo: string, dataInicio: string, dataFim: string) {
    this.listaFiltroData = []

    if (dataInicio != '') {
      this.listaFiltroData.push({ campo: campo, valor: this.formata_data(dataInicio), operador_logico: TipoOperadorJudicy.MAIOR_IGUAL })
    }

    if (dataFim != '') {
      this.listaFiltroData.push({ campo: campo, valor: this.formata_data(dataFim), operador_logico: TipoOperadorJudicy.MENOR_IGUAL })
    }


  }

  converte_filtro_para_string(): void {
    if (this.listaFiltroData.length != 0) {
      this.filtro_judicy = '{ "filtro":' + JSON.stringify(this.listaFiltroData) + '}';
    } else {
      this.filtro_judicy = ''
    }
  }
  
  
  carregarEventos(): void {    

    if (this.ix < this.lista_entidades.length) {
      this.carregaEntidade()
    }
  }


  carregaEntidade(): void {
    this.aplicar_filtro(this.lista_entidades[this.ix])
    
    this.listar_eventos(this.lista_entidades[this.ix])
      .subscribe((resposta: any) => {
        var retornoJudicy: domainRetornoApiJudicy = resposta;

        if (retornoJudicy.status == 0) {
          this.registros_do_usuario = resposta.conteudo

          this.registros_do_usuario.forEach(
            registro => { this.insereNoCalendario(registro, this.lista_entidades[this.ix]) }
          )

          this.registros_do_usuario = []

          this.ix = this.ix + 1

          //console.log('carregaEntidade = ' + this.ix)
          this.carregarEventos()
        }

        else {
          this.util.exibirMensagem(retornoJudicy.mensagem!, this.util.tp_msg.erro());
        }

      }
      ), (error: any) => {
        this.util.exibirMensagem(this.util.catalogo.msg_erro_listagem(), this.util.tp_msg.erro());
        console.log('Erro', error);
      }
      ;
  }

  insereNoCalendario(registro: any
    , entidade: string
  ): void {
    var v_evento: EventInput = {}
    var v_cor = this.get_cor(entidade)
    var v_data_inicio = this.get_data_inicio(entidade, registro)
    var v_data_fim = this.get_data_fim(entidade, registro)
    var v_titulo = this.get_titulo(entidade, registro)

    // console.log('----------event--------')
    // console.log('entidade      = ' + entidade)
    // console.log('v_cor         = ' + v_cor)
    // console.log('v_titulo      = ' + v_cor)
    // console.log('v_data_inicio = ' + v_data_inicio)
    // console.log('v_data_fim    = ' + v_data_fim)
    // console.log('registro    = ' + JSON.stringify(registro))
    // console.log('-----------------------')
    var lista_usuarios: Array<string> = []
    lista_usuarios.push(this.usuarioLogado!.login!)

    if (this.indicador_ver_registros_escritorio) {
      lista_usuarios.push('Escritorio')
    }

    if (lista_usuarios.includes(registro['responsavel'])) {

      if (entidade == "audiencia" || registro['status'] != 'C') {
        var inicio: Date = new Date(v_data_inicio)
        var fim: Date = new Date(v_data_fim)
        v_evento.id = entidade + '=' + registro['id_' + entidade]
        v_evento.title = v_titulo
        v_evento.start = inicio.toISOString().replace(/T.*$/, '')
        v_evento.end = fim.toISOString().replace(/T.*$/, '')
        v_evento.allDay = true
        v_evento.backgroundColor = v_cor
        v_evento.borderColor = v_cor
        v_evento.extendedProps = registro
        if (entidade == 'audiencia') {
          v_evento.durationEditable = false
        } else {
          v_evento.durationEditable = true
        }
      }
    }
    this.calendario.addEvent(v_evento)
  }



  calendarioVisivel = true;
  proximoMes(): void {
    this.calendario.next();
    this.recarregarEventos()
  }

  mesAnterior(): void {
    this.calendario.prev();
    this.recarregarEventos()
  }

  mesCorrente(): void {
    this.calendario.today();
    this.recarregarEventos()
  }

  opcoesCalendario: CalendarOptions = {
    eventSources: [
      {

      }
      // ...

    ],
    customButtons: {
      next: {
        click: this.proximoMes.bind(this),
      },
      prev: {
        click: this.mesAnterior.bind(this),
      },
      today: {
        text: "Hoje",
        click: this.mesCorrente.bind(this),
      },
    },
    locale: 'pt-br',
    headerToolbar: {
      left: 'prev,next today',
      center: '',
      right: 'title'//'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    },
    buttonText: {
      today: 'Hoje',
      month: 'Mês',
      week: 'Semana',
      day: 'Hoje',
      list: 'Lista'
    },
    initialView: 'dayGridMonth',
    //initialEvents: this.eventosIniciais, // alternatively, use the `events` setting to fetch from a feed
    //events: this.eventosIniciais,
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: false,
    select: this.selecaoDiasNoCalendario.bind(this),
    eventClick: this.selecaoEvento.bind(this),
    eventDrop: this.movimentacaoEvento.bind(this),
    eventResize: this.alteraTamanhoEvento.bind(this),
    //eventsSet: this.handleEvents.bind(this)
    /* you can update a remote database when these fire:
    eventAdd:
    eventChange:
    eventRemove:
    */
  };

  exibirCalendario() {
    this.calendarioVisivel = !this.calendarioVisivel;
  }


  exibirFinalDeSenama() {
    const { opcoesCalendario } = this;
    opcoesCalendario.weekends = !opcoesCalendario.weekends;
  }

  selecaoDiasNoCalendario(rangeSelecionado: DateSelectArg) {
    this.incluirTarefa(rangeSelecionado.start, rangeSelecionado.end)
  }


  listar_eventos(p_nome_entidade: string) {
    return this.judicy.listar(p_nome_entidade,
      1
      , 1000000
      , 'id_' + p_nome_entidade
      , 'asc'
      , this.filtro_judicy
    )
  }

  eventoSelecionado: EventApi | undefined

  movimentacaoEvento(p_argumentosCliqueEvento: EventDropArg) {
    this.eventoSelecionado = p_argumentosCliqueEvento.event
    var registro: any = {}
    Object.assign(registro, this.eventoSelecionado.extendedProps)
    var nome_entidade = this.get_nome_entidade(this.eventoSelecionado.id)
    this.alterarNoBanco(nome_entidade, registro, this.eventoSelecionado.start, this.eventoSelecionado.end)
  }

  alteraTamanhoEvento(p_argumentosCliqueEvento: EventResizeDoneArg) {
    this.eventoSelecionado = p_argumentosCliqueEvento.event
    var registro: any = {}
    Object.assign(registro, this.eventoSelecionado.extendedProps)
    var nome_entidade = this.get_nome_entidade(this.eventoSelecionado.id)
    this.alterarNoBanco(nome_entidade, registro, this.eventoSelecionado.start, this.eventoSelecionado.end)
  }

  alterarNoBanco(entidade: string, registro: any, novaDataInicio: Date | null, novaDataFim: Date | null
  ): void {
    if (entidade == 'audiencia') {
      if (novaDataInicio != null) {
        registro['data'] = novaDataInicio
      }
    } else {
      if (novaDataInicio != null) {
        registro['data_inicio'] = novaDataInicio
      }

      if (novaDataFim != null) {
        var fim: Date = this.util.subtrairDias(novaDataFim, 1)
        registro['data_fim'] = fim
      }
    }

    this.eventoSelecionado!.remove();
    this.insereNoCalendario(registro, entidade)

    this.judicy.alterar(entidade,
      registro, registro['id_' + entidade])
      .subscribe(
        resposta => { this.trata_retorno(resposta) }
        , (error: any) => {
          this.util.exibirMensagem(this.util.catalogo.msg_erro_alteracao(), this.util.tp_msg.erro());
        }
      );
  }


  selecaoEvento(p_argumentosCliqueEvento: EventClickArg) {
    this.executaAlteracao(p_argumentosCliqueEvento)

  }

  executaAlteracao(p_argumentosCliqueEvento: EventClickArg) {
    this.eventoSelecionado = p_argumentosCliqueEvento.event

    var registro: any = {}
    Object.assign(registro, this.eventoSelecionado.extendedProps)

    var nome_entidade = this.get_nome_entidade(this.eventoSelecionado.id)

    var tp = new TipoOperacao()
    var acao: domainAcao = tp.ALTERAR

    this.execucao_operacao_dialog(registro, acao, nome_entidade)
  }

  execucao_operacao_dialog(registro: any, acao: domainAcao, nome_entidade: string): void {
    this.judicy.registro = registro
    // console.log('reg')
    // console.log(JSON.stringify(this.judicy.registro))

    this.judicy.acao = acao

    var executor = new ExecutorOperacaoDialog()
    var lista_de_listas = new ListasDeBancoDeDados(this.judicy, this.util)
    var tipo_entidade = new TipoEntidade(this.judicy, this.util, lista_de_listas)
    var entidade = tipo_entidade.getEntidade(nome_entidade)['instancia']

    var dialog = executor.execucao_operacao_dialog(this.judicy.registro, acao.operacao!,
      0
      , this.judicy, entidade, this.dialog, true)

    var subscrive = dialog.afterAllClosed.subscribe(() => {
      // console.log('------------------------------atualizando')
      // console.log('this.eventoSelecionado = ' +JSON.stringify( this.eventoSelecionado))
      // console.log('this.judicy.registr = ' +JSON.stringify( this.judicy.registro))
      // console.log('nome_entidade = ' +JSON.stringify( nome_entidade))
      this.eventoSelecionado?.remove()
      this.insereNoCalendario(this.judicy.registro, nome_entidade)
      subscrive.unsubscribe()
    })



  }


  incluirTarefa(p_data_inicio: Date, p_data_fim: Date) {
    var registro: domainTarefa = {};
    registro.data_inicio = p_data_inicio
    registro.data_fim = p_data_fim

    var tp = new TipoOperacao()
    var acao: domainAcao = tp.INCLUIR

    this.execucao_operacao_dialog(registro, acao, 'tarefa')

  }


  trata_retorno(p_ret: domainRetornoApiJudicy) {
    if (p_ret.status == 0) {
      this.util.exibirMensagem(this.util.catalogo.msg_sucesso_alteracao(), this.util.tp_msg.sucesso());
    }
    else {
      this.util.exibirMensagem(p_ret.mensagem!, this.util.tp_msg.erro());
    }
  }

}
