import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { TipoData } from "../tipos_de_dados/tipoData";
import { TipoNumeroProcessoLink } from "../tipos_de_dados/tipoNumeroProcessolink";
import { TipoPublicacaoStatus } from "../tipos_de_dados/tipoPublicacaoStatus";
import { TipoTexto } from "../tipos_de_dados/tipoTexto";
import { classeEntidadeDefault } from "./classeEntidadeDefault";
import { valida_data_menor_igual_hoje } from "src/app/validadores/validador_data_menor_igual_hoje";

@Injectable({
  providedIn: 'root'
})
export class classeEntidadePublicacao extends classeEntidadeDefault {
 
  instanciar (): void {
    this.nome =  'publicacao'
    this.nome_externo = 'Publicação'
    this.icone = 'my_library_books'
    this.carregar_datatable()
    this.configurar_dados_parent()
    this.reconfigurar_botoes()
    this.configurar_relatorios()

  }
  
  configurar_dados_parent(): void {
       this.campo_id_parent = 'id_processo'
       this.entidade_parent = 'processo'
  }
  
  configurar_layout(): void {

    this.reinicializa()
    this.incluir_campo('data_disponibilizacao', 'Data Disponibilização', new TipoData, true, true, true, false, '', [Validators.required])
    this.incluir_campo('nome_jornal', 'Nome Jornal', new TipoTexto, true, true, true, false, '', [Validators.required])
    this.incluir_campo('data_tratamento', 'Data Tratamento', new TipoData, true, true, false, false, '', [Validators.required])
    this.incluir_campo('termo_referencia_data', 'Referência', new TipoTexto, true, true, true, false, '', [Validators.required])
    this.incluir_campo('texto_publicacao', 'Texto', new TipoTexto, true, true, false, false, '', [Validators.required])
    this.incluir_campo('titulo', 'Título', new TipoTexto, true, true, true, false, '', [Validators.required])
    this.incluir_campo('numero_publicacao', 'Número', new TipoTexto, true, true, true, false, '', [Validators.required])
    this.incluir_campo('numero_arquivo', 'Arquivo', new TipoTexto, true, true, true, false, '', [Validators.required])
    this.incluir_campo('cabecalho', 'Cabeçalho', new TipoTexto, true, true, false, false, '', [Validators.required])
    this.incluir_campo('rodape', 'Rodapé', new TipoTexto, true, true, false, false, '', [Validators.required])
    this.incluir_campo('codigo_relacionamento', 'Código Relacionamento', new TipoTexto, true, true, false, false, '', [Validators.required])
    this.incluir_campo('status', 'Status', new TipoPublicacaoStatus, true, true, true, false, '', [Validators.required])    
    this.incluir_campo('data_inclusao', 'Data Inclusão', new TipoData, true, true, false, false, '', [Validators.required])
    this.incluir_campo('numero_processo', 'Número Processo', new TipoNumeroProcessoLink(this.util, this.judicy), true, false, true, true, 'processo')

    this.layout_resetar = [
      {nome_campo: 'data'      ,nome_descritivo:'Data'     , tipo: new TipoData         ,obrigatorio:true, editavel:true, exibe_consulta: false, indicador_campo_parent: false, nome_parent:''        , validacao: [Validators.required, valida_data_menor_igual_hoje()]}
     ]
  }

  reconfigurar_botoes() {
    this.acoes_nivel_tabela = []
    this.acoes_nivel_registro = []
    var btn_rel = this.tipo_operacao.RELATORIO
    btn_rel.estilo = 'btn-incluir'
    this.acao_duplo_clique = this.tipo_operacao.CONSULTAR_DIALOG

    this.acoes_nivel_registro.push(this.tipo_operacao.CONSULTAR_DIALOG)
    this.acoes_nivel_tabela.push(btn_rel)
    this.acoes_nivel_tabela.push(this.tipo_operacao.RECARREGAR)
  }

  configurar_relatorios() {
    //relatorio.formatos = ['pdf', 'docx', 'html', 'xlsx']

    this.lista_relatorio = [
      { formatos : ['docx'], icone : 'summarize', descritivo : 'Detalhamento Publicações', nome : 'detalhamento_publicacao', nivel:'tabela' }
    ]
  }
  
  get_titulo(registro: any): string {
    return registro['nome_jornal'] + ' - Publicação ' + registro['numero_publicacao']
  }

  get_subtitulo(registro: any): string {
    return registro['titulo'] 
  }

  get_texto(registro: any): string {
    return  registro['texto_publicacao']
  }

  get_rodape(registro: any): string {
    return registro['rodape']
  }

}
