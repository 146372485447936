import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { classeCatalogo, classeTipoMensagem } from 'src/app/classe/mensagens';
import { domainRetornoApiJudicy } from 'src/app/domain/apiJudicy';
import { domainLog } from 'src/app/domain/log';
import { JudicyService } from './judicy.service';
import { UtilidadesService } from './utilidades.service';

@Injectable({
  providedIn: 'root'
})
export class LogControlService {

  constructor(private util: UtilidadesService
    ,private judicy: JudicyService
    ) { }
   
  incluirLog(p_log : domainLog ) {
    this.judicy.incluir('log',p_log)                                           
    .subscribe(resposta => { 
        this.trata_retorno(resposta);
    }                                       
      , (error: any) => {                                                                          
        this.util.exibirMensagem(this.catalogo.msg_erro_inclusao(), this.tp_msg.erro());
        //console.log('Erro', error);                                                              
      }                                                                                            
    )
  }

  trata_retorno(p_ret: domainRetornoApiJudicy) {                                                        
    if (p_ret.status != 0) {                                                                           
      this.util.exibirMensagem(p_ret.mensagem!, this.tp_msg.erro());                              
      //console.log('Erro', retornoJudicy.mensagem);                                                    
    }                                                                                                  
  }     
  // -------------------------------------- VARIAVEIS DE MENSAGENS PADRAO //
  catalogo: classeCatalogo = new (classeCatalogo);
  tp_msg: classeTipoMensagem = new (classeTipoMensagem);

}
