import { domainRetornoApiJudicy } from "src/app/domain/apiJudicy";
import { domainLista } from "src/app/domain/lista";
import { JudicyService } from "src/app/servicos/judicy.service";
import { UtilidadesService } from "src/app/servicos/utilidades.service";
import { TipoLista } from "./tipoLista";

export class TipoListaParametros extends TipoLista {
  constructor(
    public judicy: JudicyService,
    public util: UtilidadesService
  ) {
    super();
    this.prepara_lista()
  }

  prepara_lista(): void {
    // this.carregarLista()
 
  }

  nome_lista = ''

  listaOpcoes: Array<domainLista> = []
  
  carregarLista(): void {
    console.log('listar')
    this.judicy.listar(
      'lista'
      , 1
      , 999999999
      , 'id_lista'
      , 'asc'
      , ''
    )
      .subscribe((resposta: any) => {
        var retornoJudicy: domainRetornoApiJudicy = resposta;

        if (retornoJudicy.status == 0) {
          this.listaOpcoes = resposta.conteudo

          this.lista = []

          for (var opcao of this.listaOpcoes) {

            if (this.nome_lista == opcao.lista) {
              this.adicionar_item(opcao.valor!, opcao.valor!)
            }


          }
        }

        else {
          this.util.exibirMensagem(retornoJudicy.mensagem!, this.util.tp_msg.erro());
        }

      }
      ), (error: any) => {
        this.util.exibirMensagem(this.util.catalogo.msg_erro_listagem(), this.util.tp_msg.erro());
        console.log('Erro', error);
      }
      ;
  }

}