import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CabecalhoServicoService } from 'src/app/template/cabecalho/cabecalho-servico.service';
import { NavegacaoServicoService } from 'src/app/template/navegacao/navegacao-servico.service';

@Component({
  selector: 'app-prazo-pagina',
  templateUrl: './prazo-pagina.component.html',
  styleUrls: ['./prazo-pagina.component.css']
})
export class PrazoPaginaComponent implements OnInit {

  constructor(
      public dialog: MatDialog
    , private vCabecalhoServico: CabecalhoServicoService
    , private vNavegacaoServico: NavegacaoServicoService
  ) {
    vCabecalhoServico.cabecalho = {
      titulo: 'Processo > Prazo',
      icone: 'feed',
      rotaURL: 'pgProcesso'
    }

    vNavegacaoServico.tipoMenu = 'processo';
  }

  ngOnInit(): void {
    
  }

}