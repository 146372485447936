import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { TipoListaUF } from "../tipos_de_dados/tipoListaUF";
import { TipoNumeroProcesso } from "../tipos_de_dados/tipoNumeroProcesso";
import { TipoProcessoInatividade } from "../tipos_de_dados/tipoProcessoInatividade";
import { TipoTexto } from "../tipos_de_dados/tipoTexto";
import { TipoTextoLongo } from "../tipos_de_dados/tipoTextoLongo";
import { classeEntidadeDefault } from "./classeEntidadeDefault";
import { valida_processo_ja_cadastrado } from "src/app/validadores/validador_processo_existente";
import { TipoVara } from "../tipos_de_dados/tipoVara";

@Injectable({
  providedIn: 'root'
})
export class classeEntidadeProcesso extends classeEntidadeDefault {

//construtor
  
  instanciar (): void {
    this.nome =  'processo'
    this.nome_externo = 'Processo'
    this.icone = 'post_add'
    this.carregar_datatable()
    this.configurar_layout()
    this.reconfigurar_botoes()
    this.configurar_relatorios()
  }

  configurar_layout(): void {
    this.reinicializa()
    this.incluir_campo('numero_processo', 'Processo'   , new TipoNumeroProcesso      , true ,  true , true , false, ''     , [Validators.required], true, undefined, [], [], [valida_processo_ja_cadastrado(this.judicy)])
    this.incluir_campo('numero_pasta'   , 'Pasta'      , new TipoTexto               , true ,  true , true , false, ''     , [Validators.required], true)
    this.incluir_campo('estado'         , 'UF'         , new TipoListaUF             , true ,  true , false, false, ''     , [Validators.required], true)
    this.incluir_campo('cidade'         , 'Cidade'     , new TipoTexto               , true ,  true , false, false, ''     , [Validators.required], true)
    this.incluir_campo('vara'           , 'Vara'       , new TipoVara                , true ,  true , false, false, ''     , [Validators.required], true)
    this.incluir_campo('orgao'          , 'Orgão'      , this.lista.orgao            , true ,  true , false, false, ''     , [Validators.required], true)
    this.incluir_campo('valor'          , 'Valor'      , new TipoTexto               , true ,  true , false, false, ''     , [Validators.required], true)
    this.incluir_campo('obs'            , 'Obs'        , new TipoTextoLongo          , false,  true , false, false, ''     , [Validators.required], true)
    this.incluir_campo('status'         , 'Status'     , this.lista.status           , true ,  true , true , false, ''     , [Validators.required], true)
    this.incluir_campo('autor'          , 'Autor'      , new TipoTexto               , false,  false, true , false, ''     , [Validators.required], false)
    this.incluir_campo('reu'            , 'Réu'        , new TipoTexto               , false,  false, true , false, ''     , [Validators.required], false)
    this.incluir_campo('inatividade'    , 'Inatividade', new TipoProcessoInatividade , false,  false, true , false, ''     , [Validators.required], false)
    this.incluir_campo('id_forum'       , 'Fórum'      , this.lista.forum            , true ,  true , false, true , 'forum', [Validators.required], true)

  }

  reconfigurar_botoes() {
    this.tipo_operacao.ALTERAR.executada_apenas_por_criador = true
    var botao = this.tipo_operacao.CONSULTAR_NOVA_PAGINA
    botao.pagina_destino = 'pgProcessoDetalhe'
    this.acao_duplo_clique = this.tipo_operacao.CONSULTAR_NOVA_PAGINA

    this.acoes_nivel_tabela = []
    this.acoes_nivel_registro = []
    this.acoes_nivel_registro.push(botao)
    this.acoes_nivel_registro.push(this.tipo_operacao.RELATORIO)

  }

  configurar_relatorios() {
    //relatorio.formatos = ['pdf', 'docx', 'html', 'xlsx']

    this.lista_relatorio = [
      { formatos : ['docx'], icone : 'description', descritivo : 'Substabelecimento', nome : 'substabelecimento' }
    ]
  }

    
}