import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { JudicyService } from "../servicos/judicy.service";
import { TipoOperadorJudicy } from '../domain/tipoOperadorJudicy';
import { map } from 'rxjs/operators';

export function valida_parte_ja_cadastrada(campo_tipo_documento: string,
  judicy: JudicyService):
  AsyncValidatorFn {
  return (control: AbstractControl) => {
    const documento = control.value;
    const tipo = control.root.get(campo_tipo_documento)!.value;

    var filtro_documento = { campo: 'documento', valor: documento, operador_logico: TipoOperadorJudicy.IGUAL };
    var filtro_tipo = { campo: 'tipo_documento', valor: tipo, operador_logico: TipoOperadorJudicy.IGUAL };

    var listafiltro = [];
    listafiltro.push(filtro_documento);
    listafiltro.push(filtro_tipo);

    var filtro_judicy = '{ "filtro":' + JSON.stringify(listafiltro) + '}';
    return busca_judicy('parte', filtro_judicy, judicy)
      .pipe(
        map((res: any) => { return res.conteudo.length == 0
                                 ? // * Quando não há erros:
                                 null
                                 : // * Quando houver erros: 
                                 { duplicado: true } }
        )
      );
  };
}


export function busca_judicy(entidade: string, filtro_judicy: string, judicy: JudicyService
) {
  return judicy.listar(
    entidade
    , 1
    , 1
    , ''
    , ''
    , filtro_judicy
  )
}