<mat-card>
  <div class="div-indicador">
    <mat-slide-toggle [(ngModel)]="indicador_ver_registros_escritorio" (change)="recarregarEventos()" color="primary">
      Vizualizar Escritório
    </mat-slide-toggle>

  </div>
  <div class='div-agenda'>



    <full-calendar 
    #calendario_html
    class="agenda" *ngIf='calendarioVisivel' [options]='opcoesCalendario'
    ></full-calendar>
  </div>
</mat-card>