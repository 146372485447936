<mat-card>
  <mat-card-title>
      <i class="material-icons">
        build
      </i>
      Parâmetros
  </mat-card-title>

  <mat-card-subtitle>
      Aqui você pode gerenciar todos os parametros cadastrados no Strom. 
  </mat-card-subtitle>

</mat-card>

<div class="divDataTable">
  <app-datatable ENTIDADE="lista" ID_PARENT=""></app-datatable>
</div>