<table>
  <tr>
    <td>


      <div class="group">
        <div class="div-login">

          <mat-toolbar class="cabecalho mat-elevation-z6">
            Bem Vindo!
          </mat-toolbar>

          <mat-card class="cardFormulario mat-elevation-z3">
            <form>

              <!-- <img class='logo' src="assets/img/logo horizontal.png" alt="logo"> -->
              <div>
                <mat-form-field appearance="outline">
                  <mat-label>Usuário</mat-label>
                  <input matInput placeholder='Usuário ' [(ngModel)]="usuarioLogado.login" required name="inputUsuario">
                  <mat-icon matSuffix>person</mat-icon>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Senha</mat-label>
                  <input matInput placeholder='Senha ' [(ngModel)]="usuarioLogado.senha" required name="inputSenha"
                    type="password">
                  <mat-icon matSuffix>lock_open</mat-icon>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Escritório</mat-label>
                  <input matInput placeholder='Escritório ' [(ngModel)]="dominioLogado.nome_dominio" required
                    name="inputDominio">
                  <mat-icon matSuffix>language</mat-icon>

                </mat-form-field>

                <mat-error>{{mensagem_login}}</mat-error>

              </div>

              <button mat-raised-button (click)="realizarLogin()" class="btn-login">
                <i class="material-icons">
                  check
                </i>
                Login
              </button>

            </form>
          </mat-card>

          <mat-card class="cardRodape mat-elevation-z3">
            <!-- <a>Esqueci minha senha </a> -->
            <a>-</a>
          </mat-card>
        </div>

        <div class="div-judicy">
          <img class='logo-judicy' src="assets/img/JudicySlogan.jpg" alt="logo">
        </div>
      </div>
    </td>
    <td class="linhalogo" valign="top">
    </td>
  </tr>
</table>