import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { TipoTexto } from "../tipos_de_dados/tipoTexto";
import { classeEntidadeDefault } from "./classeEntidadeDefault";
import { TipoUsuarioPerfil } from "../tipos_de_dados/tipoUsuarioPerfil";
import { TipoTelefone } from "../tipos_de_dados/tipoTelefone";
import { Condicao, domainAcao } from "src/app/domain/acao";
import { TipoUsuarioStatus } from "../tipos_de_dados/tipoUsuarioStatus";
import { domainUsuario } from "src/app/domain/usuario";
import { TipoSenha } from "../tipos_de_dados/tipoSenha";
import { TipoFoto } from "../tipos_de_dados/tipoFoto";
import { TipoTextoLongo } from "../tipos_de_dados/tipoTextoLongo";

@Injectable({
  providedIn: 'root'
})
export class classeEntidadeUsuario extends classeEntidadeDefault {

//construtor
  
  instanciar (): void {
    this.nome =  'usuario'
    this.nome_externo = 'Usuário'
    this.icone = 'account_circle'
    this.carregar_datatable()
    this.reconfigurar_botoes()

  }
  set_layout_alterar_cadastro(): void {
    this.reinicializa()
    this.layout = [
      {nome_campo: 'foto'            ,nome_descritivo:'Foto'           , tipo: new TipoFoto          ,obrigatorio:false, editavel:true , exibe_consulta: false, indicador_campo_parent: false, nome_parent:''        , validacao: [],condicao_exibicao:[]}
     ,{nome_campo: 'nome'            ,nome_descritivo:'Nome'           , tipo: new TipoTexto         ,obrigatorio:true, editavel:true , exibe_consulta: true , indicador_campo_parent: false, nome_parent:''        , validacao: [Validators.required],condicao_exibicao:[]}
     ,{nome_campo: 'email'           ,nome_descritivo:'E-Mail'         , tipo: new TipoTexto         ,obrigatorio:true, editavel:true , exibe_consulta: true , indicador_campo_parent: false, nome_parent:''        , validacao: [Validators.required],condicao_exibicao:[]}
     ,{nome_campo: 'telefone'        ,nome_descritivo:'Telefone'       , tipo: new TipoTelefone      ,obrigatorio:true, editavel:true , exibe_consulta: false, indicador_campo_parent: false, nome_parent:''        , validacao: [Validators.required],condicao_exibicao:[]}
     ,{nome_campo: 'biografia'       ,nome_descritivo:'Biografia'      , tipo: new TipoTextoLongo    ,obrigatorio:true, editavel:true , exibe_consulta: true , indicador_campo_parent: false, nome_parent:''        , validacao: [],condicao_exibicao:[]}
   ]
  }
  configurar_layout(): void {

    this.reinicializa()
    var socio = 'S'
    var condicao_alterar_perfil = {'campo':'perfil','valor':socio}

    this.layout = [
      {nome_campo: 'foto'            ,nome_descritivo:''           , tipo: new TipoFoto          ,obrigatorio:false, editavel:true , exibe_consulta: true, indicador_campo_parent: false, nome_parent:''        , validacao: [],condicao_exibicao:[]}
     ,{nome_campo: 'login'           ,nome_descritivo:'Login'          , tipo: new TipoTexto         ,obrigatorio:true, editavel:false, exibe_consulta: true , indicador_campo_parent: false, nome_parent:''        , validacao: [Validators.required],condicao_exibicao:[]}
     ,{nome_campo: 'nome'            ,nome_descritivo:'Nome'           , tipo: new TipoTexto         ,obrigatorio:true, editavel:true , exibe_consulta: true , indicador_campo_parent: false, nome_parent:''        , validacao: [Validators.required],condicao_exibicao:[]}
     ,{nome_campo: 'senha'           ,nome_descritivo:'Senha'          , tipo: new TipoSenha         ,obrigatorio:true, editavel:false, exibe_consulta: false, indicador_campo_parent: false, nome_parent:''        , validacao: [Validators.required],condicao_exibicao:[]}
     ,{nome_campo: 'perfil'          ,nome_descritivo:'Perfil'         , tipo: new TipoUsuarioPerfil ,obrigatorio:true, editavel:true , exibe_consulta: true , indicador_campo_parent: false, nome_parent:''        , validacao: [Validators.required],condicao_exibicao:[]}
     ,{nome_campo: 'email'           ,nome_descritivo:'E-Mail'         , tipo: new TipoTexto         ,obrigatorio:true, editavel:true , exibe_consulta: true , indicador_campo_parent: false, nome_parent:''        , validacao: [Validators.required],condicao_exibicao:[]}
     ,{nome_campo: 'telefone'        ,nome_descritivo:'Telefone'       , tipo: new TipoTelefone      ,obrigatorio:true, editavel:true , exibe_consulta: false, indicador_campo_parent: false, nome_parent:''        , validacao: [Validators.required],condicao_exibicao:[]}
     ,{nome_campo: 'status'          ,nome_descritivo:'Status'         , tipo: new TipoUsuarioStatus ,obrigatorio:true, editavel:false , exibe_consulta: true , indicador_campo_parent: false, nome_parent:''        , validacao: [Validators.required],condicao_exibicao:[]}
     ,{nome_campo: 'biografia'       ,nome_descritivo:'Biografia'      , tipo: new TipoTextoLongo    ,obrigatorio:true, editavel:true , exibe_consulta: false , indicador_campo_parent: false, nome_parent:''        , validacao: [],condicao_exibicao:[]}
   ]

   this.layout_resetar = [
    //{nome_campo: 'sua_senha'       ,nome_descritivo:'Sua Senha'          , tipo: new TipoTexto         ,obrigatorio:true, editavel:true, exibe_consulta: false, indicador_campo_parent: false, nome_parent:''        , validacao: [Validators.required]}
    {nome_campo: 'nova_senha'      ,nome_descritivo:'Nova Senha'     , tipo: new TipoSenha         ,obrigatorio:true, editavel:true, exibe_consulta: false, indicador_campo_parent: false, nome_parent:''        , validacao: [Validators.required],condicao_exibicao:[]}
   ,{nome_campo: 'confirmar_senha' ,nome_descritivo:'Confirmar Senha', tipo: new TipoSenha         ,obrigatorio:true, editavel:true, exibe_consulta: false, indicador_campo_parent: false, nome_parent:''        , validacao: [Validators.required],condicao_exibicao:[]}
   ]
   
   
  }

  reconfigurar_botoes() {    
    this.acoes_nivel_registro = []
    var condicao_bloquear : Condicao  = {}
    condicao_bloquear.campo = 'status'
    condicao_bloquear.valor = 'A'

    var condicao_desbloquear : Condicao  = {}
    condicao_desbloquear.campo = 'status'
    condicao_desbloquear.valor = 'B'

    var btn_bloquear : domainAcao = this.tipo_operacao.BLOQUEAR
    btn_bloquear.condicao = condicao_bloquear

    var btn_desbloquear : domainAcao = this.tipo_operacao.DESBLOQUEAR
    btn_desbloquear.condicao = condicao_desbloquear

    this.acoes_nivel_registro.push(btn_bloquear)
    this.acoes_nivel_registro.push(btn_desbloquear)
    this.acoes_nivel_registro.push(this.tipo_operacao.ALTERAR)
    this.acoes_nivel_registro.push(this.tipo_operacao.RESETAR)
  }


  formatar_detalhe_externo(registro: any): string {
    var usuario: domainUsuario = registro

    var formatado: string

    formatado = usuario.nome!


    return formatado

  }
    
}