import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class classeFonteDados implements DataSource<any> {

    public fonteSubject   = new BehaviorSubject<any[]>([]);
    public loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();
    
    constructor(
      ) {}

    connect(collectionViewer: CollectionViewer): Observable<any[]> {
      return this.fonteSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
      this.fonteSubject.complete();
      this.loadingSubject.complete();
    }

    converteJson(): any {
       return this.fonteSubject.getValue()
    }
    
    total_registros = 100;
}