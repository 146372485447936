import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { domainAcao, Operacao } from 'src/app/domain/acao';
import { domainRetornoApiJudicy } from 'src/app/domain/apiJudicy';
import { domainRelatorio } from 'src/app/domain/relatorio';
import { JudicyService } from 'src/app/servicos/judicy.service';
import { UtilidadesService } from 'src/app/servicos/utilidades.service';
import { Md5 } from 'ts-md5';
import { DatatableComponent } from '../datatable/datatable.component';
import { classeEntidade } from '../entidade/classeEntidade';
import { FormularioComponent } from '../formulario/formulario.component';
import { SessaoService } from 'src/app/servicos/sessao.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<DialogComponent>
    , private util: UtilidadesService
    , private judicy: JudicyService
    , private sessao: SessaoService
    , public datepipe: DatePipe
  ) { }

  @ViewChild(DatatableComponent) datatable: any;

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.entidade_seletor == undefined || this.datatable == undefined) {

    } else {
      this.datatable.carregar_datatable(this.entidade_seletor)
    }
  }

  get registro(): any {
    return this.judicy.registro
  }

  get operacao(): string {
    return this.judicy.acao.operacao!
  }

  get acao(): domainAcao {
    return this.judicy.acao
  }

  get entidade(): classeEntidade {
    return this.judicy.entidade
  }

  get entidade_seletor(): classeEntidade {
    return this.judicy.entidade.entidade_seletor
  }


  get registro_a_ser_excluido(): string {
    var retorno = JSON.stringify(this.registro)

    return retorno

  }

  confirmar_acao_dialog(acao: domainAcao) {
    this.judicy.acao = acao!
    this.formulario.instanciar()

  }

  verificaOcultarAcao(acao_nivel_dialog: domainAcao): boolean {
    var oculto = false

    if (this.acao.operacao == acao_nivel_dialog.operacao) {
      oculto = true
    }

    if (this.acao.operacao == 'incluir') {
      oculto = true
    }

    if (this.judicy.registro['status'] == 'C') {
      oculto = true
    }

    return oculto

  }

  confirmar() {
    if (this.operacao == Operacao.ALTERAR) {

      if (this.formulario.preechimento_formulario_ok()) {
        this.alterar()
      }
    }

    if (this.operacao == Operacao.INCLUIR) {
      if (this.formulario.preechimento_formulario_ok()) {
        this.incluir()
      }
    }

    if (this.operacao == Operacao.INCLUIR_VIA_SELETOR) {
      this.incluir_via_seletor()
    }

    if (this.operacao == Operacao.EXCLUIR) {
      this.excluir()
    }

    if (this.operacao == Operacao.BLOQUEAR) {
      this.registro['status'] = 'B'
      this.alterar()
    }

    if (this.operacao == Operacao.DESBLOQUEAR) {
      this.registro['status'] = 'A'
      this.alterar()
    }

    if (this.operacao == Operacao.CANCELAR) {
      this.registro['status'] = 'C'
      this.alterar()
    }

    if (this.operacao == Operacao.CONCLUIR) {
      if (this.entidade.nome == 'audiencia') {
        this.registro['status'] = 'R'

      } else {
        if (this.registro['solucao'] != '' && this.registro['solucao'] != undefined) {
          const agora = new Date();
          this.registro['status'] = 'C'
          this.registro['data_conclusao'] = agora
        }
      }

      this.alterar()
    }

    if (this.operacao == Operacao.RESETAR) {
      const md5 = new Md5();
      if (this.registro['nova_senha'] != undefined && this.registro['nova_senha'] == this.registro['confirmar_senha']) {
        var senha_criptografada = md5.appendStr(this.registro['nova_senha']).end()
        this.registro['senha'] = senha_criptografada.toString()

        this.alterar()
      }
    }

    if (this.operacao == Operacao.RECARREGAR) {
      
      if (this.formulario.preechimento_formulario_ok()) {

        this.recarregar()
      }
    }

  }

  recarregar() {
    let data = this.datepipe.transform(this.registro['data'], 'yyyy-MM-dd')

    var servico = 'recarregar_publicacao'
    if (data != undefined) {
      this.judicy.recarregar(data, servico)
        .subscribe(resposta => { this.trata_retorno(resposta); }
          , (error: any) => {
            this.util.exibirMensagem(this.util.catalogo.msg_erro_recarga(), this.util.tp_msg.erro());
          }
        )
    }
  }


  incluir() {
    //if (this.formulario.preechimento_formulario_ok()) {
    this.judicy.incluir(this.entidade.nome, this.registro)
      .subscribe(resposta => {
        this.judicy.registro = resposta['conteudo']

        this.trata_retorno(resposta);
      }
        , (error: any) => {
          this.util.exibirMensagem(this.util.catalogo.msg_erro_inclusao(), this.util.tp_msg.erro());
          //console.log('Erro', error);                                                              
        }
      )
    //}
  }

  get registro_selecionado(): any {
    if (this.datatable == undefined) {
      return undefined
    } else {
      // console.log('this.datatable.registro_selecionado = ' + JSON.stringify(this.datatable.registro_selecionado))
      return this.datatable.registro_selecionado
    }
  }

  incluir_via_seletor() {
    //console.log('this.datatable.registro_selecionado = ' + JSON.stringify(this.datatable.registro_selecionado))
    var reg = this.datatable.registro_selecionado
    if (reg = !undefined) {
      this.judicy.registro[this.acao.entidade_seletor!] = this.datatable.registro_selecionado
      this.judicy.incluir(this.entidade.nome, this.registro)
        .subscribe(resposta => { this.trata_retorno(resposta); }
          , (error: any) => {
            this.util.exibirMensagem(this.util.catalogo.msg_erro_inclusao(), this.util.tp_msg.erro());
            //console.log('Erro', error);                                                              
          }
        )
    }
  }

  // -------------------------------------- ALTERAR //                                                 
  alterar() {
    //if (this.formulario.preechimento_formulario_ok()) {

    this.judicy.alterar(this.entidade.nome, this.registro, this.registro['id_' + this.entidade.nome])
      .subscribe(
        (resposta: domainRetornoApiJudicy) => { this.trata_retorno(resposta) }
        , (error: any) => {
          this.util.exibirMensagem(this.util.catalogo.msg_erro_alteracao(), this.util.tp_msg.erro());
          //console.log('Erro', error);                                                              
        }
      );
    //}
  }

  excluir() {
    this.judicy.excluir(this.entidade.nome, this.registro['id_' + this.entidade.nome]).subscribe(
      resposta => { this.trata_retorno(resposta) }
      , (error: any) => {
        this.util.exibirMensagem(this.util.catalogo.msg_erro_exclusao(), this.util.tp_msg.erro());
        //console.log('Erro', error);
      }
    );
  }

  downloadRelatorio(v_formato: string, v_relatorio: domainRelatorio) {
    var id_registro = this.registro['id_' + this.entidade.nome]
    console.log('filtro----- ' + this.judicy.filtro)
    var filtro: string = this.judicy.filtro

    if (v_relatorio.nivel == 'tabela') {
      id_registro = undefined
    } else {
      filtro = ''
    }

    this.judicy.downloadRelatorio(
        v_formato
      , v_relatorio.nome!
      , id_registro
      , filtro
    )
      .subscribe(resposta => { this.trata_retorno_download(resposta, v_formato); }
        , (error: any) => {
          this.util.exibirMensagem(this.util.catalogo.msg_erro_download(), this.util.tp_msg.erro());
          //console.log('Erro', error);   
        }
      )
  }

  trata_retorno_download(p_ret: domainRetornoApiJudicy, p_formato: string) {
    if (p_ret.status == 0) {
      this.util.downLoadArquivo(p_ret.conteudo['arquivo_download_base64']
        , p_formato
        , p_ret.conteudo['arquivo_download_nome']
      )

      this.util.exibirMensagem(this.util.catalogo.msg_sucesso_download(), this.util.tp_msg.sucesso());
    }
    else {
      this.util.exibirMensagem(p_ret.mensagem!, this.util.tp_msg.erro());
      //console.log('Erro', retornoJudicy.mensagem); 
    }
  }

  // -------------------------------------- VALIDA O RETORNO DO SERVICO DE ALTERACAO //
  atualiza_usuario_logado(): void {
    if (this.judicy.usuarioLogado.id_usuario == this.judicy.registro['id_usuario']) {
      this.sessao.set_usuario_logado(this.judicy.registro)
    }
  }
  trata_retorno(p_ret: domainRetornoApiJudicy) {
    if (p_ret.status == 0) {
      this.util.exibirMensagem('Operação realizada com sucesso!', this.util.tp_msg.sucesso());
      this.atualiza_usuario_logado()
      this.dialogRef.close();
    }
    else {
      this.util.exibirMensagem(p_ret.mensagem!, this.util.tp_msg.erro());
      //console.log('Erro', retornoJudicy.mensagem);                                                    
    }
  }

  // -------------------------------------- CANCELAR //                                                
  cancelar() {
    this.dialogRef.close();
  }


  @ViewChild(FormularioComponent, { static: false })
  formulario!: FormularioComponent;

}
