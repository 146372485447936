import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { domainDominio } from 'src/app/domain/dominio';
import { domainUsuario } from 'src/app/domain/usuario';
import { SessaoService } from 'src/app/servicos/sessao.service';

@Component({
  selector: 'app-login-pagina',
  templateUrl: './login-pagina.component.html',
  styleUrls: ['./login-pagina.component.css']
})
export class LoginPaginaComponent implements OnInit {

  constructor(
    private sessaoServico: SessaoService
   ,private rotaURL: Router

  ) { }

  ngOnInit(): void {
    if (this.sessaoServico.getFlagUsuarioLogado()) {
      this.rotaURL.navigate(['pgHome']);
    } else {
      this.sessaoServico.realizarLout()
    }
  }

  get usuarioLogado(): domainUsuario {
    return this.sessaoServico.usuarioLogado
  }

  get dominioLogado(): domainDominio {
    return this.sessaoServico.dominioLogado
  }

  get mensagem_login(): string {
    return this.sessaoServico.mensagem_login
  }

  realizarLogin() {
    this.sessaoServico.realizarLogin();

  }

  dominioInvalido(): boolean {
    var retorno:boolean = false;
    if (this.dominioLogado.nome_dominio == undefined || this.dominioLogado.nome_dominio == '')   {
      retorno =  true;

    } 
    return retorno   
  }

}
