import { TipoDados } from "./tipoDados";

export interface itemLista {
  codigo?: unknown
  valor?: string
}

export class TipoLista extends TipoDados {
  
  lista: Array<itemLista> = []

  codigo_tipo = 'lista'

  formatar_para_exibir_em_tela(codigo: string): string {
    
    if (codigo == null || codigo == undefined) {
      codigo = ''
    }

    var retorno = codigo

    for (var item_lista of this.lista) {

      if (item_lista.codigo == codigo) {
        retorno = item_lista.valor!
      }
    }
    
    return retorno
  }

  adicionar_item(coidgo: unknown, valor: string) : void {
    var item : itemLista = {}
    item.codigo = coidgo
    item.valor = valor
 
    this.lista.push(item)
  }

  get_lista(): Array<itemLista> {
    return this.lista
  }

}