import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { domainTarefa } from "src/app/domain/tarefa";
import { TipoData } from "../tipos_de_dados/tipoData";
import { TipoTarefaStatus } from "../tipos_de_dados/tipoTarefaStatus";
import { TipoTarefaTipo } from "../tipos_de_dados/tipoTarefaTipo";
import { TipoTextoLongo } from "../tipos_de_dados/tipoTextoLongo";
import { classeEntidadeDefault } from "./classeEntidadeDefault";
import { Condicao, Condicoes } from "src/app/domain/acao";
import { valida_data_inicio_e_fim } from "src/app/validadores/validador_data_inicio_e_fim";

@Injectable({
  providedIn: 'root'
})
export class classeEntidadeTarefa extends classeEntidadeDefault {

//construtor
  
  instanciar (): void {
    this.nome =  'tarefa'
    this.nome_externo = 'Tarefa'
    this.icone = 'task'
    this.carregar_datatable()
    this.recarregar_botoes()
  }
  
  configurar_layout(): void {   
    this.reinicializa()
    this.incluir_campo('data_inicio', 'Data Inicio', new TipoData        , true , true , true, false, '', [Validators.required])
    this.incluir_campo('data_fim'   , 'Data Fim'   , new TipoData        , true , true , true, false, '', [Validators.required, valida_data_inicio_e_fim('data_inicio')])
    this.incluir_campo('data_conclusao', 'Data Conclusão', new TipoData  , true, false, true, false, '', undefined, false)
    this.incluir_campo('tipo'       , 'Tipo'       , new TipoTarefaTipo  , true , true , true, false, '', [Validators.required])
    this.incluir_campo('responsavel', 'Responsável', this.lista.usuario  , true , true , true, false, '', [Validators.required])
    this.incluir_campo('descricao'  , 'Descrição'  , new TipoTextoLongo  , true , true , true, false, '', [Validators.required])
    this.incluir_campo('status'     , 'Status'     , new TipoTarefaStatus, true , false , true, false, '', [Validators.required], false)
    this.incluir_campo('solucao'    , 'Solução'    , new TipoTextoLongo  , false, false, true, false, '', [Validators.required], false)
    this.incluir_campo('usuario'    , 'Criador'    , this.lista.usuario  , false, false, true, false, '', [Validators.required], false)

    this.layout_resetar = [
      {nome_campo: 'solucao'      ,nome_descritivo:'Solução'     , tipo: new TipoTextoLongo         ,obrigatorio:true, editavel:true, exibe_consulta: false, indicador_campo_parent: false, nome_parent:''        , validacao: [Validators.required]}
     ]
  }

  recarregar_botoes() {
    this.acoes_nivel_registro = []

    this.tipo_operacao.CONCLUIR.executada_apenas_por_criador = true
    this.tipo_operacao.ALTERAR.executada_apenas_por_criador = true
    this.tipo_operacao.EXCLUIR.executada_apenas_por_criador = true

    var prazo_concluido : Condicao  = {}
    prazo_concluido.campo = 'status'
    prazo_concluido.valor = 'C'
    prazo_concluido.tipo = true

    var cond: Condicoes = {}
    cond.condicoes = [prazo_concluido]

    this.tipo_operacao.CONCLUIR.condicao_desabilitar = cond
    this.tipo_operacao.ALTERAR.condicao_desabilitar = cond
    this.tipo_operacao.EXCLUIR.condicao_desabilitar = cond

    this.acoes_nivel_registro.push(this.tipo_operacao.CONCLUIR)
    this.acoes_nivel_registro.push(this.tipo_operacao.ALTERAR)
    this.acoes_nivel_registro.push(this.tipo_operacao.EXCLUIR)


    this.acoes_nivel_dialog.push(this.tipo_operacao.CONCLUIR)

  }
    
  formatar_detalhe_externo(registro: any): string {
    var tarefa: domainTarefa = registro

    var formatado: string

    formatado = tarefa.descricao!

    return formatado

  }
}