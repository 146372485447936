import { Component, OnInit } from '@angular/core';
import { SessaoService } from 'src/app/servicos/sessao.service';
import { NavegacaoServicoService } from './navegacao-servico.service';

@Component({
  selector: 'app-navegacao',
  templateUrl: './navegacao.component.html',
  styleUrls: ['./navegacao.component.css']
})
export class NavegacaoComponent implements OnInit {
  vgMenu = 'Home';

  estiloNav = "sidenav-fechado";

  ajusta_expansao_nav ():void {
     if (this.estiloNav == "sidenav-aberto") {
      this.estiloNav = "sidenav-fechado"
     } else {
      this.estiloNav = "sidenav-aberto"
     }
  }

  expande (): void {
    this.estiloNav = "sidenav-aberto"
  }
  
  reduz (): void {
    this.estiloNav = "sidenav-fechado"
  }

  menu = {
    itens  : [
             {
              texto  : 'Agenda',
              icone  : 'chrome_reader_mode',
              rotaURL: 'pgAgenda',
              tipo   : 'home'  
             },
             {
              texto  : 'Tarefa',
              icone  : 'task',
              rotaURL: 'pgTarefa',
              tipo   : 'home'  
             },

             {
              texto  : 'Incluir Parte',
              icone  : 'person_add_alt',
              rotaURL: 'pgParteIncluir',
              tipo   : 'parte'  
             },
             {
              texto  : 'Consulta Parte',
              icone  : 'person_search',
              rotaURL: 'pgParteConsulta',
              tipo   : 'parte'  
             },

             {
              texto  : 'Incluir Processo',
              icone  : 'post_add',
              rotaURL: 'pgProcessoIncluir',
              tipo   : 'processo'  
             },
             {
              texto  : 'Consulta Processo',
              icone  : 'manage_search',
              rotaURL: 'pgProcessoConsulta',
              tipo   : 'processo'  
             },
             
             {
              texto  : 'Audiência',
              icone  : 'gavel',
              rotaURL: 'pgAudiencia',
              tipo   : 'processo'  
             },
             {
              texto  : 'Prazo',
              icone  : 'date_range',
              rotaURL: 'pgPrazo',
              tipo   : 'processo'  
             },
             {
              texto  : 'Publicação',
              icone  : 'my_library_books',
              rotaURL: 'pgPublicacao',
              tipo   : 'processo'  
             },

             {
              texto  : 'Usuário',
              icone  : 'account_circle',
              rotaURL: 'pgUsuario',
              tipo   : 'gerencial'  
             },

             {
              texto  : 'Fórum',
              icone  : 'apartment',
              rotaURL: 'pgForum',
              tipo   : 'gerencial'  
             },

             {
              texto  : 'Histórico Operações',
              icone  : 'history',
              rotaURL: 'pgHistorico',
              tipo   : 'gerencial'  
             },

             {
              texto  : 'Parâmetros',
              icone  : 'build',
              rotaURL: 'pgParametro',
              tipo   : 'gerencial'  
             },

             {
              texto  : 'Alertas',
              icone  : 'mail',
              rotaURL: 'pgControleAlerta',
              tipo   : 'gerencial'  
             }

            ]
  }
  
  get flagUsuarioLogado(): boolean {
    return this.sessaoServico.getFlagUsuarioLogado()

  }

  constructor(private vNavegacaoServico:NavegacaoServicoService
             ,private sessaoServico : SessaoService
    ) { }
  
  ngOnInit(): void {
  }
  
  getEstiloContainer(): string {

    if (this.flagUsuarioLogado) {
      return 'container'
    } else {
      return 'containerLogin'
    }
  }

  get tipoMenu(): string {
    return this.vNavegacaoServico.tipoMenu
  }
  
}
