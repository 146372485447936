import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavegacaoServicoService {

  private _tipoMenu = new BehaviorSubject<string>(
    'home'
    )
  
  constructor() { }

  get tipoMenu(): string {
    return this._tipoMenu.value
  }

  set tipoMenu(tipoMenu: string) {
    this._tipoMenu.next(tipoMenu)
  }


}
