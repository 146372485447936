<mat-card class="calendario">
  <mat-card-title>
    <i class="material-icons">
      manage_search
    </i>
    Consulta de Processos
  </mat-card-title>


  <mat-card-subtitle>
    Aqui você pode consultar todos processos cadastrados.

  </mat-card-subtitle>

</mat-card>

<div class="divDataTable">
  <app-datatable ENTIDADE="processo" ID_PARENT=""></app-datatable>
</div>