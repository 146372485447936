import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Condicao, Operacao } from 'src/app/domain/acao';
import { domainRetornoApiJudicy } from 'src/app/domain/apiJudicy';
import { domainCampo, } from 'src/app/domain/entidade';
import { domainProcesso } from 'src/app/domain/processo';
import { domainViaCep } from 'src/app/domain/viaCep';
import { JudicyService } from 'src/app/servicos/judicy.service';
import { UtilidadesService } from 'src/app/servicos/utilidades.service';
import { classeFonteDados } from '../fonte-dados';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { DialogImagemComponent } from '../dialog-imagem/dialog-imagem.component';
import { DialogComponent } from '../dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class FormularioComponent implements OnInit {
  public mask = {
    guide: true,
    showMask: true,
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
  };

  estiloPrimary: string = 'primary';

  get registro(): any {
    return this.judicy.registro
  }

  constructor(
    private util: UtilidadesService
    , private dialog: MatDialog
    , private judicy: JudicyService
    , private conexaoHttp: HttpClient
    , private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
  ) {}

  brasil() {
    this._locale = 'pt-BR';
    this._adapter.setLocale(this._locale);
  }

  
  exibir() {
    return JSON.stringify(this.registro)
  }

  @ViewChild(DialogImagemComponent, { static: false })
  caixa_dialogo!: DialogImagemComponent;

  selecionar_foto() {
    var caixaDialogo = this.dialog.open(DialogImagemComponent);
    this.dialog.afterAllClosed.subscribe(data => this.set_foto())
  }

  set_foto() {
    // this.registro[campo.nome_campo] = imagemEmBase64
  }

  campos_formulario: Array<domainCampo> = []
  campos_formulario_longo: Array<domainCampo> = []
  campos_formulario_link: Array<domainCampo> = []

  layout: Array<domainCampo> = []

  instanciar(): void {
    this.brasil()
    var lista_operacoes: Array<string> = []
    this.campos_formulario_link = []
    this.campos_formulario_longo = []
    this.campos_formulario = []

    lista_operacoes.push(Operacao.RESETAR)
    lista_operacoes.push(Operacao.RECARREGAR)
    lista_operacoes.push(Operacao.CONCLUIR)

    if (lista_operacoes.includes(this.judicy.acao.operacao!)) {
      this.layout = this.judicy.entidade.layout_resetar
    } else {
      this.layout = this.judicy.entidade.layout

    }
    this.carregar_formulario()
  }
  ngOnInit(): void {
    // console.log('this.judicy.operacao = ' + this.judicy.operacao)
    // console.log('this.judicy.entidade.layout ' + JSON.stringify(this.judicy.entidade.layout))
    this.instanciar()

  }
  
  urlFoto = ""

  selecaoFoto(evento: any, campo: domainCampo) {
    const lista_arquivos = <FileList>evento.srcElement.files
    const foto = lista_arquivos[0]

    var leitor = new FileReader();
    leitor.readAsDataURL(foto)

    leitor.onloadend=(evento:any)=> {
      const imagemEmBase64 = leitor.result as string;
      this.registro[campo.nome_campo] = imagemEmBase64

      this.urlFoto=evento.target.result
    }

  }

  verifica_condicoes_exibicao(registro: any, condicoes_exibicao: Array<Condicao>): boolean {
    var retorno = true

    if (condicoes_exibicao != undefined) {
      for (var condicao of condicoes_exibicao) {
        if (registro[condicao!.campo!] == condicao!.valor || condicao!.campo == '') {

        } else {
          retorno = false

        }

      }
    }

    return retorno
  }

  flag = 0
  campo: any = {}

  carregar_formulario(): void {
    this.flag = 0
    for (var campo of this.layout) {
      //console.log(JSON.stringify(campo))
      this.campo = campo

      if (this.deve_apresentar()) {
        this.carrega_link()
        this.carrega_texto_longo()
        this.carrega_demais_tipos()
        this.carrega_form_group()
      }
    }
  }

  deve_apresentar(): boolean {
    if (this.campo.editavel == false && this.judicy.acao.operacao == Operacao.ALTERAR && this.campo.tipo.codigo_tipo != 'link') {
      return false
    }

    if (this.campo.cadastravel == false && this.judicy.acao.operacao == Operacao.INCLUIR) {
      return false
    }

    return true

  }


  carrega_link(): void {
    if (this.campo.tipo?.codigo_tipo == 'link' && this.judicy.acao.operacao == Operacao.ALTERAR) {
      this.campos_formulario_link.push(this.campo)
    }
  }

  carrega_texto_longo(): void {
    if (this.campo.tipo?.codigo_tipo == 'textoLongo') {
      this.campos_formulario_longo.push(this.campo)
    }
  }

  carrega_demais_tipos(): void {
    if (this.campo.tipo?.codigo_tipo == 'autocompleteprocesso' && this.flag == 0) {
      this.flag = 1
      this.carregarProcesso();
    }
    //console.log('push = ' + JSON.stringify(campo))
    this.campos_formulario.push(this.campo)
  }

  nome_campo_controle = ''
  valor_inicial: any

  carrega_form_group(): void {
    this.identifica_campo_e_valor_inicial()
    this.adicionar_campo_no_form()
    this.adicionar_validacoes_do_campo()
  }

  identifica_campo_e_valor_inicial(): void {
    if (this.campo.indicador_campo_parent) {
      this.valor_inicial = this.registro[this.campo.nome_parent!][this.campo.nome_campo]
      this.nome_campo_controle = this.campo.nome_parent + this.campo.nome_campo

    } else {
      this.valor_inicial = this.registro[this.campo.nome_campo]
      this.nome_campo_controle = this.campo.nome_campo
    }
    this.converte_valor_ininicial_para_string_se_number()
  }

  converte_valor_ininicial_para_string_se_number(): void {
    if (typeof (this.valor_inicial) == 'number') {
      this.valor_inicial = this.valor_inicial.toString()
    }
  }

  adicionar_campo_no_form(): void {
    this.formulario.addControl(this.nome_campo_controle, new FormControl(this.valor_inicial))
  }

  adicionar_validacoes_do_campo(): void {
    if (this.campo.validacao != undefined) {
      // if (this.nome_campo_controle == 'forumid_forum') {
      //    console.log(' validacao = ' + this.campo.validacao)
      //  }
      this.formulario.controls[this.nome_campo_controle].setValidators(this.campo.validacao)
      this.formulario.controls[this.nome_campo_controle].setAsyncValidators(this.campo.validacao_assincrona)

      this.formulario.controls[this.nome_campo_controle].statusChanges.subscribe(async (status) => {
        if (status == 'INVALID') {
          this.blur_campo()
        }
      });
    }
  }


  formulario = new FormGroup({}, { updateOn: "blur" });
  mensagem_erro = ''


  preechimento_formulario_ok(): boolean {
    var formulario_valido = true
    var ix = 0

    // this.formulario.setValidators(this.judicy.entidade.validacao_formulario)
    // this.formulario.setAsyncValidators(this.judicy.entidade.validacao_assincrona_formulario)

    for (var campo of this.campos_formulario) {
      if (this.preenchimento_campo_ok(campo, ix)) {
        // console.log('Vallidando  campo ' + campo.nome_campo)

      } else {
         console.log('invalidando pelo campo ' + campo.nome_campo)
        formulario_valido = false
      }
      ix = ix + 1
    }

    return formulario_valido
  }

  validacoes = [
      { 'nome': 'duplicado', 'msg': 'Já Cadastrado' }
    , { 'nome': 'obrigatorio', 'msg': 'Obrigatório' }
    , { 'nome': 'tamanho', 'msg': 'Tamanho Inválido' }
    , { 'nome': 'digito', 'msg': 'Dígito Inválido' }
    , { 'nome': 'required', 'msg': 'Campo Obrigatório' }
    , { 'nome': 'requerido', 'msg': 'Campo Obrigatório' }
    , { 'nome': 'data_fim_menor_que_inicio', 'msg': 'Data Fim deve ser posterior ao Inicio' }
    , { 'nome': 'data_futura', 'msg': 'Data não pode ser Futura' }
  ]

  preenchimento_campo_ok(campo: domainCampo, ix: number): boolean {
    var campo_valido: boolean = true;

    var campo_formulario = campo.nome_campo

    if (campo.indicador_campo_parent) {
      campo_formulario = campo.nome_parent + campo.nome_campo
    }
    //  console.log('INICIANDO VALIDACOES')
    //  console.log('campo = ' + campo_formulario)

    // this.formulario.controls[campo_formulario].updateValueAndValidity();

    // if (campo_formulario == 'email') {
    //   console.log('this.formulario.controls[campo_formulario] = ' + this.formulario.controls[campo_formulario].value)
    //   console.log('erros = ' + this.formulario.controls[campo_formulario].errors)
    //   console.log('d = ' + this.formulario.controls[campo_formulario].hasError('duplicado'))
    // }

    this.validacoes.forEach(val => {
        // if (campo_formulario == 'forumid_forum') {
          // console.log('campo = ' + campo_formulario)
          // console.log('msg = ' + val.msg)
          // console.log('nome = ' + val.nome)
          // console.log('campo_valido = ' + campo_valido)
          // console.log('campo2 = ' + this.campos_formulario[ix].nome_campo)
        // }
      if (this.formulario.controls[campo_formulario].hasError(val.nome) && campo_valido) {
        // console.log('invalidando = ' + campo_formulario)
        if (val.nome == 'duplicado' && this.judicy.acao.operacao == Operacao.ALTERAR) {
          // console.log('SIM')
          this.campos_formulario[ix].mensagem = ''
          this.formulario.controls[campo_formulario].setErrors(null)
          campo_valido = true;
        } else {
          // console.log('Retornando ERRO')
          this.campos_formulario[ix].mensagem = val.msg
          campo_valido = false;
        }
      }
    })

    this.formulario.controls[campo_formulario].markAsTouched()

    return campo_valido;
  }

  blur_campo(campo?: domainCampo) {
    if (campo != undefined) {
      this.campo = campo
    }
    var campo_formulario = this.campo.nome_campo

    if (this.campo.indicador_campo_parent) {
      campo_formulario = this.campo.nome_parent + this.campo.nome_campo
    }

    this.validacoes.forEach(val => {
      if (this.formulario.controls[campo_formulario].hasError(val.nome)) {



        this.campos_formulario.forEach(cp => {
          if (cp.nome_campo == this.campo.nome_campo) {
            // console.log('cp.nome_campo = ' + cp.nome_campo)
            // console.log('campo.nome_campo = ' + this.campo.nome_campo)
              if (val.nome == 'duplicado' && this.judicy.acao.operacao == Operacao.ALTERAR) {
              cp.mensagem = ''
              this.formulario.controls[campo_formulario].setErrors(null)
            } else {
              cp.mensagem = val.msg
            }
          }
        })

      }
    })

  }

  // ESPECIFICOS DO CEP

  buscaCEP(): void {
    var msgErro: string;
    var url: string;

    if (this.registro['cep'] == undefined) {

    } else {
      url = 'https://viacep.com.br/ws/' + this.registro['cep'] + '/json/';

      this.conexaoHttp.get(url)
        .subscribe(
          (data: domainViaCep) => {
            this.atualizaEnderecoPeloCEP(data);
          }
          , (error: any) => {
            msgErro = error;
            console.log('Erro', error);
          }
        );

    }
  }

  atualizaEnderecoPeloCEP(enderecoViaCep: domainViaCep): void {
    this.registro['cidade'] = enderecoViaCep.localidade;
    this.registro['bairro'] = enderecoViaCep.bairro;
    this.registro['uf'] = enderecoViaCep.uf;
    this.registro['logradouro'] = enderecoViaCep.logradouro;

  }

  // ESPECIFICOS DO PROCESSO

  exibir_valores(processo: domainProcesso): string {
    return processo && processo.numero_processo ? processo.numero_processo : '';
  }

  selecionar_apenso(processo: domainProcesso) {
    this.judicy.registro['processo_apenso'] = processo

    // if (processo.numero_processo != undefined) {
    //   this.apensoValidador.form.controls['flag_processo_selecionado'].setValue(true)
    // }
  }

  listaProcesso: Array<domainProcesso> = []

  pagina = 0
  tamanho = 30
  campo_ordenacao = 'numero_processo'
  direcao_ordenacao = 'asc'
  filtro: domainProcesso = { forum: {} }
  fonteDados = new classeFonteDados();

  prefixo_numero_processo: string = ''

  carregarProcesso(): void {
    this.filtro.numero_processo = this.prefixo_numero_processo

    this.fonteDados.loadingSubject.next(true);

    this.judicy.listar('processo',
      this.pagina + 1
      , this.tamanho
      , this.campo_ordenacao
      , this.direcao_ordenacao
      , this.util.formataParametrosFiltro(this.filtro)
    ).pipe(finalize(() => this.fonteDados.loadingSubject.next(false))
    ).subscribe((resposta: any) => {
      var retornoJudicy: domainRetornoApiJudicy = resposta;

      if (retornoJudicy.status == 0) {
        this.fonteDados.total_registros = resposta.total_registros
        this.fonteDados.fonteSubject.next(resposta.conteudo)
      }

      else {
        this.util.exibirMensagem(retornoJudicy.mensagem!, this.util.tp_msg.erro());
      }

    }
    ), (error: any) => {
      this.util.exibirMensagem(this.util.catalogo.msg_erro_listagem(), this.util.tp_msg.erro());
      console.log('Erro', error);
    }
      ;
  }

  exibir_campo(linha: any, coluna: domainCampo): string {
    if (coluna.nome_parent && coluna.nome_parent != undefined && coluna.nome_parent != '') {
      return coluna.tipo!.formatar_para_exibir_em_tela(linha[coluna.nome_parent][coluna.nome_campo])

    } else {
      return coluna.tipo!.formatar_para_exibir_em_tela(linha[coluna.nome_campo])
    }
  }
}

function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}