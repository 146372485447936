import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { classeCatalogo, classeTipoMensagem } from 'src/app/classe/mensagens';
import { autenticacaoApiJudicy, domainRetornoApiJudicy, urlApiJudicy } from 'src/app/domain/apiJudicy';
import { domainConsultaEnderecoIP } from 'src/app/domain/consultaEnderecoIP';
import { domainDominio } from 'src/app/domain/dominio';
import { domainUsuario } from 'src/app/domain/usuario';
import { UtilidadesService } from 'src/app/servicos/utilidades.service';
import { enderecoIPService } from './enderecoIP.service';
import { Md5 } from 'ts-md5';

@Injectable({
  providedIn: 'root'
})
export class SessaoService {

  //dadosSessao!: Storage;

  flagUsuarioLogado: boolean = false;

  set_usuario_logado(usuario: any) {
    this.usuarioLogado = usuario
    sessionStorage.setItem('usuarioLogado', JSON.stringify(usuario))
  }

  usuarioLogado: domainUsuario = {
    dominio: {}
  }

  dominioLogado: domainDominio = {

  }

  mensagem_login: string = ''

  catalogo: classeCatalogo = new (classeCatalogo);
  tp_msg: classeTipoMensagem = new (classeTipoMensagem);

  constructor(private util: UtilidadesService
    , private rotaURL: Router
    , private conexaoHttp: HttpClient
    , private servicoEnderecoIP: enderecoIPService

  ) { 
    //sessionStorage = window.localStorage;
   }

  retornoJudicy!: domainRetornoApiJudicy;
  retornoIP!: domainConsultaEnderecoIP ;
  senha_login  = ''

  realizarLogin(): void {
    const md5 = new Md5();
    var senha: string = ''

    if (this.usuarioLogado.senha != undefined) {
       senha = this.usuarioLogado.senha
    }
    
    var v_senha_md5: string = ''
    v_senha_md5 = md5.appendStr(senha).end().toString()

    this.senha_login = v_senha_md5

  // this.servicoEnderecoIP.executaConsultaPorEnderecoIP().subscribe
  // ((resposta: any) => {
  //   this.retornoIP = resposta;
  //   this.efetivarLogin();
  //   })
    var force = {'country_code': '' 
            ,'state ': ''
            ,'city' : ''
            ,'IPv4' : ''
    }

    this.retornoIP = force

    this.efetivarLogin();

  }
  
  efetivarLogin(): void {
    this.mensagem_login = '';

    if (this.dominioLogado.nome_dominio == '' || this.dominioLogado.nome_dominio == undefined) {
      this.mensagem_login = 'Campo domínio Obrigatório'
    }

    if (this.usuarioLogado.senha == '' || this.usuarioLogado.senha == undefined) {
      this.mensagem_login = 'Campo senha Obrigatório'
    }

    if (this.usuarioLogado.login == '' || this.usuarioLogado.login == undefined) {
      this.mensagem_login = 'Campo usuário Obrigatório'
    }

    if (this.mensagem_login == '') {
      this.conexaoHttp.get<any>(urlApiJudicy + '/logar'
        + '?'
        + 'requerente=' + autenticacaoApiJudicy.requerente
        + '&'
        + 'chave=' + autenticacaoApiJudicy.chave
        + '&'
        + 'dominio=' + this.dominioLogado.nome_dominio
        + '&'
        + 'login=' + this.usuarioLogado.login
        + '&'
        + 'senha=' + this.senha_login
        + '&'
        + 'endereco_ip=' + this.retornoIP.country_code 
        + ' - ' + this.retornoIP.state 
        + ' - ' + this.retornoIP.city
        + ' (' + this.retornoIP.IPv4 + ')'
        
                                          , )
        .subscribe(
          resposta => {
            this.retornoJudicy = resposta;

            if (this.retornoJudicy.status == 0) {
              sessionStorage.setItem('usuarioLogado', JSON.stringify(this.retornoJudicy.conteudo))
              sessionStorage.setItem('flagUsuarioLogado', 'S')
              this.rotaURL.navigate(['pgHome']);
              this.util.exibirMensagem('Login realizado com Sucesso!', 'SUCESSO');
              //console.log(JSON.stringify(resposta));

              this.util.exibirMensagem(this.catalogo.msg_sucesso_login(), this.tp_msg.sucesso());
            }
            else {
              sessionStorage.setItem('flagUsuarioLogado', 'N')
              this.mensagem_login = this.retornoJudicy.mensagem!;
              this.util.exibirMensagem(this.retornoJudicy.mensagem!, this.tp_msg.erro());
              //console.log('Erro', this.retornoJudicy.mensagem);

            }

          }

          , (error: any) => {
            sessionStorage.setItem('flagUsuarioLogado', 'N')
            this.mensagem_login = this.catalogo.msg_erro_login();
            this.util.exibirMensagem(this.catalogo.msg_erro_login(), this.tp_msg.erro());
            //console.log('Erro', error);
          }
        );
    }

  }

  realizarLout(): void {
    sessionStorage.setItem('flagUsuarioLogado', 'N')
    sessionStorage.setItem('usuarioLogado', '{}')
    this.rotaURL.navigate(['pgLogin']);
    this.mensagem_login = '';
  }

  getFlagUsuarioLogado(): boolean {
    if (sessionStorage.getItem('flagUsuarioLogado') == 'S') {
      return true
    } else {
      return false
    }
  }

  getUsuarioLogado(): domainUsuario {
    var userLogado = sessionStorage.getItem('usuarioLogado')
    if (userLogado == undefined) {
      return {}
    } else {
      return JSON.parse(userLogado)
    }
  }

  setUsuarioLogado(p_usuario_string: string): void {
    sessionStorage.setItem('usuarioLogado', JSON.stringify(p_usuario_string))
  }
}
