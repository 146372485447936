import { Component, OnInit } from '@angular/core';
import { CabecalhoServicoService } from 'src/app/template/cabecalho/cabecalho-servico.service';
import { NavegacaoServicoService } from 'src/app/template/navegacao/navegacao-servico.service';

@Component({
  selector: 'app-processo-consulta-pagina',
  templateUrl: './processo-consulta-pagina.component.html',
  styleUrls: ['./processo-consulta-pagina.component.css']
})
export class ProcessoConsultaPaginaComponent implements OnInit {
  constructor(private vCabecalhoServico: CabecalhoServicoService
    , private vNavegacaoServico: NavegacaoServicoService
  ) {
    vCabecalhoServico.cabecalho = {
      titulo: 'Processo > Consulta',
      icone: 'feed',
      rotaURL: 'pgProcesso'
    }

    vNavegacaoServico.tipoMenu = 'processo';
  }
  ngOnInit(): void {
  }

}
