import { FormGroup } from "@angular/forms";

export function valida_data_menor_igual_hoje(
  ) {
    return (data: FormGroup) => {
      var retorno = null
      const agora = new Date();

      if (data.value > agora) {
        retorno = { data_futura: true };
      }

      return retorno

    }
};