<mat-card class="calendario">
    <mat-card-title>
        <i class="material-icons">
            task
        </i>
        Consulta de Tarefas
    </mat-card-title>

    <mat-card-subtitle>
        Aqui você pode consultar todas as tarefas cadastradas. 
    </mat-card-subtitle>
</mat-card>

<div class="divDataTable">
    <app-datatable ENTIDADE="tarefa" ID_PARENT=""></app-datatable>
</div>