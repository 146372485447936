import { Component,  OnInit,  } from '@angular/core';
import { CabecalhoServicoService } from 'src/app/template/cabecalho/cabecalho-servico.service';
import { NavegacaoServicoService } from 'src/app/template/navegacao/navegacao-servico.service';

@Component({
  selector: 'app-audiencia-pagina',
  templateUrl: './audiencia-pagina.component.html',
  styleUrls: ['./audiencia-pagina.component.css']
})

export class AudienciaPaginaComponent implements OnInit {

  constructor(private vCabecalhoServico: CabecalhoServicoService
    , private vNavegacaoServico: NavegacaoServicoService
  ) {
    vCabecalhoServico.cabecalho = {
      titulo: 'Processo > Audiência',
      icone: 'feed',
      rotaURL: 'pgProcesso'
    }

    vNavegacaoServico.tipoMenu = 'processo';
  }


  ngOnInit(): void {

  }

}