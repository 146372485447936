import { JudicyService } from "src/app/servicos/judicy.service";
import { UtilidadesService } from "src/app/servicos/utilidades.service";
import { TipoAndamentoCategoria } from "./tipoAndamentoCategoria";
import { TipoBancoLista } from "./tipoBancoLista";
import { TipoListaForum } from "./tipoListaForum";
import { TipoListaUsuario } from "./tipoListaUsuario";
import { TipoProcessoOrgao } from "./tipoProcessoOrgao";
import { TipoProcessoStatus } from "./tipoProcessoStatus";

export class ListasDeBancoDeDados {
  constructor(
    private judicy: JudicyService
   ,private util: UtilidadesService

 ) { }
  usuario = new TipoListaUsuario(this.judicy,this.util)
  categoria = new TipoAndamentoCategoria(this.judicy,this.util)
  forum = new TipoListaForum(this.judicy,this.util)
  orgao = new TipoProcessoOrgao(this.judicy,this.util)
  status = new TipoProcessoStatus(this.judicy,this.util)
  banco = new TipoBancoLista(this.judicy,this.util)

}