import { TipoDados } from "./tipoDados";
import { itemLista } from "./tipoLista";

export class TipoCEP extends TipoDados {

  formatar_para_exibir_em_tela(cep_num: number): string {
    var retorno: string
    var cep:string =   this.pad(cep_num,8)
    if (cep == undefined) {
      retorno = 'Indefinido'
    } else {
      if (cep?.length == 8) {
        retorno = cep!.toString()
        retorno = retorno.substr(0, 5) + '-'
          + retorno.substr(5, 3)
      } else {
        retorno = cep
      }
    }
    return retorno
  }
  
  codigo_tipo = 'cep'
  lista: Array<itemLista> = []
  mascara = '00000-000'

  pad(num:number, size:number): string {
    let s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
}

}