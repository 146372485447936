import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { JudicyService } from "../servicos/judicy.service";
import { TipoOperadorJudicy } from '../domain/tipoOperadorJudicy';
import { map } from 'rxjs/operators';

export function valida_audiencia_ja_agendada(campo_id_processo: string,
  judicy: JudicyService):
  AsyncValidatorFn {
  return (control: AbstractControl) => {
    var tipo = control.value;
    var id_processo = control.root.get(campo_id_processo)!.value;
    
    // var redesiginado_ou_agendado: any = "D,A"
    var agendado: any = "A"

    var filtro_processo = { campo: 'id_processo', valor: id_processo, operador_logico: TipoOperadorJudicy.IGUAL };
    var filtro_status   = { campo: 'status', valor: agendado, operador_logico: TipoOperadorJudicy.CONTEM_LISTA };
    var filtro_tipo     = { campo: 'tipo', valor: tipo, operador_logico: TipoOperadorJudicy.IGUAL };

    var listafiltro = [];
    listafiltro.push(filtro_processo);
    listafiltro.push(filtro_status);
    listafiltro.push(filtro_tipo);
   
    var filtro_judicy = '{ "filtro":' + JSON.stringify(listafiltro) + '}';

    return busca_judicy('audiencia', filtro_judicy, judicy)
      .pipe(
        map((res: any) => {
          return res.conteudo.length == 0
            ? // * Quando não há erros:
            null
            : // * Quando houver erros: 
            { duplicado: true }
        }
        )
      );
  };
}


export function busca_judicy(entidade: string, filtro_judicy: string, judicy: JudicyService
) {
  return judicy.listar(
    entidade
    , 1
    , 1
    , ''
    , ''
    , filtro_judicy
  )
}