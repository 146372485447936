import { TipoLista } from "./tipoLista";

export class TipoEnderecoTipo extends TipoLista {
  constructor(
    
  ) {
    super();
    this.prepara_lista()
  }

  prepara_lista(): void {
    this.adicionar_item('RE','Residêncial')
    this.adicionar_item('CR','Correspondência')
    this.adicionar_item('CM','Comercial')

  }

}