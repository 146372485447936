import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { TipoData } from "../tipos_de_dados/tipoData";
import { classeEntidadeDefault } from "./classeEntidadeDefault";
import { TipoHora } from "../tipos_de_dados/tipoHora";
import { TipoNumeroProcessoLink } from "../tipos_de_dados/tipoNumeroProcessolink";
import { domainAndamento } from "src/app/domain/andamento";
import { TipoTextoLongo } from "../tipos_de_dados/tipoTextoLongo";

@Injectable({
  providedIn: 'root'
})
export class classeEntidadeAndamento extends classeEntidadeDefault {

//construtor

  instanciar(): void {
    this.nome = 'andamento'
    this.nome_externo = 'Andamento'
    this.icone = 'history'
    this.carregar_datatable()
    this.configurar_dados_parent()
  }
  
  configurar_dados_parent(): void {
       this.campo_id_parent = 'id_processo'
       this.entidade_parent = 'processo'
  }
  
  configurar_layout(): void {

    this.reinicializa()
    this.incluir_campo('data', 'Data Cadastro', new TipoData, false, false, true, false, '', undefined, false)
    this.incluir_campo('hora', 'Hora Cadastro', new TipoHora, false, false, true, false, '', undefined, false)
    this.incluir_campo('data_andamento', 'Data Andamento', new TipoData, true, true, true, false, '', [Validators.required])
    this.incluir_campo('categoria', 'Categoria', this.lista.categoria , true, true, true, false, '', [Validators.required])
    this.incluir_campo('descricao', 'Descrição', new TipoTextoLongo, true, true, true, false, '', [Validators.required])
    this.incluir_campo('usuario', 'Criador', this.lista.usuario, true, false, true, false, '', [Validators.required], false)
    this.incluir_campo('numero_processo', 'Número Processo', new TipoNumeroProcessoLink(this.util), true, false, true, true, 'processo', undefined, false)
  }

  formatar_detalhe_externo(registro: any): string {
    var andamento: domainAndamento = registro

    var formatado: string

    formatado = andamento.descricao!

    return formatado

  }

}