import { TipoDados } from "./tipoDados";
import { itemLista } from "./tipoLista";

export class TipoVara extends TipoDados {

  formatar_para_exibir_em_tela(vara_num?: number): string {
    var retorno: string

    if (vara_num == undefined) {
      retorno = 'Indefinido'

    } else {
        var vara : string = this.pad(vara_num,3)
        retorno = vara + 'º'
    }

    return retorno

  }
  
  codigo_tipo = 'mascara'
  lista: Array<itemLista> = []
  mascara = '999º'

  pad(num:number, size:number): string {
    let s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
}

}