import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TipoEntidade } from 'src/app/classe/entidade/tipoEntidade';
import { ExecutorOperacaoDialog } from 'src/app/classe/OperacaoDialog';
import { domainAcao, Operacao, TipoOperacao } from 'src/app/domain/acao';
import { domainUsuario } from 'src/app/domain/usuario';
import { SessaoService } from 'src/app/servicos/sessao.service';
import { JudicyService } from 'src/app/servicos/judicy.service';
import { UtilidadesService } from 'src/app/servicos/utilidades.service';
import { CabecalhoServicoService } from './cabecalho-servico.service';
import { TipoFoto } from 'src/app/classe/tipos_de_dados/tipoFoto';
import { ListasDeBancoDeDados } from 'src/app/classe/tipos_de_dados/tipoListaBancoDeDados';

@Component({
  selector: 'app-cabecalho',
  templateUrl: './cabecalho.component.html',
  styleUrls: ['./cabecalho.component.css']
})
export class CabecalhoComponent implements OnInit {

  constructor(private vCabecalhoServico: CabecalhoServicoService
    , public sessaoServico: SessaoService
    , private dialog: MatDialog
    , private judicy: JudicyService
    , private util: UtilidadesService
  ) { }

  ngOnInit(): void {
  }

  get usuarioLogado(): domainUsuario {
    return this.sessaoServico.getUsuarioLogado()
  }

  get titulo(): string {
    return this.vCabecalhoServico.cabecalho.titulo
  }

  get icone(): string {
    return this.vCabecalhoServico.cabecalho.icone
  }

  get rotaUrl(): string {
    return this.vCabecalhoServico.cabecalho.rotaURL
  }

  realizarLogout() {
    this.sessaoServico.realizarLout();

  }

  formatar_foto(foto: any) {
    var tipo_foto = new TipoFoto();
    return tipo_foto.formatar_para_exibir_em_tela(foto)
  }

  alterarCadastro() {
    var executor = new ExecutorOperacaoDialog()
    console.log('chamando lista 3')
    var lista_de_listas = new ListasDeBancoDeDados(this.judicy, this.util)
    var tipo_entidade = new TipoEntidade(this.judicy, this.util, lista_de_listas)
    var entidade = tipo_entidade.getEntidade('usuario')['instancia']
    entidade.set_layout_alterar_cadastro()
    this.judicy.acao.descricao = "Alterar"

    var dialog = executor.execucao_operacao_dialog(this.usuarioLogado, Operacao.ALTERAR,
      0
      , this.judicy, entidade, this.dialog)
    
  }

  acao: domainAcao = {}

  alterarSenha(): void {
    var executor = new ExecutorOperacaoDialog()
    console.log('chamando lista 4')

    var lista_de_listas = new ListasDeBancoDeDados(this.judicy, this.util)
    var tipo_entidade = new TipoEntidade(this.judicy, this.util, lista_de_listas)
    var entidade = tipo_entidade.getEntidade('usuario')['instancia']
    this.judicy.acao.descricao = "Resetar"

    var dialog = executor.execucao_operacao_dialog(this.usuarioLogado, Operacao.RESETAR,
      0
      , this.judicy, entidade, this.dialog)
  }

}
