import { TipoLista } from "./tipoLista";

export class TipoUsuarioPerfil extends TipoLista {
  constructor(
    
  ) {
    super();
    this.prepara_lista()
  }

  prepara_lista(): void {
    this.adicionar_item('S','Sócio')
    this.adicionar_item('C','Colaborador')
    this.adicionar_item('A','Advogado')
    this.adicionar_item('D','Administrador')

  }

}