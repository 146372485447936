<h2 mat-dialog-title  *ngIf="this.operacao != 'consultar_dialog'">
  <i class='material-icons icone'>
    {{entidade.icone}}
  </i>   {{this.acao.descricao}} {{this.entidade.nome_externo}}
</h2>

<h2 mat-dialog-title  *ngIf="this.operacao === 'consultar_dialog'">
  <i class='material-icons icone'>
    {{entidade.icone}}
  </i>
  {{entidade.get_titulo(registro)}}
</h2>

<mat-dialog-content *ngIf="this.operacao === 'incluir' || this.operacao === 'alterar' || this.operacao === 'resetar' || this.operacao === 'recarregar' || this.operacao === 'concluir'"   
class='conteudo'>
  <app-formulario></app-formulario>
</mat-dialog-content>

<mat-dialog-content *ngIf="this.operacao === 'excluir' || this.operacao === 'bloquear' || this.operacao === 'desbloquear'  " 
class='conteudo'>
  <p>Deseja realmente {{this.operacao}}?</p>
  <p>{{entidade.formatar_detalhe_externo(registro)}}
  </p>
</mat-dialog-content>

<mat-dialog-content *ngIf="this.operacao === 'consultar_dialog'" 
class='conteudo'>
<p>{{entidade.get_subtitulo(registro)}}</p>
<p>{{entidade.get_texto(registro)}}</p>
  <p>{{entidade.get_rodape(registro)}}</p>
</mat-dialog-content>

<mat-dialog-content *ngIf="this.operacao === 'relatorio'" class='conteudo'>

  <div *ngFor="let rel of this.entidade.lista_relatorio" class='div-toolbar'>
    <mat-toolbar>
      <i class='material-icons'>
        {{rel.icone}}
      </i>
      {{rel.descritivo}} <span class="espacamento"></span>
      <a *ngFor="let formato of rel.formatos" mat-list-item [routerLink]=""
        (click)="downloadRelatorio(formato,rel)">
        <div class='div-icone'>
          <img class='logo' src="assets/img/icones/{{formato}}.png" alt="logo" class='icone-rel'>
        </div>
      </a>
    </mat-toolbar>
  </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="this.operacao === 'incluir_via_seletor'" class='conteudo'>
  <p>selecone</p>
  <p *ngIf="this.registro_selecionado !== undefined">{{this.registro_selecionado[acao.identificador_seletor!]}}</p>
  <p><app-datatable></app-datatable>
  </p>
</mat-dialog-content>

<mat-dialog-actions align='end'>
  <button mat-button (click)='cancelar()' *ngIf="this.operacao != 'relatorio'">
    Cancelar
  </button>

  <button mat-button (click)='confirmar()' *ngIf="this.operacao != 'relatorio'">
    Confirmar
  </button>

  <button mat-button (click)='cancelar()' *ngIf="this.operacao === 'relatorio'">
    Sair
  </button>
  
  <ng-container *ngFor="let acao of this.entidade.acoes_nivel_dialog">
    <button mat-raised-button
        *ngIf="!verificaOcultarAcao(acao)"
        (click)='confirmar_acao_dialog(acao)' class='{{acao.estilo}}'
        matTooltip='{{acao.descricao}} {{entidade.nome_externo}}'>
        <i class='material-icons'>
            {{acao.icone}}
        </i>
    </button>
</ng-container>

</mat-dialog-actions>