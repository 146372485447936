import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { domainCabecalho } from 'src/app/domain/cabecalho';

@Injectable({
  providedIn: 'root'
})
export class CabecalhoServicoService {

  private _cabecalho = new BehaviorSubject<domainCabecalho>({
    titulo : 'Inicio',
    icone  : 'home',
    rotaURL: ''
  })
  
  constructor() { }

  get cabecalho(): domainCabecalho {
    return this._cabecalho.value
  }

  set cabecalho(cabecalho: domainCabecalho) {
    this._cabecalho.next(cabecalho)
  }
}
