import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { domainLocal } from "src/app/domain/local";
import { TipoData } from "../tipos_de_dados/tipoData";
import { TipoNumeroProcessoLink } from "../tipos_de_dados/tipoNumeroProcessolink";
import { TipoTexto } from "../tipos_de_dados/tipoTexto";
import { classeEntidadeDefault } from "./classeEntidadeDefault";

@Injectable({
  providedIn: 'root'
})
export class classeEntidadeLocal extends classeEntidadeDefault {
//construtor

  instanciar(): void {
    this.nome = 'local'
    this.nome_externo = 'Local'
    this.icone = 'place'
    this.carregar_datatable()
    this.reconfigurar_botoes()
    this.configurar_dados_parent()
  }
  
  configurar_dados_parent(): void {
       this.campo_id_parent = 'id_processo'
       this.entidade_parent = 'processo'
  }
  
  configurar_layout(): void {

    this.reinicializa()
    this.incluir_campo('data', 'Data', new TipoData, true, true, true, false, '', [Validators.required])
    this.incluir_campo('local', 'Local', new TipoTexto, true, true, true, false, '', [Validators.required])
    this.incluir_campo('numero_processo', 'Número Processo', new TipoNumeroProcessoLink(this.util), true, false, true, true, 'processo', undefined, false)
  }

  reconfigurar_botoes() {
    this.acoes_nivel_registro = []
    this.tipo_operacao.ALTERAR.executada_apenas_por_criador = false
    this.tipo_operacao.EXCLUIR.executada_apenas_por_criador = false
    this.acao_duplo_clique = this.tipo_operacao.ALTERAR
    this.acoes_nivel_registro.push(this.tipo_operacao.ALTERAR)
    this.acoes_nivel_registro.push(this.tipo_operacao.EXCLUIR)


  }

  formatar_detalhe_externo(registro: any): string {
    var local: domainLocal = registro

    var formatado: string

    formatado = local.local!

    return formatado

  }

}