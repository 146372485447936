import { Condicao, CondicaoDestacar, domainAcao, Operacao, TipoOperacao } from "src/app/domain/acao";
import { domainCampo } from "src/app/domain/entidade";
import { domainRelatorio } from "src/app/domain/relatorio";
import { UtilidadesService } from "src/app/servicos/utilidades.service";
import { TipoDados } from "../tipos_de_dados/tipoDados";
import { ListasDeBancoDeDados } from "../tipos_de_dados/tipoListaBancoDeDados";
import { classeEntidade } from "./classeEntidade";
import { ValidatorFn } from "@angular/forms";
import { JudicyService } from "src/app/servicos/judicy.service";

export class classeEntidadeDefault implements classeEntidade {

  constructor(
    public lista: ListasDeBancoDeDados,
    public util: UtilidadesService,
    public judicy: JudicyService
  ) {
    this.instanciar()
  }

  formatar_registro(registro: any): string {
    return 'registro'
  }
  formatar_detalhe_externo(registro: any): string {
    return 'registro'
  }

  instanciar() {
  }

  reinicializa() {
    this.layout = []
  }

  flag_exibicao_parent = false
  campo_id_parent: string = ''
  entidade_parent: string = ''
  id_parent = 0
  tipo_operacao = new TipoOperacao()
  acoes_nivel_registro: Array<domainAcao> = []
  acoes_nivel_tabela: Array<domainAcao> = []
  acoes_nivel_dialog: Array<domainAcao> = []
  layout: Array<domainCampo> = []
  layout_resetar: Array<domainCampo> = []
  nome: string = ''
  lista_relatorio: Array<domainRelatorio> = []
  acao_duplo_clique: domainAcao = { operacao: Operacao.ALTERAR, executada_apenas_por_criador: true }
  icone = 'home'
  entidade_seletor: any
  campo_ordenacao: string = ''
  direcao_ordenacao: string = 'asc'
  nome_externo: string = 'Entidade'
  condicao_destacar: Array<CondicaoDestacar> = []
  validacao_formulario : Array<any> = []
  validacao_assincrona_formulario : Array<any> = []

  carregar_datatable() {
    this.configurar_layout()
    this.carregar_botes_acao_nivel_registro()
    this.carregar_botes_acao_nivel_tabela()

    // this.tabela.instanciar()
    // this.tabela.carregar_cabecalho_tabela()
    // this.tabela.atualizar_conteudo_tabela()
    // this.tabela.acoes_nivel_registro.push(this.tipo_operacao.ALTERAR)
    // this.tabela.acoes_nivel_registro.push(this.tipo_operacao.EXCLUIR)
    // this.tabela.campo_id_parent = 'id_processo'
    // this.tabela.entidade_parent = 'processo'
    // this.tabela.id_parent = this.id_parent
    // this.tabela.acoes_nivel_tabela.push(this.tipo_operacao.INCLUIR)
  }

  carregar_botes_acao_nivel_registro() {
    this.tipo_operacao.ALTERAR.executada_apenas_por_criador = true
    this.tipo_operacao.EXCLUIR.executada_apenas_por_criador = true

    this.acoes_nivel_registro.push(this.tipo_operacao.ALTERAR)
    this.acoes_nivel_registro.push(this.tipo_operacao.EXCLUIR)

  }

  carregar_botes_acao_nivel_tabela() {
    this.acoes_nivel_tabela.push(this.tipo_operacao.INCLUIR)
  }

  configurar_layout(): void {
  }

  incluir_campo(p_nome: string
    , p_descritivo: string
    , p_tipo: TipoDados
    , p_obrigatorio: boolean
    , p_editavel: boolean
    , p_exibe_consulta: boolean
    , p_indicador_campo_parent?: boolean
    , p_nome_parent?: string
    , p_validacao?: Array<any>
    , p_cadastravel?: boolean
    , p_apenas_para_pf?: boolean
    , p_condicao_exibicao?: Array<Condicao>
    , p_condicao_habilitacao?: Array<Condicao>
    , p_validacao_assincrona?: Array<any>
  ): void {
    var campo: domainCampo = { nome_campo: '' }

    campo.nome_campo = p_nome
    campo.nome_descritivo = p_descritivo
    campo.tipo = p_tipo
    campo.obrigatorio = p_obrigatorio
    campo.editavel = p_editavel
    campo.exibe_consulta = p_exibe_consulta
    campo.indicador_campo_parent = p_indicador_campo_parent
    campo.nome_parent = p_nome_parent
    campo.validacao = p_validacao
    if (p_cadastravel == undefined) {
      campo.cadastravel = true
    } else {
      campo.cadastravel = p_cadastravel
    }

    if (p_apenas_para_pf == undefined) {
      campo.apenas_para_pf = false
    } else {
      campo.apenas_para_pf = p_apenas_para_pf
    }

    if (p_condicao_exibicao == undefined) {
      campo.condicao_exibicao = [{ 'campo': '' }]
    } else {
      campo.condicao_exibicao = p_condicao_exibicao
    }

    if (p_condicao_habilitacao == undefined) {
      campo.condicao_habilitacao = [{}]
    } else {
      campo.condicao_habilitacao = p_condicao_habilitacao
    }
    campo.validacao_assincrona = p_validacao_assincrona

    this.layout.push(campo)
  }

  get_titulo(registro: any): string {
    return ''
  }

  get_texto(registro: any): string {
    return ''
  }

  get_rodape(registro: any): string {
    return ''
  }

  get_subtitulo(registro: any): string {
    return ''
  }

  configurar_exibicao_default(): void {
    this.flag_exibicao_parent = false
    this.id_parent = 0

    if (this.campo_id_parent != '') {
      var acoes_sem_incluir = this.acoes_nivel_tabela.filter((acao) => acao.operacao != Operacao.INCLUIR)
      this.acoes_nivel_tabela = acoes_sem_incluir
    }
  }

  configurar_exibicao_parent(id_parent: string): void {
    this.flag_exibicao_parent = true
    this.id_parent = + id_parent

  }

}